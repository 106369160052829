<overa-datatable
  #dataTable
  [columns]="columns"
  [loadDataFunction]="loadDataFunction"
  [localizeLiterals]="datatableLiterals"
  [showPaginator]="true"
  title="Budget List"
  [showGlobalFilter]="true"
  [rowsPerPage]="[10, 20]"
  [showExports]="false"
  [items]="data"
  [totalRecords]="totalRecords"
  [selectionMode]="'multiple'"
  (selectedItems)="onSelectedItems($event)"
>
</overa-datatable>
