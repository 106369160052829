//import { InfoView } from "./infoView.model";

export interface IFilterDropdownsNames<T> {
    filterType: T;
    uids: T;
    hostNames: T;
    userNames: T;
    types: T;
    multipleStatus: T;
    appVersions: T;
    systemModels: T;
    timeInterval: T;
    timeIntervalMinutes: T;
    timeIntervalDays: T;
    processNames: T;
    taskTypes: T;
    taskStatus: T;
    dayTypes: T;
    functions: T;
    numbers: T;
    productivityType: T;
    deviceTypes: T;
    measureType: T;
    roles: T;
    groupType: T;
    softwareProducts: T;
    excludeZeroValues: T;
    singleApps:T;
    employeeTag:T;
    numItems: T;
    dataType: T;
    screenType: T;
    viewTelemetry: T;
    hostsInformation: T;
    tierTop: T;
    softwareDataType: T;
    showAllProducts: T;
    showDeviceOrUser: T;
    withinWorkingHours: T;
    isPercentage: T;
    windowsEventsId: T;
    windowsEventsChannel: T;
    windowsEventsLevel: T;
    hostNameTag: T;
}

export interface IFilterNames<T> extends IFilterDropdownsNames<T> {
    startDate: T;
    endDate: T;
}

export class Filters {
    [key: string]: any;    
}

export class FilterInit {
    autoloadData!: boolean;
    isLazyLoadData!: boolean;
    loadFilterWhenDropdown!: boolean;
    autoloadChangeFilter!: boolean;
    cleanFilterOnReloadData!: boolean;
    notifyOnInitChangeFilter!: boolean;
    dataRangeDays!: number;
    export!: boolean;
    actionList!: Array<any>;
    defaultButton!: boolean;
    viewName!: string;
    exportMode!: boolean;
    //infoView: InfoView;
}

export class ChangeFilter {
    value: any;
    name!: string;
    filterElement: any;
}

export class FilterInstance {
    [key: string]: any;
    name: string;
    type: string;
    visible: boolean;
    visibleWildcard?: boolean;
    required: boolean;
    conditionalsRequired?: string[];
    defaultValue: any;
    //TODO - pending to apply in all filters. Only groupType
    //excludedItems?: any[];
    view: string;
    filterType: string; 
    userId: string = "";
    rolId: string = "";
    isInit: boolean = false;
    endpointName: string = "";
    optionLabel: string = "name";
    showClear: boolean = true;
    filter: boolean = false;

    constructor(name: string, type: string, filterType: string, view: string) {
        this.name = name;
        this.type = type;
        this.visible = true;
        this.visibleWildcard = false;
        this.required = false;
        this.view = view;
        this.filterType = filterType;
    }
} 
