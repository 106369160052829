import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ConfigsService } from "@overa/shared";
import * as imageMapResize from "image-map-resizer";
import { GoldenLayoutService } from "src/app/services/golden-layout.service";
import { ActivityGlobals } from "src/app/utils/globals";
import { OveraMessagesService } from "@overa/components/info-dialog";

@Component({
  selector: "app-home",
  templateUrl: "home.component.html",
  styleUrls: ["./home.scss"],
})
export class HomeComponent implements OnInit {
  globals: ActivityGlobals;
  newTabWithCtrlClick: boolean = this.config.getModuleConfig(
    "overa-shared",
    "allwaysNewTabOnGL"
  );
  isCtrlNavigation: boolean = false;
  constructor(
    private router: Router, 
    private config: ConfigsService, 
    private goldenLayoutService: GoldenLayoutService,
    globals: ActivityGlobals,
    protected overaMessagesService: OveraMessagesService,
  ) {
    this.globals = globals;
  }

  ngOnInit() {
    console.log("imagemap-", imageMapResize);
    imageMapResize();
  }

  navigationClick(event: MouseEvent, route: string) {
    event.preventDefault();
    /* if (event.ctrlKey && this.newTabWithCtrlClick) {
      this.isCtrlNavigation = true;
    } else {
      this.isCtrlNavigation = false;
    }
    this.router.navigateByUrl(route); */

    console.log("home navigationClick-> ", event, route);
    this.goldenLayoutService.getUserLayouts(this.globals.user.id ?? "").subscribe(res => {
      const homeSelectionLayout = res.items.find((i: any) => i.name.toLowerCase() == route.toLowerCase());
      if(homeSelectionLayout){
        this.goldenLayoutService.loadSavedLayout(homeSelectionLayout);
      }
      else{
        this.overaMessagesService.showToast(
          "No existe layout",
          "Crea unlayout con el nombre de la sección",
          "warning",
          ""
        );
      }
    });
    
  }
}
