import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";

import { BaseService, ConfigsService } from "@overa/shared";
import { TenantIdTenantUid } from "../models/tenantIdTenantUid.model";

@Injectable({ providedIn: "root" })
export class TenantIdTenantUidService extends BaseService<TenantIdTenantUid> {
  private tenantIdTenantUidServiceRouteSubject: ReplaySubject<string> =
    new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public tenantIdTenantUidServiceRouteObservable: Observable<string> =
    this.tenantIdTenantUidServiceRouteSubject.asObservable();

  private tenantIdTenantUidServiceSubject: ReplaySubject<boolean> =
    new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public tenantIdTenantUidServiceObservable: Observable<boolean> =
    this.tenantIdTenantUidServiceSubject.asObservable();

  private endpointUrl = `${this.apiBaseUrl}${this.path}`;

  override get apiBaseUrl(): string {
    return this.config.getModuleConfig("overa-security", "apiBaseUrl") + "/api";
  }
  override get path(): string {
    return "/TenantIdTenantUid";
  }

  constructor(override http: HttpClient, config: ConfigsService) {
    super(http, config);
  }

  getTenantIdTenantUids(): Observable<TenantIdTenantUid[]> {
    return this.http.get<TenantIdTenantUid[]>(`${this.endpointUrl}`);
  }

  getTenantById(tenantId: string): Observable<TenantIdTenantUid> {
    let tenant = { tenantId: tenantId }; // Create object for using in a POST request
    return this.http.post<TenantIdTenantUid>(
      `${this.endpointUrl}/GetTenantById`,
      tenant
    );
  }

  getTenantByUid(tenantUid: string): Observable<TenantIdTenantUid> {
    let tenant = { tenantUid: tenantUid }; // Create object for using in a POST request
    return this.http.post<TenantIdTenantUid>(
      `${this.endpointUrl}/GetTenantByUid`,
      tenant
    );
  }

  addTenantIdTenantUid(data: TenantIdTenantUid): Observable<TenantIdTenantUid> {
    return this.http.post<TenantIdTenantUid>(
      `${this.endpointUrl}/CreateTenantIdTenantUid`,
      data
    );
  }

  updateTenantIdTenantUidRoute(id: string) {
    this.tenantIdTenantUidServiceRouteSubject.next(id);
  }

  updateTenantIdTenantUids(tenantInstallVersion: boolean) {
    this.tenantIdTenantUidServiceSubject.next(tenantInstallVersion);
  }
}
