import { generateGuid } from "@overa/shared";

export class DetailedView {
  id: string;
  app: string;
  device: string;
  type: string;
  startDate: Date;
  endDate: Date;
  duration: number;
  selected: boolean;

  constructor(
    app: string,
    device: string,
    type: string,
    startDate: Date,
    endDate: Date,
    duration: number,
    selected: boolean,
  ) {
    this.app = app;
    this.device = device;
    this.type = type;
    this.startDate = startDate;
    this.endDate = endDate;
    this.duration = duration;
    this.selected = selected;
    this.id = generateGuid();
  }
}

function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const apps = ["App1", "App2", "App3", "App4", "App5"];
const devices = ["Device1", "Device2", "Device3", "Device4", "Device5"];
const types = ["Type1", "Type2", "Type3", "Type4", "Type5"];

export const detailedViews = Array.from({ length: 20 }, () => {
  const app = apps[Math.floor(Math.random() * apps.length)];
  const device = devices[Math.floor(Math.random() * devices.length)];
  const type = types[Math.floor(Math.random() * types.length)];
  const startDate = new Date(2022, randomIntFromInterval(0, 11), randomIntFromInterval(1, 28));
  const endDate = new Date(startDate.getTime() + randomIntFromInterval(1, 14) * 24 * 60 * 60 * 1000);
  const duration = (endDate.getTime() - startDate.getTime()) / 1000;

  return new DetailedView(app, device, type, startDate, endDate, duration, false);
});
