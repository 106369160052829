<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <mat-card-content>
                            <div class="container-fluid main-container">

                                <div class="container-individualFilters">
                                    <app-filters #filterCmp
                                        [filterInit]="filterInit"
                                        (loading)="setLoading($event)"
                                        (loadData)="loadData($event)"
                                        (filterChange)="changeFilter($event)"
                                        (exportOnClick)="csvExport($event)"
                                        (validAllRequiredFilters)="validAllRequiredFilters($event)"
                                        (viewModeChange)="viewModeChange($event)">
                                    </app-filters>
                                </div>

                                <div class="row">
                                    <div class="col-12 loading-wrapper">

                                        <div *ngIf="loading" class="p-component-overlay loading-overlay">
                                            <em class="pi pi-spin pi-spinner p-datatable-loading-icon" style="font-size: 2rem;">
                                            </em>
                                        </div>

                                        <div class="container">
                                            <div class="row g-4" *ngFor="let chartData of chartsData; let i = index">

                                            <div class="col-3 col-md-3 align-items-center d-flex border-bottom">
                                                <div class="fs-2 fw-bold text-md-end">{{ chartData.chartKey }}</div>
                                            </div>

                                            <div class="col-9 col-md-9 border-bottom">
                                                <overa-echart [options]="chartsOptions[i]" [data]="chartData" [size]="chartsHeight[i]"></overa-echart>
                                            </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                
                            </div>
                        </mat-card-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

