import { Component } from "@angular/core";

@Component({
  selector: "app-dialog-update-detail",
  templateUrl: "./dialog-update-detail.component.html",
  styleUrls: ["./dialog-update-detail.component.scss"],
})
export class DialogUpdateDetailComponent {
  optionsStatus: string[] = ["Default", "Enabled", "Disabled", "Pending"];
  selectedStatus: string = "";

  selectState(event: any) {
    console.log("evenet", event);
  }
}
