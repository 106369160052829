import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { TimeFromSecondsPipe } from "@overa/shared";
import { TreeNode } from "primeng/api";
import { Filters } from "src/app/models/filters.model";
import {
  COLUMN_DATETIME_LONG_PIPE,
  COLUMN_DATE_SHORT_PIPE,
  COLUMN_TIME_DURATION_PIPE,
} from "src/app/shared/pipes/column_pipes/column-data-formatter.pipe";
import {
  ActivityTreeTableComponent,
  TreeNodeLevel,
} from "../../shared/activity-treetable.component";
import {
  UserActivitySummaryGrouped,
  mockUserActivitySummaryGroupedData,
} from "./model";
import { DurationPipe } from "src/app/shared/pipes/duration.pipe";
import { MeasurementService } from "src/app/services/measurement.service";
import { ActivitySummaryGroupedType } from "src/app/models/userActivity/activitySummaryGrouped.model";
import { ActivitySummary } from "src/app/models/userActivity/activitySummary.model";
import { GroupType } from "src/app/models/groupType.model";
import { OveraDataTableColumn } from "@overa/components/common";

@Component({
  selector: "app-user-activity-summary-grouped",
  templateUrl: "./user-activity-summary-grouped.component.html",
  styleUrls: ["./user-activity-summary-grouped.component.scss"],
  providers: [DatePipe, TimeFromSecondsPipe, DurationPipe],
})
export class UserActivitySummaryGroupedComponent
  extends ActivityTreeTableComponent<ActivitySummaryGroupedType>
  implements OnInit
{
  constructor(private measurementService: MeasurementService) {
    super();
  }

  override ngOnInit(): void {
    this.createColumns();
    this.levelColorsClass = ["first-bg", "second-bg", "third-bg"];
    //this.data = this.convertToTreeNode(mockUserActivitySummaryGroupedData);
    console.log("data on tree no se que", this.data);
    this.assignColors(this.data, 0);
  }

  override loadData(filters: Filters): void {
    // this.data = this.convertToTreeNode(mockUserActivitySummaryGroupedData);
    // this.assignColors(this.data, 0);

    // TODO: Mockup filters
    if (!filters || !filters["measureType"]) {
      filters = {
        groupType: ["department"],
        measureType: ["sum"],
        startDate: new Date("2024-05-01T23:00:00.000Z"),
        endDate: new Date("2024-05-10T22:59:59.000Z"),
        itemsPerPage: 10,
        page: 1,
      };
    }

    this.measurementService
      .getActivitySummaryGrouped(filters)
      .subscribe((res) => {
        this.data = this.convertToTreeNode(res);
        this.assignColors(this.data, 0);
        // TODO: Utilizar traducciones para obtener la cabecera
        this.columns[0].header = "Agrupado por " + filters["groupType"][0];
      });
  }

  assignColors(items: TreeNode[], level: number): void {
    items.forEach((item: TreeNode) => {
      item.styleClass =
        this.levelColorsClass[level % this.levelColorsClass.length];

      if (item.children) {
        this.assignColors(item.children, level + 1);
      }
    });
  }

  /* convertToTreeNode(data: UserActivitySummaryGrouped[]): TreeNode[] {
    const treeNodeToReturn: TreeNode[] = [];
    data.forEach((element) => {
      const treeNode: TreeNode = {
        data: element,
        children: [],
        leaf: false,
        expanded: false,
        type: TreeNodeLevel.FIRST,
      };
      treeNode.children = [];
      element.employeeData.forEach((employee) => {
        const employeeNode: TreeNode = {
          data: employee,
          children: employee.details.map((detail) => ({
            data: detail,
            children: [],
            leaf: true,
            expanded: false,
            type: TreeNodeLevel.THIRD,
          })),
          type: TreeNodeLevel.SECOND,
          leaf: true,
          expanded: false,
        };
        treeNode.children?.push(employeeNode);
      });
      treeNodeToReturn.push(treeNode);
    });
    return treeNodeToReturn;
  } */

  convertToTreeNode(data: ActivitySummary[]): TreeNode[] {
    const treeNodeToReturn: TreeNode[] = [];
    data.forEach((element) => {
      const treeNode: TreeNode = {
        data: element,
        children: [],
        leaf: false,
        expanded: false,
        type: TreeNodeLevel.FIRST,
      };
      treeNode.children = [];

      let children: any[] = [];
      if (element.subGroups) {
        const key: GroupType = GroupType.EMPLOYEE;
        children = element.subGroups[key];
      }

      children.forEach((employee) => {
        let children: any[] = [];
        if (employee.subGroups) {
          const key: GroupType = GroupType.STARTDATE;
          children = employee.subGroups[key];
        }

        const employeeNode: TreeNode = {
          data: employee,
          children: children.map((detail) => ({
            data: detail,
            children: [],
            leaf: true,
            expanded: false,
            type: TreeNodeLevel.THIRD,
          })),
          type: TreeNodeLevel.SECOND,
          leaf: true,
          expanded: false,
        };
        treeNode.children?.push(employeeNode);
      });
      treeNodeToReturn.push(treeNode);
    });
    return treeNodeToReturn;
  }

  createColumns() {
    this.columns = [
      {
        field: "groupKey",
        header: "Group_Property",
        type: "String",
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "firstStart",
        header: "Inicio del Departamento",
        type: "DateTime",
        pipes: COLUMN_DATETIME_LONG_PIPE,
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "lastEnd",
        header: "Fin del Departamento",
        type: "DateTime",
        pipes: COLUMN_DATETIME_LONG_PIPE,
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "effectiveDuration",
        header: "Duración Efectiva",
        type: "Integer",
        pipes: COLUMN_TIME_DURATION_PIPE,
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "productiveDuration",
        header: "Duración Productiva",
        type: "Integer",
        pipes: COLUMN_TIME_DURATION_PIPE,
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "afkDuration",
        header: "Duración AFK",
        type: "Integer",
        pipes: COLUMN_TIME_DURATION_PIPE,
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "appUsageDuration",
        header: "Duración de Uso de la App",
        type: "Integer",
        pipes: COLUMN_TIME_DURATION_PIPE,
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "inactivityDuration",
        header: "Duración de Inactividad",
        type: "Integer",
        pipes: COLUMN_TIME_DURATION_PIPE,
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "blockedDuration",
        header: "Duración Bloqueada",
        type: "Integer",
        pipes: COLUMN_TIME_DURATION_PIPE,
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "notRegisteredDuration",
        header: "Duración No Registrada",
        type: "Integer",
        pipes: COLUMN_TIME_DURATION_PIPE,
        responsive: true,
        responsiveHeader: true,
      },
    ];
  }

  onNodeExpand(event: TreeNode) {
    if (event.children && event.children.length > 0) {
      if (event.type == TreeNodeLevel.FIRST) {
        const employeeColumn = {
          field: "employeeName",
          header: "Empleado",
          type: "String",
          responsive: true,
          responsiveHeader: true,
        };
        this.addColumn(employeeColumn);
      } else if (event.type == TreeNodeLevel.SECOND) {
        const dateColumn = {
          field: "date",
          header: "Fecha",
          type: "DateTime",
          pipes: COLUMN_DATE_SHORT_PIPE,
          responsive: true,
          responsiveHeader: true,
        };

        const insideScheduleColumn = {
          field: "withinWorkingHours",
          header: "Dentro del Horario",
          type: "Boolean",
          responsive: true,
          responsiveHeader: true,
        };

        this.addColumn(dateColumn, 2);

        this.addColumn(insideScheduleColumn, this.columns.length);
      }
    }
  }

  onNodeCollapse(event: TreeNode) {
    if (event.type === TreeNodeLevel.FIRST) {
      const hasOtherDepartmentOpen = this.data.some(
        (node) =>
          node.expanded && node !== event && node.type === TreeNodeLevel.FIRST
      );
      if (!hasOtherDepartmentOpen) {
        const columnName = "employeeName";
        this.removeColumn(columnName);
      }
    } else if (event.type === TreeNodeLevel.SECOND) {
      const hasOtherEmployeeOpen = this.data.some((departmentNode) =>
        departmentNode.children?.some(
          (employeeNode) =>
            employeeNode.expanded && employeeNode.type === TreeNodeLevel.SECOND
        )
      );
      if (!hasOtherEmployeeOpen) {
        const columnDate = "date";
        const columnInsideSchedule = "insideSchedule";
        this.removeColumn(columnDate);
        this.removeColumn(columnInsideSchedule);
      }
    }
  }

  private removeColumn(columnName: string) {
    this.columns = this.columns.filter((col) => col.field !== columnName);
  }

  private addColumn(dateColumn: OveraDataTableColumn, index: number = 1) {
    if (!this.columns.some((col) => col.field === dateColumn.field)) {
      this.columns.splice(index, 0, dateColumn);
    }
  }
}
