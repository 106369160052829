import { Component, ViewChild } from "@angular/core";
import { OveraTreetableComponent } from "@overa/components/treetable";
import { TreeNode } from "primeng/api";
import { ActivityBaseComponent } from "./activity-base.component";
import { OveraDataTableColumn } from "@overa/components/common";

export enum TreeNodeLevel {
  FIRST = "0",
  SECOND = "1",
  THIRD = "2",
}

@Component({
  template: "",
})
export abstract class ActivityTreeTableComponent<
  T
> extends ActivityBaseComponent {
  columns: OveraDataTableColumn[] = [];
  @ViewChild("treetable", { static: true })
  dataTable?: OveraTreetableComponent;
  data: TreeNode[] = [];
  levelColorsClass: string[] = [];

  abstract assignColors(items: TreeNode[], level: number): void;

  abstract convertToTreeNode(data: T[]): TreeNode[];
}
