import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "duration",
})
export class DurationPipe implements PipeTransform {
  transform(value: any): string {
    if (value) {
      const seconds = +value % 60;
      const minutes = Math.floor((+value / 60) % 60);
      const hours = Math.floor(+value / 3600);

      return `${hours}h ${minutes}m ${seconds}s`;
    }
    return "";
  }
}
