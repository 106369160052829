<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" acceptLabel="Si"
    rejectLabel="No"></p-confirmDialog>
<p-toast></p-toast>
<div class="main-content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <mat-card-content>
                    <div class="container-fluid main-container">
                        <!-- <div *ngIf="hidde">
                            <filters #filterCmp [filters]=filters [filterInit]=filterInit (loading)=setLoading($event)
                                (loadData)=loadData($event) (exportOnClick)=csvExport()
                                (validAllRequiredFilters)=validAllRequiredFilters($event)
                                (viewModeChange)=viewModeChange($event)>
                            </filters>
                        </div> -->
                        <div class="row">
                            <div class="col-12 loading-wrapper">
                                <div *ngIf="loading" class="p-component-overlay loading-overlay">
                                    <em class="pi pi-spin pi-spinner p-datatable-loading-icon"
                                        style="font-size: 2rem;"></em>
                                </div>
                                <!-- <div *ngIf="showCharts" echarts [options]="statusChartOption" [merge]="statusChartData"
                                    [autoResize]=true [style.height.px]="height">
                                </div> -->
                                <overa-echart [size]="height" [options]="chartOptions" [data]="chartData"></overa-echart>
                                <div *ngIf="!showCharts" i18n="@@VIEWS.COMMONS.FILTERS.INFO.NO_RESULTS"></div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </div>
        </div>
    </div>
</div>