import {
  ComponentFactoryResolver,
  Injectable,
  InjectionToken,
  Injector,
  StaticProvider,
  Type,
} from "@angular/core";
import {
  ComponentContainer,
  ComponentItem,
  GoldenLayout,
  JsonValue,
  LayoutConfig,
  ResolvedLayoutConfig,
  Stack,
  Tab,
} from "golden-layout";
import { GoldenLayoutComponent } from "../components/goldenLayout/golden-layout.component";
import { Menu } from "@overa/security";
import { Route } from "@angular/router";
import { BaseService, ConfigsService } from "@overa/shared";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GoldenLayoutService extends BaseService<any> {
  override get apiBaseUrl(): string {
    return this.config.getModuleConfig("overa-security", "apiBaseUrl") + "/api";
  }
  [key: string]: any;

  override get path(): string {
    return "/goldenLayout";
  }

  private get goldenLayoutApiUrl(): string {
    return this.apiBaseUrl + this.path;
  }
  private _goldenLayoutHostComponent!: GoldenLayoutComponent;
  private _componentTypeMap = new Map<string, Type<any>>();
  private _componentTypeMapRoutes: Route[] = [];
  private _goldenLayout!: GoldenLayout;
  saveLayoutDialog!: DynamicDialogRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public override http: HttpClient,
    protected override config: ConfigsService
  ) {
    super(http, config);
  }
  initialise(value: GoldenLayoutComponent) {
    this._goldenLayoutHostComponent = value;
    this._goldenLayout = this._goldenLayoutHostComponent.goldenLayout;
  }

  registerComponentType(name: string, componentType: Type<any>) {
    this._componentTypeMap.set(name, componentType);
  }
  registerComponentTypes(
    parentRoute: string,
    routes: Route[],
    father: Route | null = null
  ) {
    console.log(routes);
    this._componentTypeMapRoutes.push({ path: parentRoute, children: routes });

    routes.forEach((menuItem) => {
      if (
        menuItem.children &&
        menuItem.children.length > 0 &&
        menuItem.children[0].path !== ":id"
      ) {
        this.registerComponentTypes(
          `${parentRoute}/${menuItem.path}`,
          menuItem.children
        );
      } else if (menuItem.component) {
        const menuItemFullPath = `${parentRoute}${
          father ? `/${father.path}` : ""
        }${menuItem.path ? `/${menuItem.path}` : ""}`;
        this.registerComponentType(menuItemFullPath, menuItem.component);
      }
    });
  }

  registerComponentsRecursively(menuItems: any[], father: any = null) {
    menuItems.forEach((menuItem) => {
      if (
        menuItem.children &&
        menuItem.children.length !== 0 &&
        menuItem.children[0].path !== ":id"
      ) {
        this.registerComponentsRecursively(menuItem.children, menuItem);
      } else if (menuItem.component && menuItem.path) {
        const menuItemFullPath = father?.path
          ? father.path + "/" + menuItem.path
          : menuItem.path;
        this.registerComponentType(menuItemFullPath, menuItem.component);
      }
    });
  }

  //TODO: review lazy loading on golden layout
  // registerComponentsLazy() {
  //   import("@overa/dynamic/components").then((m) => {
  //     this.registerComponentType(
  //       "dynamicMaintenances",
  //       m.DynamicMaintenanceComponent
  //     );
  //     this.registerComponentType("dynamic", m.OveraDynamicViewListComponent);
  //     this.registerComponentType("dynamic/:id", m.OveraDynamicViewComponent);
  //     this.registerComponentType(
  //       "dynamic/listEntity/:apiName/:entityDefinitionId",
  //       m.OveraDynamicEntityListComponent
  //     );
  //     this.registerComponentType(
  //       "dynamic/extendedEntity/:apiName/:entityDefinitionId",
  //       m.OveraDynamicEntityContainerComponent
  //     );
  //     this.registerComponentType(
  //       "dynamic/extendedEntity/:apiName/:entityDefinitionId/:id",
  //       m.OveraDynamicEntityContainerComponent
  //     );
  //   });
  //   import("@overa/security").then((m) => {
  //     this.registerComponentType("core/security/menu", m.MenuComponent);
  //   });
  // }

  getRegisteredComponentTypeNames(): string[] {
    let count = this._componentTypeMap.size;
    let result = new Array<string>(count);
    let idx = 0;
    for (const [key, value] of this._componentTypeMap) {
      result[idx++] = key;
    }
    if (!result) {
      count = this._componentTypeMap.size;
      result = new Array<string>(count);
      let idx = 0;
      for (const [key, value] of this._componentTypeMap) {
        result[idx++] = key;
      }
    }
    return result;
  }

  getRegisteredComponentTypes(): Route[] {
    return this._componentTypeMapRoutes;
  }

  createComponent(
    componentTypeJsonValue: JsonValue,
    container: ComponentContainer
  ) {
    const componentType = this._componentTypeMap.get(
      componentTypeJsonValue as string
    );
    if (componentType === undefined) {
      throw new Error("Unknown component type");
    } else {
      const provider: StaticProvider = {
        provide: new InjectionToken<ComponentContainer>(
          "GoldenLayoutContainer"
        ),
        useValue: container,
      };
      const injector = Injector.create({
        providers: [provider],
      });
      const componentFactoryRef =
        this.componentFactoryResolver.resolveComponentFactory<any>(
          componentType
        );
      return componentFactoryRef.create(injector);
    }
  }

  findStacksAndComponentsRecursively(
    item: any,
    stacks: Stack[],
    components: ComponentItem[]
  ) {
    if (item.isStack) {
      stacks.push(item);
      if (item.contentItems && item.contentItems.length > 0) {
        components.push(...item.contentItems);
      }
    } else if (item.contentItems) {
      item.contentItems.forEach((subItem: any) => {
        this.findStacksAndComponentsRecursively(subItem, stacks, components);
      });
    }
  }

  loadSavedLayout(currentLayout?: any) {
    if (currentLayout === undefined) {
      throw new Error("No saved layout");
    } else {
      const currentLayoutValueJson = JSON.parse(currentLayout.value);
      const layoutConfig = LayoutConfig.fromResolved(currentLayoutValueJson);
      this._goldenLayout.loadLayout(layoutConfig);

      let components: ComponentItem[] = [];
      this.findStacksAndComponentsRecursively(
        (this._goldenLayout as any).groundItem,
        [],
        components
      );

      let focusedItem = components.find(
        (c) => (c.container.state as any).focused == true
      );
      this._goldenLayoutHostComponent.selectItemAndNavigate(focusedItem);
    }
  }

  async saveLayout(userId: string, layoutName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      let savedLayout = {
        userId: userId,
        value: JSON.stringify(this._goldenLayout.saveLayout()),
        name: layoutName,
      };

      this.http
        .post(`${this.goldenLayoutApiUrl}`, savedLayout)
        .subscribe((res) => {
          resolve();
        });
    });
  }

  async updateLayout(layout: any, newLayoutName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      layout.name = newLayoutName;
      layout.value = JSON.stringify(this._goldenLayout.saveLayout());
      this.http
        .put(`${this.goldenLayoutApiUrl}`, layout)
        .subscribe((res) => {
          resolve();
        });
    });
  }

  deleteLayout(layoutId: string): Observable<any> {
    return this.http.delete(`${this.goldenLayoutApiUrl}/${layoutId}`);
  }

  getUserLayouts(userid: string): Observable<any> {
    return this.http.get(`${this.goldenLayoutApiUrl}/getUserLayouts/`, {
      params: { userId: userid },
    });
  }

  getLayoutById(layoutId: string): Observable<any> {
    return this.http.get(`${this.goldenLayoutApiUrl}/${layoutId}`);
  }
}
