import { ChangeDetectorRef, Component } from "@angular/core";
import { ActivityBaseComponent } from "../../shared/activity-base.component";
import { Filters } from "src/app/models/filters.model";
import { MeasurementDeviceService } from "src/app/services/measurementDevice.service";
import { DatePipe } from "@angular/common";
import { DynamicPipeData, TimeFromSecondsPipe } from "@overa/shared";
import {
  OveraEchartData,
  OveraEchartHBarOptions,
} from "@overa/components/echart";
import { COLUMN_TIME_SHORT_PIPE } from "src/app/shared/pipes/column_pipes/column-data-formatter.pipe";

//TODO: Check what base class use for this screen. Original was BaseTableComponent
@Component({
  selector: "app-user-analisys",
  templateUrl: "./userAnalisys.component.html",
  styleUrls: ["./userAnalisys.component.scss"],
  providers: [DatePipe, TimeFromSecondsPipe],
})
export class UserAnalisysComponent extends ActivityBaseComponent {
  chartOptions: OveraEchartHBarOptions = new OveraEchartHBarOptions();
  chartData?: OveraEchartData;
  chartSize?: number;

  horaEntrada?: string;

  columnTimePipe: DynamicPipeData[] = COLUMN_TIME_SHORT_PIPE;

  constructor(private measurementDeviceService: MeasurementDeviceService) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    //configure chart

    this.chartOptions.seriesOption.barWidth = 20;

    this.chartOptions.seriesOption.label!.formatter = (params: any) => {
      return this.secondsToHMS(params.value);
    };

    this.chartOptions.axisLabelFormatter = (value: any) => {
      return this.secondsToHMS(value);
    };

    /* this.chartOptions.seriesOption.color = ['#ff5722', '#CCC', '#4caf50']; */

    /* this.chartOptions.legend.setData([
      { name: "serie1", selected: true, color: "#0FF" },
      { name: "serie2", selected: true, icon: ChartIcons.Triangle, color: "#00F" },
    ]); */
  }

  //TODO: Se reciben qué datos puede ver o no el tenant mediante la consulta a la tabla
  //DeviceAnalisysConfigView. Sin embargo, estos datos se están enviando desde el API aunque no
  //se muestren
  override loadData(filters: Filters): void {}

  override ngAfterViewInit(): void {
    this.loading = true;

    this.measurementDeviceService.getUserDeviceApps().subscribe((res) => {
      // set chart size
      this.chartSize = res.axisCategories!.length * 80;

      //set max value x Axis
      /* let maxValue = res.series![0].data! as number[];
      let roundedMaxValue = (Math.ceil(Math.max(...maxValue) / 1800) * 30) * 60;
      this.chartOptions.xAxis.max = roundedMaxValue; */
      this.chartOptions.axisLabelInterval = 1800;

      // set translations for categories
      res.translations = {
        activityDuration: "Duración activa",
        afkDuration: "Duración AFK",
        effectiveDuration: "Duración efectiva",
        sum: "Suma",
      };

      //set bars colors. We have to use translated names
      this.chartOptions.colorsByCategories = [
        { name: "Duración activa", colors: "#F00" },
        { name: "Duración AFK", colors: "#0F0" },
        { name: "Duración efectiva", colors: "#00F" },
        { name: "Suma", colors: "#CCC" },
      ];

      // set series
      this.chartData = res;

      // set other data for the screen
      this.horaEntrada = res.info?.["inDateTime"];

      this.loading = false;
    });
  }
}
