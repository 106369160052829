<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <mat-card-content>
                            <div class="container-fluid main-container">
                            
                                <div class="container-individualFilters">
                                    <app-filters #filterCmp
                                        [filterInit]=filterInit
                                        (loading)=setLoading($event)
                                        (loadData)=loadData($event)
                                        (filterChange)=changeFilter($event)
                                        (exportOnClick)=csvExport($event)
                                        (validAllRequiredFilters)=validAllRequiredFilters($event)
                                        (viewModeChange)=viewModeChange($event)>
                                    </app-filters>
                                </div>

                                <div class="row mt-5 mb-5">
                                    <div class="col-12">
                                        <div *ngIf="loading" class="p-component-overlay loading-overlay">
                                            <em class="pi pi-spin pi-spinner p-datatable-loading-icon"></em>
                                        </div>
                                        <overa-echart [size]="400" [options]="chartOptions" [data]="chartData"></overa-echart>
                                    </div>
                                </div>

                            </div>
                        </mat-card-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>