import { Component, OnInit, ViewChild } from "@angular/core";
import { OveraDataTableComponent } from "@overa/components/datatable";
import {
  generateDeviceResources,
  measurementPaginationProcess,
} from "./data-generator.helper";
import { DatePipe } from "@angular/common";
import { DynamicPipe } from "@overa/shared";
import { DinamicPipeData } from "src/app/models/dinamicPipeData.model";
import { COLUMN_DATETIME_LONG_PIPE } from "src/app/shared/pipes/column_pipes/column-data-formatter.pipe";
import {
  ChartIcons,
  OveraEchartData,
  OveraEchartLineOptions,
  createLineSerie,
} from "@overa/components/echart";
import { ConvertSizePipe } from "src/app/shared/pipes/size-conversions.pipe";
import * as moment from "moment";
import { color } from "echarts";
import { OveraDataTableColumn } from "@overa/components/common";

@Component({
  selector: "app-device-process-consumption",
  templateUrl: "./device-process-consumption.component.html",
  styleUrls: ["./device-process-consumption.component.scss"],
  providers: [DatePipe],
})
export class DeviceProcessConsumptionComponent implements OnInit {
  columns: OveraDataTableColumn[];
  datatableLiterals: { [literal: string]: string } = {
    NoData: $localize`:@@common.nodata: No data to display`,
    Yes: $localize`:@@common.yes: Yes`,
    No: $localize`:@@common.no: No`,
    FilterBy: $localize`:@@datatable.filterby: Filter by`,
    checkboxOff: $localize`:@@common.no: No`,
    checkboxOn: $localize`:@@common.yes: Yes`,
  };
  items: any = []; //TODO: add types
  itemsTemp: any[] = [];
  measurementsCount: number = 0;
  data: any[] = [];

  @ViewChild("datatable", { static: true })
  dataTable?: OveraDataTableComponent<any>;

  chartOptions: OveraEchartLineOptions = new OveraEchartLineOptions();
  chartData!: OveraEchartData;
  chartOptionsBytes: OveraEchartLineOptions = new OveraEchartLineOptions();
  chartDataBytes!: OveraEchartData;

  colors = {
    darkBlue: "#12239E",
    lightBlue: "#118DFF",
    lightGreen: "#02BB7A",
  };

  constructor() {
    this.columns = this.createColumns();
    this.data = generateDeviceResources(300);
    console.log("data", this.data);
    this.createChartsOptions();
    this.setChartData();
  }

  ngOnInit(): void {
    this.items = measurementPaginationProcess.measurements;
    this.itemsTemp = measurementPaginationProcess.measurements;
    this.measurementsCount = measurementPaginationProcess.count;
  }

  loadDataFunction = (
    pag: number,
    items_per_pag: number,
    order: { field: string; order: number }[]
  ): any[] => {
    if (pag != undefined && pag != null) {
      pag = pag * items_per_pag;

      const startIndex = pag;
      const endIndex = Math.min(pag + items_per_pag, this.itemsTemp.length);
      const loadItems = this.itemsTemp.slice(startIndex, endIndex);
      this.items = loadItems;
      if (this.dataTable) this.dataTable.items = loadItems;
      return loadItems;
    } else {
      return [];
    }
  };

  createColumns(): OveraDataTableColumn[] {
    return [
      {
        field: "startDate",
        header: $localize`:@@column.date:Fecha`,
        type: "DateTime",
        pipes: COLUMN_DATETIME_LONG_PIPE,
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "hostName",
        header: $localize`:@@common.device:Dispositivo`,
        type: "String",
        responsive: true,
      },
      {
        field: "userName",
        header: $localize`:@@common.userName:Usuario`,
        type: "String",
      },
      {
        field: "systemModel",
        header: $localize`:@@common.systemModel:Model`,
        type: "string",
      },
      {
        field: "total",
        header: $localize`:@@common.total:Total`,
        type: "String",
      },
      {
        field: "name",
        header: $localize`:@@common.processName:Proceso`,
        type: "String",
      },
      {
        field: "cpuUsage",
        header: $localize`:@@common.cpuPercentage:CPU %`,
        type: "String",
      },
      {
        field: "memory",
        header: $localize`:@@common.memoryMB:Memoria MB`,
        type: "String",
      },
      {
        field: "totalReadBytes",
        header: $localize`:@@common.readBytesKB:Bytes Leidos KB`,
        type: "String",
      },
      {
        field: "totalWrittenBytes",
        header: $localize`:@@common.writtenBytesKB:Bytes Escritos KB`,
        type: "String",
      },
    ];
  }

  createChartsOptions() {
    this.generateCommonOptions(this.chartOptions);
    //this.generateCommonOptions(this.chartOptionsBytes);
    this.generateOptions();
    //this.generateOptionsBytes();
  }

  generateCommonOptions(options: OveraEchartLineOptions) {
    options.title.textStyle = { fontFamily: "AristotelicaDisplay-Extralight" };

    options.setGrid([50, 70, 80, 60]);
    options.toolbox.show = true;
    options.toolbox.feature!.dataZoom = {
      yAxisIndex: "none",
      title: {
        zoom: "Zoom",
        back: "Revertir Zoom",
      },
    };
    options.toolbox.feature!.restore!.title = "Restaurar";
    options.toolbox.feature!.saveAsImage!.show = false;

    options.setZoomSlider(true, [80, 100]);
    options.setZoomInside(false);

    options.setTooltipFormatter(
      (series) => `${moment(series[0].data[0]).format("HH:mm:ss - DD/MM/YY")}`,
      (serie) =>
        `<br/>${serie.marker}${serie.seriesName}: ${serie.value[1]}  ${serie.value[2]}`
    );

    options.setXAxisForTime("HH:mm DD/MM/YY", true);
  }

  generateOptions() {
    const convertedSizePipe = new ConvertSizePipe();
    let maxTotalMemory = Math.max(...this.data.map((n) => n.totalMemory));
    maxTotalMemory =
      maxTotalMemory > 0
        ? +convertedSizePipe.transform(maxTotalMemory, "B", "MB").toFixed(2)
        : 0;

    this.chartOptions.setYAxis([
      {
        name: "CPU/GPU",
        max: 100,
        units: "%",
        paddingLeft: 20,
        splitLinecolor: this.colors.lightBlue,
      },
      {
        name: "MEMORY",
        units: "MB",
        paddingLeft: 60,
        splitLinecolor: this.colors.lightGreen,
      },
    ]);

    this.chartOptions.setLegend(
      [
        { name: "CPU", selected: true, color: "#02BB7A" },
        { name: "GPU", selected: false, color: "#118DFF" },
        { name: "MEMORY", selected: true, color: "#12239E" },
      ],
      {
        type: "scroll",
        left: 80,
        top: 10,
        icon: ChartIcons.Circle,
        itemGap: 60,
      }
    );
  }

  generateOptionsBytes() {
    this.chartOptionsBytes.setYAxis([
      {
        name: "Bytes leídos",
        units: "KB",
        paddingLeft: 60,
        splitLinecolor: this.colors.lightBlue,
      },
      {
        name: "Bytes escritos",
        units: "KB",
        paddingLeft: 60,
        splitLinecolor: this.colors.darkBlue,
      },
    ]);

    this.chartOptionsBytes.setLegend(
      [
        { name: "Bytes leídos", selected: true, color: this.colors.lightBlue },
        {
          name: "Bytes escritos",
          selected: false,
          color: this.colors.darkBlue,
        },
      ],
      {
        type: "scroll",
        left: 80,
        top: 10,
        icon: ChartIcons.Circle,
        itemGap: 60,
      }
    );
  }

  setChartData() {
    const convertedSizePipe = new ConvertSizePipe();
    let maxTotalMemory = Math.max(...this.data.map((n) => n.totalMemory));
    maxTotalMemory =
      maxTotalMemory > 0
        ? +convertedSizePipe.transform(maxTotalMemory, "B", "MB").toFixed(2)
        : 0;

    this.chartData = {
      series: [
        createLineSerie(
          "GPU",
          this.data.map((n) => [
            n.startDate,
            n.gpuUsage ? n.gpuUsage.toFixed(2) : null,
            "%",
          ]),
          0,
          this.colors.lightBlue
        ),
        createLineSerie(
          "MEMORY",
          this.data.map((n) => [
            n.startDate,
            convertedSizePipe.transform(n.usedMemory, "B", "MB"),
            "MB",
          ]),
          1,
          this.colors.darkBlue
        ),
        createLineSerie(
          "CPU",
          this.data.map((n) => [
            n.startDate,
            n.cpuUsage ? n.cpuUsage.toFixed(2) : null,
            "%",
          ]),
          0,
          this.colors.lightGreen
        ),
      ],
    };

    this.chartDataBytes = {
      series: [
        createLineSerie(
          "Read Bytes",
          this.data.map((n) => [
            n.startDate,
            convertedSizePipe.transform(n.totalReadBytes, "B", "KB"),
            "KB",
          ])
        ),
        createLineSerie(
          "Written Bytes",
          this.data.map((n) => [
            n.startDate,
            convertedSizePipe.transform(n.totalWrittenBytes, "B", "KB"),
            "KB",
          ])
        ),
      ],
    };
  }
}
