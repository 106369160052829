import { DatePipe } from "@angular/common";
import { DynamicPipeData } from "@overa/shared";
import { DurationPipe } from "../duration.pipe";
import { DinamicPipeData } from "src/app/models/dinamicPipeData.model";


export const FORMAT_DATE_TIME_LONG = "dd/MM/yyyy HH:mm:ss";
export const FORMAT_TIME_LONG = "longTime";
export const FORMAT_TIME_SHORT = "HH:mm:ss";
export const FORMAT_DATE_SHORT = "dd/MM/yyyy";
export const FORMAT_DATE_SHORT_YEAR = "DD/MM/YY";

export const COLUMN_DATETIME_LONG_PIPE: DinamicPipeData[] = [
  { name: DatePipe, args: [FORMAT_DATE_TIME_LONG] },
];

export const COLUMN_TIME_LONG_PIPE: DynamicPipeData[] = [
  { name: DatePipe, args: [FORMAT_TIME_LONG] },
];

export const COLUMN_TIME_SHORT_PIPE: DynamicPipeData[] = [
  { name: DatePipe, args: [FORMAT_TIME_SHORT] },
];

export const COLUMN_DATE_SHORT_PIPE: DynamicPipeData[] = [
  { name: DatePipe, args: [FORMAT_DATE_SHORT] },
];

export const COLUMN_DATE_SHORT_YEAR_PIPE: DynamicPipeData[] = [
  { name: DatePipe, args: [FORMAT_DATE_SHORT_YEAR] },
];

export const COLUMN_TIME_DURATION_PIPE: DynamicPipeData[] = [
  { name: DurationPipe, args: [] }];

