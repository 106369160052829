import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseService, ConfigsService } from "@overa/shared";
import { Filters } from "../models/filters.model";
import { Observable, of } from "rxjs";
import { OveraEchartData } from "@overa/components/echart";
import { TreeNode } from "primeng/api";
import { ActivitySummary } from "../models/userActivity/activitySummary.model";
import { ActivitySummaryGroupedType } from "../models/userActivity/activitySummaryGrouped.model";
import { AppUrlSchedule } from "../models/userActivity/appUrlSchedule.model";

@Injectable({ providedIn: "root" })
export class MeasurementService extends BaseService<OveraEchartData> {
  constructor(override http: HttpClient, override config: ConfigsService) {
    super(http, config);
  }

  override get apiBaseUrl(): string {
    return this.config.getModuleConfig("overa-security", "apiBaseUrl") + "/api";
  }
  override get path(): string {
    throw new Error("Method not implemented.");
  }

  getDetailedView(filters: Filters) {
    return this.http.post<any>(
      `${this.apiBaseUrl}/Measurement/detailedView`,
      filters
    );
  }

  getActivitySummary(filters: Filters) {
    return this.http.post<ActivitySummary[]>(
      `${this.apiBaseUrl}/Measurement/activitySummary`,
      filters
    );
  }

  getActivitySummaryGrouped(filters: Filters) {
    return this.http.post<ActivitySummary[]>(
      `${this.apiBaseUrl}/Measurement/activitySummaryGrouped`,
      filters
    );
  }

  /*   getActivitySummaryGroupedAsTreeNode(filters: Filters) {
    return this.http.post<TreeNode[]>(
      `${this.apiBaseUrl}/Measurement/activitySummaryGroupedAsTreeNode`,
      filters
    );
  } */

  getMeasurementsGroupedTelecommuting(filters: Filters) {
    return this.http.post<OveraEchartData[]>(
      `${this.apiBaseUrl}/MeasurementProcessed/telecommuting-vs-onsite/summary`,
      filters
    );
  }

  getSimpleActivitySummaryByEmployee(
    filters: Filters
  ): Observable<OveraEchartData> {
    return this.http.post<OveraEchartData>(
      `${this.apiBaseUrl}/MeasurementProcessed/userActivity2weks`,
      filters
    );
  }

  getActivityEmployeeByRange(filters: Filters): Observable<OveraEchartData[]> {
    return this.http.post<OveraEchartData[]>(
      `${this.apiBaseUrl}/Measurement/activityEmployeeByRange`,
      filters
    );
  }

  getProductivityByDay(filters: Filters): Observable<OveraEchartData> {
    return this.http.post<OveraEchartData>(
      `${this.apiBaseUrl}/Measurement/productivityByDay`,
      filters
    );
  }

  getMeasurementsGroupedEntryExit(
    filters: Filters
  ): Observable<OveraEchartData[]> {
    return this.http.post<OveraEchartData[]>(
      `${this.apiBaseUrl}/Measurement/measurementsGroupedEntryExit`,
      filters
    );
  }

  getProductivityWeekByDepartment(
    filters: Filters
  ): Observable<OveraEchartData> {
    return this.http.post<OveraEchartData>(
      `${this.apiBaseUrl}/Measurement/productivityWeekByDepartment`,
      filters
    );
  }

  getAppUrlShedule(filters: Filters): Observable<AppUrlSchedule[]> {
    /* return this.http.post<AppUrlSchedule[]>(
      `${this.apiBaseUrl}/Measurement/getAppUrlShedule`,
      filters
    ); */
    // TODO Mockup response from API
    let responseFromApi: AppUrlSchedule[] = [
      {
        firstStartDate: new Date("2023-01-01T09:00:00"),
        lastStartDate: new Date("2023-01-01T17:00:00"),
        duration: "2h",
        appName: "App 1",
        userName: "User 1",
        departamentNameEmployee: "IT",
        inWorkHours: true,
      },
      {
        firstStartDate: new Date("2023-01-02T09:00:00"),
        lastStartDate: new Date("2023-01-02T17:00:00"),
        duration: "30s",
        appName: "App 2",
        userName: "User 2",
        departamentNameEmployee: "HR",
        inWorkHours: false,
      },
      {
        firstStartDate: new Date("2023-01-03T00:00:25"),
        lastStartDate: new Date("2023-01-03T00:00:25"),
        duration: "5m",
        appName: "",
        userName: "User 3",
        departamentNameEmployee: "Finance",
        inWorkHours: true,
      },
      {
        firstStartDate: new Date("2023-01-04T00:00:28"),
        lastStartDate: new Date("2023-01-04T17:50:28"),
        duration: "50m",
        appName: "App 4",
        userName: "User 4",
        departamentNameEmployee: "Sales",
        inWorkHours: true,
      },
      {
        firstStartDate: new Date("2023-01-05T09:00:00"),
        lastStartDate: new Date("2023-01-05T17:00:00"),
        duration: "1s",
        appName: "App 5",
        userName: "User 5",
        departamentNameEmployee: "Marketing",
        inWorkHours: false,
      },
      {
        firstStartDate: new Date("2023-01-06T09:00:00"),
        lastStartDate: new Date("2023-01-06T17:00:00"),
        duration: "40m",
        appName: "App 6",
        userName: "User 6",
        departamentNameEmployee: "Support",
        inWorkHours: true,
      },
      {
        firstStartDate: new Date("2023-01-07T09:00:00"),
        lastStartDate: new Date("2023-01-07T17:00:00"),
        duration: "2s",
        appName: "App 7",
        userName: "User 7",
        departamentNameEmployee: "Engineering",
        inWorkHours: true,
      },
      {
        firstStartDate: new Date("2023-01-08T09:00:00"),
        lastStartDate: new Date("2023-01-08T17:00:00"),
        duration: "29s",
        appName: "App 8",
        userName: "User 8",
        departamentNameEmployee: "Design",
        inWorkHours: false,
      },
      {
        firstStartDate: new Date("2023-01-09T09:00:00"),
        lastStartDate: new Date("2023-01-09T17:00:00"),
        duration: "40m",
        appName: "App 9",
        userName: "User 9",
        departamentNameEmployee: "Product",
        inWorkHours: false,
      },
      {
        firstStartDate: new Date("2023-01-10T09:00:00"),
        lastStartDate: new Date("2023-01-10T17:00:00"),
        duration: "3s",
        appName: "App 10",
        userName: "User 10",
        departamentNameEmployee: "Legal",
        inWorkHours: true,
      },
      {
        firstStartDate: new Date("2023-01-11T09:00:00"),
        lastStartDate: new Date("2023-01-11T17:00:00"),
        duration: "1m",
        appName: "App 11",
        userName: "User 11",
        departamentNameEmployee: "Operations",
        inWorkHours: true,
      },
      {
        firstStartDate: new Date("2023-01-12T09:00:00"),
        lastStartDate: new Date("2023-01-12T17:00:00"),
        duration: "8h",
        appName: "App 12",
        userName: "User 12",
        departamentNameEmployee: "Admin",
        inWorkHours: true,
      },
    ];
    return of(responseFromApi);
  }

  getProductivityByDayType(filters: Filters): Observable<OveraEchartData[]> {
    return this.http.post<OveraEchartData[]>(
      `${this.apiBaseUrl}/Measurement/productivityByDayType`,
      filters
    );
  }
}
