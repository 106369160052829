<div>
    <div class="container-up">
        <div class="measurements-count">
            <h1 class="measurements-count">{{measurementsCount}} medidas procesadas</h1>
        </div>
        <div class="table-title">Consumo de procesos</div>
    </div>
    <div class="container-down">
        <div class="container-left">
            <overa-datatable
            #dataTable
            [columns]="columns"
            [loadDataFunction]="loadDataFunction"
            [localizeLiterals]="datatableLiterals"
            title="Data"
            [showGlobalFilter]="false"
            [rowsPerPage]="[10, 20]"
            [items]="items"
            [showExports]="false"
            [showPaginator]="true"
        ></overa-datatable>
        </div>
        <div class="container-right">
            <div class="chart-up">
                <!-- CHART FOR CPU , MEMORY and GPU -->
                <overa-echart [size]="400" [options]="chartOptions" [data]="chartData"></overa-echart>
                <!-- <div *ngIf="true" echarts [options]="chartOptions" [merge]="chartData" style="height: 400px;"></div> -->
            </div>
            <div class="chart-down">
                <!-- CHART FOR WRITTEN and READ BYTES -->
                <overa-echart [size]="400" [options]="chartOptionsBytes" [data]="chartDataBytes"></overa-echart>
                <!-- <div *ngIf="true" echarts [options]="chartOptionsBytes" [merge]="chartDataBytes" style="height: 400px;"></div> -->

            </div>
        </div>
    </div>    
</div>