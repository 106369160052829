export function generateDeviceResources(num: number) {
  const deviceResources = [];
  let startDate = new Date(); // Fecha inicial

  for (let i = 0; i < num; i++) {
    deviceResources.push({
      type: "proc",
      hostName: null,
      startDate: new Date(
        startDate.setSeconds(startDate.getSeconds() + i)
      ).toISOString(),
      cpuUsage: Math.random(),
      gpuUsage: 0.0,
      cpuBrand: null,
      usedMemory: Math.random() * 10000000000,
      totalMemory: Math.random() * 20000000000,
      totalDisksSpace: 0.0,
      availableDisksSpace: 0.0,
      totalReadBytes: Math.random() * 10000,
      totalWrittenBytes: Math.random() * 1000,
      usageDisksSpace: 0.0,
    });
  }
  return deviceResources;
}

export const measurementPaginationProcess = {
  // estos son para la tabla
  count: 264831,
  page: 0,
  measurements: [
    {
      totalMemory: "17178791936",
      cpuUsage: 0.0,
      memory: 10137600.0,
      name: "msdtc.exe",
      pids: "4156,",
      startTime: 1711390634,
      userName: "network service",
      totalWrittenBytes: 199100.0,
      writtenBytes: 0.0,
      totalReadBytes: 0.0,
      readBytes: 0.0,
      hashApp: "96aebdd8ffede93c",
      gpuUsage: 0.0,
      hostUser: "citrix02-network service",
      total: 1,
      identityJob: null,
      systemModel: "vmware7,1",
      status: "Default",
      type: "proc",
      uid: "YyQLRxs8gUOPI1riZ1Ub9",
      appVersion: "1.17.0",
      hostName: "citrix02",
      startDate: "2024-04-24T00:00:10+02:00",
      hostAlias: null,
    },
    {
      totalMemory: "17178791936",
      cpuUsage: 0.0,
      memory: 10723328.0,
      name: "mpcmdrun.exe",
      pids: "5480,",
      startTime: 1713837575,
      userName: "network service",
      totalWrittenBytes: 0.0,
      writtenBytes: 0.0,
      totalReadBytes: 0.0,
      readBytes: 0.0,
      hashApp: "b8d001d0acbc8dc2",
      gpuUsage: 0.0,
      hostUser: "citrix02-network service",
      total: 1,
      identityJob: null,
      systemModel: "vmware7,1",
      status: "Default",
      type: "proc",
      uid: "YyQLRxs8gUOPI1riZ1Ub9",
      appVersion: "1.17.0",
      hostName: "citrix02",
      startDate: "2024-04-24T00:00:10+02:00",
      hostAlias: null,
    },
    {
      totalMemory: "17178791936",
      cpuUsage: 0.0,
      memory: 28721152.0,
      name: "concentr.exe",
      pids: "7248,6860,",
      startTime: 1711390899,
      userName: "administrator",
      totalWrittenBytes: 3.0,
      writtenBytes: 0.0,
      totalReadBytes: 102.0,
      readBytes: 0.0,
      hashApp: "7a893ae84ecadce8",
      gpuUsage: 0.0,
      hostUser: "citrix02-administrator",
      total: 2,
      identityJob: "8",
      systemModel: "vmware7,1",
      status: "Default",
      type: "proc",
      uid: "YyQLRxs8gUOPI1riZ1Ub9",
      appVersion: "1.17.0",
      hostName: "citrix02",
      startDate: "2024-04-24T00:00:10+02:00",
      hostAlias: null,
    },
    {
      totalMemory: "17178791936",
      cpuUsage: 0.0,
      memory: 78807040.0,
      name: "svchost.exe",
      pids: "2532,844,992,1052,",
      startTime: 1711390630,
      userName: "network service",
      totalWrittenBytes: 952.0,
      writtenBytes: 0.0,
      totalReadBytes: 83.0,
      readBytes: 0.0,
      hashApp: "3c510c432c118396",
      gpuUsage: 0.0,
      hostUser: "citrix02-network service",
      total: 4,
      identityJob: null,
      systemModel: "vmware7,1",
      status: "Default",
      type: "proc",
      uid: "YyQLRxs8gUOPI1riZ1Ub9",
      appVersion: "1.17.0",
      hostName: "citrix02",
      startDate: "2024-04-24T00:00:10+02:00",
      hostAlias: null,
    },
    {
      totalMemory: "17178791936",
      cpuUsage: 0.0,
      memory: 11710464.0,
      name: "nissrv.exe",
      pids: "11128,",
      startTime: 1712773752,
      userName: "local service",
      totalWrittenBytes: 0.0,
      writtenBytes: 0.0,
      totalReadBytes: 0.0,
      readBytes: 0.0,
      hashApp: "523515587f2af255",
      gpuUsage: 0.0,
      hostUser: "citrix02-local service",
      total: 1,
      identityJob: null,
      systemModel: "vmware7,1",
      status: "Default",
      type: "proc",
      uid: "YyQLRxs8gUOPI1riZ1Ub9",
      appVersion: "1.17.0",
      hostName: "citrix02",
      startDate: "2024-04-24T00:00:10+02:00",
      hostAlias: null,
    },
    {
      totalMemory: "17178791936",
      cpuUsage: 0.0,
      memory: 21688320.0,
      name: "wmsvc.exe",
      pids: "2132,",
      startTime: 1711390628,
      userName: "local service",
      totalWrittenBytes: 9.0,
      writtenBytes: 0.0,
      totalReadBytes: 380.0,
      readBytes: 0.0,
      hashApp: "56caf77be6eeea48",
      gpuUsage: 0.0,
      hostUser: "citrix02-local service",
      total: 1,
      identityJob: null,
      systemModel: "vmware7,1",
      status: "Default",
      type: "proc",
      uid: "YyQLRxs8gUOPI1riZ1Ub9",
      appVersion: "1.17.0",
      hostName: "citrix02",
      startDate: "2024-04-24T00:00:10+02:00",
      hostAlias: null,
    },
    {
      totalMemory: "17178791936",
      cpuUsage: 0.0,
      memory: 105889792.0,
      name: "citrix.deliveryservices.credentialwallet.servicehost.exe",
      pids: "5964,",
      startTime: 1711515276,
      userName: "network service",
      totalWrittenBytes: 331759.0,
      writtenBytes: 0.0,
      totalReadBytes: 33470.0,
      readBytes: 0.0,
      hashApp: "d7c6426b26344c03",
      gpuUsage: 0.0,
      hostUser: "citrix02-network service",
      total: 1,
      identityJob: null,
      systemModel: "vmware7,1",
      status: "Default",
      type: "proc",
      uid: "YyQLRxs8gUOPI1riZ1Ub9",
      appVersion: "1.17.0",
      hostName: "citrix02",
      startDate: "2024-04-24T00:00:10+02:00",
      hostAlias: null,
    },
    {
      totalMemory: "17178791936",
      cpuUsage: 0.0,
      memory: 12283904.0,
      name: "rdpclip.exe",
      pids: "1748,",
      startTime: 1711390866,
      userName: "administrator",
      totalWrittenBytes: 15.0,
      writtenBytes: 0.0,
      totalReadBytes: 19015.0,
      readBytes: 0.0,
      hashApp: "c1cde9deba5a60fb",
      gpuUsage: 0.0,
      hostUser: "citrix02-administrator",
      total: 1,
      identityJob: "8",
      systemModel: "vmware7,1",
      status: "Default",
      type: "proc",
      uid: "YyQLRxs8gUOPI1riZ1Ub9",
      appVersion: "1.17.0",
      hostName: "citrix02",
      startDate: "2024-04-24T00:00:10+02:00",
      hostAlias: null,
    },
    {
      totalMemory: "17178791936",
      cpuUsage: 0.0,
      memory: 48627712.0,
      name: "citrix.deliveryservices.servicemonitor.servicehost.exe",
      pids: "2020,",
      startTime: 1711390628,
      userName: "network service",
      totalWrittenBytes: 0.0,
      writtenBytes: 0.0,
      totalReadBytes: 387.0,
      readBytes: 0.0,
      hashApp: "34b8a646604fdf01",
      gpuUsage: 0.0,
      hostUser: "citrix02-network service",
      total: 1,
      identityJob: null,
      systemModel: "vmware7,1",
      status: "Default",
      type: "proc",
      uid: "YyQLRxs8gUOPI1riZ1Ub9",
      appVersion: "1.17.0",
      hostName: "citrix02",
      startDate: "2024-04-24T00:00:10+02:00",
      hostAlias: null,
    },
    {
      totalMemory: "17178791936",
      cpuUsage: 0.0,
      memory: 10420224.0,
      name: "vgauthservice.exe",
      pids: "2164,",
      startTime: 1711390628,
      userName: "system",
      totalWrittenBytes: 27.0,
      writtenBytes: 0.0,
      totalReadBytes: 31.0,
      readBytes: 0.0,
      hashApp: "28e3618cc52fe616",
      gpuUsage: 0.0,
      hostUser: "citrix02-system",
      total: 1,
      identityJob: null,
      systemModel: "vmware7,1",
      status: "Default",
      type: "proc",
      uid: "YyQLRxs8gUOPI1riZ1Ub9",
      appVersion: "1.17.0",
      hostName: "citrix02",
      startDate: "2024-04-24T00:00:10+02:00",
      hostAlias: null,
    },
  ],
};
