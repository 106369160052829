import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseService, ConfigsService } from "@overa/shared";
import { Filters } from "../models/filters.model";
import { Observable, of } from "rxjs";
import { OveraEchartData } from "@overa/components/echart/";

@Injectable({
  providedIn: "root",
})
export class MeasurementUserService extends BaseService<OveraEchartData> {
  constructor(override http: HttpClient, override config: ConfigsService) {
    super(http, config);
  }

  override get apiBaseUrl(): string {
    return this.config.getModuleConfig("overa-security", "apiBaseUrl") + "/api";
  }
  override get path(): string {
    throw new Error("Method not implemented.");
  }

  getUsersByDays(filters: Filters): Observable<OveraEchartData> {
    return this.http.post<OveraEchartData>(
      `${this.apiBaseUrl}/MeasurementTelemetryUser/usersByDays`,
      filters
    );
  }

  /*
  NOTA: Los datos necesarios para un gráfico custom son:
  
  [0] graphIndex: number;
  [1] color: string;
  [2] index: number;
  [3] startTime: string;
  [4] endTime: string;
  [5...] [key: string]: any; // Información adicional

  El API no necesita devolver los dos primeros porque se los aporta el front
  */
  getStatusOneLine(filters: Filters): Observable<OveraEchartData> {
    // filters["startDate"] = new Date(2024, 4, 14, 0, 0, 0);
    // filters["endDate"] = new Date(2024, 4, 14, 23, 59, 59);
    return this.http.post<OveraEchartData>(
      `${this.apiBaseUrl}/MeasurementTelemetryUser/statusSummaryOneLine`,
      filters
    );
    const toReturn: OveraEchartData = {
      series: [
        {
          name: "unproductive",
          data: [
            {
              value: [
                0,
                "2024-02-08T12:11:04+01:00",
                "2024-02-08T12:11:07+01:00",
                "host-user1",
                "app1",
              ],
            },
            {
              value: [
                0,
                "2024-02-08T12:23:30+01:00",
                "2024-02-08T12:23:32+01:00",
                "host-user1",
                "app2",
              ],
            },
          ],
        },
        {
          name: "productive",
          data: [
            {
              value: [
                0,
                "2024-02-08T08:00:00",
                "2024-02-08T08:01:16+01:00",
                "host-user1",
                "app2",
              ],
            },
            {
              value: [
                0,
                "2024-02-08T08:01:16+01:00",
                "2024-02-08T08:11:16+01:00",
                "host-user1",
                "app8",
              ],
            },
            {
              value: [
                1,
                "2024-02-08T12:40:15+01:00",
                "2024-02-08T12:41:12+01:00",
                "host-user2",
                "app5",
              ],
            },
            {
              value: [
                1,
                "2024-02-08T12:41:20+01:00",
                "2024-02-08T12:41:59+01:00",
                "host-user2",
                "app1",
              ],
            },
          ],
        },
        {
          name: "current",
          data: [
            {
              value: [
                0,
                "2024-02-08T15:00:00",
                "2024-02-08T16:01:16+01:00",
                "host-user1",
                "app2",
              ],
            },
            {
              value: [
                0,
                "2024-02-08T16:01:16+01:00",
                "2024-02-08T16:11:16+01:00",
                "host-user1",
                "app8",
              ],
            },
            {
              value: [
                1,
                "2024-02-08T17:40:15+01:00",
                "2024-02-08T17:41:12+01:00",
                "host-user2",
                "app5",
              ],
            },
            {
              value: [
                1,
                "2024-02-08T17:41:20+01:00",
                "2024-02-08T19:41:59+01:00",
                "host-user2",
                "app1",
              ],
            },
          ],
        },
        {
          name: "alive",
          data: [
            {
              value: [
                0,
                "2024-02-08T08:00:00",
                "2024-02-08T08:01:16+01:00",
                "host-user1",
                "host",
              ],
            },
            {
              value: [
                0,
                "2024-02-08T08:01:16+01:00",
                "2024-02-08T08:11:16+01:00",
                "host-user1",
                "host",
              ],
            },
            {
              value: [
                1,
                "2024-02-08T12:40:15+01:00",
                "2024-02-08T12:41:12+01:00",
                "host-user2",
                "host",
              ],
            },
            {
              value: [
                1,
                "2024-02-08T12:41:20+01:00",
                "2024-02-08T12:41:59+01:00",
                "host-user2",
                "host",
              ],
            },
          ],
        },
        {
          name: "started",
          data: [
            {
              value: [
                0,
                "2024-02-08T08:00:00",
                "2024-02-08T08:01:16+01:00",
                "host-user1",
                "host",
              ],
            },
            {
              value: [
                0,
                "2024-02-08T08:01:16+01:00",
                "2024-02-08T08:11:16+01:00",
                "host-user1",
                "host",
              ],
            },
            {
              value: [
                1,
                "2024-02-08T12:40:15+01:00",
                "2024-02-08T12:41:12+01:00",
                "host-user2",
                "host",
              ],
            },
            {
              value: [
                1,
                "2024-02-08T12:41:20+01:00",
                "2024-02-08T12:41:59+01:00",
                "host-user2",
                "host",
              ],
            },
          ],
        },
      ],
      axisCategories: ["host-user1", "host-user2"],
    };

    return of(toReturn);
  }
}
