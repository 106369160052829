import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'convertSize'
  })
  export class ConvertSizePipe implements PipeTransform {
   /**
     * Converts a size from one measure to another.
     * @param size The value to convert.
     * @param from The measure of the value to be converted. Possible values: B, KB, MB, GB, TB, PB, EB, ZB, YB.
     * @param to The measure of the resulting value. Possible values: B, KB, MB, GB, TB, PB, EB, ZB, YB.
     * @param round If true, it returns the value rounded to two decimal places. Otherwise, it returns it without round. Default true.
     */
    transform(size: number, from: string, to: string, round: boolean = true): number {
      let sizeMeasurements = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      let fromIndex = sizeMeasurements.indexOf(from.toUpperCase());
      let toIndex = sizeMeasurements.indexOf(to.toUpperCase());
      if (fromIndex < 0) { throw new Error("Invalid parameter 'from'"); }
      if (toIndex < 0) { throw new Error("Invalid parameter 'to'"); }
      let exponent = toIndex - fromIndex;
      let convertedSize = size / Math.pow(1024, exponent);
      return round ? Math.round(convertedSize * 100) / 100 : convertedSize;
    }
}