
import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
interface Roles {
    id: string,
    name: string
}
@Component({
    selector: 'filter-dialog',
    templateUrl: './filterDefaultDialog.component.html',
})
export class FilterDefaultDialog implements OnInit {
    rolesDropdown: Roles[] = [];
    selectedUids: any
    constructor(private dialogConfig: DynamicDialogConfig, private ref: DynamicDialogRef) { }

    ngOnInit(): void {
        this.rolesDropdown = this.dialogConfig.data;
     
    }
    selectRole(){
        this.ref.close(this.selectedUids);
    }

}

