import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
interface Roles {
  id: string;
  name: string;
}
@Component({
  selector: "saveLayout-dialog",
  templateUrl: "./saveLayoutDialog.component.html",
})
export class SaveLayoutDialog implements OnInit {
  layoutName: string = "";
  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.layoutName = this.dialogConfig.data;
  }

  save() {
    this.ref.close(this.layoutName);
  }
}
