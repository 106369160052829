import {
  ChangeDetectorRef,
  Component,
  Type,
  ViewChild,
  inject,
} from "@angular/core";
import { NavigationEnd, Event, Route } from "@angular/router";

import { OveraLayoutComponent } from "@overa/base";

import { Permissions } from "@overa/security";

import { Menu } from "@overa/security";

import { ActivityGlobals } from "../utils/globals";

import { GoldenLayoutComponent } from "./goldenLayout/golden-layout.component";
import { GoldenLayoutService } from "../services/golden-layout.service";
import { Constants } from "../utils/constants";
import { environment } from "src/environments/environment";
import { logLog } from "@overa/shared";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent extends OveraLayoutComponent {
  static override className = "LayoutComponent";

  globals = inject(ActivityGlobals);
  cdRef = inject(ChangeDetectorRef);
  goldenLayoutService = inject(GoldenLayoutService);

  override languages: { key: string; translateKey: string }[] = [
    { key: "es", translateKey: "Español" },
    { key: "en", translateKey: "English" },
  ];

  viewGolden: boolean = false;

  @ViewChild("goldenLayoutHost")
  private _goldenLayoutHostComponent!: GoldenLayoutComponent;

  routerOuletContainer: HTMLElement | null = null;
  homeContainer: HTMLElement | null = null;
  goldenLayoutHostContainer: HTMLElement | null = null;

  useGoldenLayout: boolean = this.config.getModuleConfig(
    "overa-shared",
    "useGoldenLayout"
  );

  isGoldenLayoutView: boolean | undefined = true;

  isCreateButtonActive = false;
  showGlobalFilter = false;

  version!: string;

  override init = () => {
    if (!environment.production || environment.version.endsWith("-stg")) {
      this.version = environment.version;
    } else {
      this.version = "?";
      console.log(`${environment.app} ${environment.version}`);
    }
    this.notificationService.notifications.subscribe((notif) => {
      const countNotifications = notif.filter(
        (x) => !x.isConfirmed && x.isInternal
      ).length;
      this.setNotificationsCircle(countNotifications);
    });
  };

  override ngAfterViewInit() {
    this.cdRef.detectChanges();
    setTimeout(() => {
      this._goldenLayoutHostComponent.initialise();
      this.goldenLayoutService.initialise(this._goldenLayoutHostComponent);
    }, 0);

    this.routerOuletContainer = document.querySelector(
      "#routerOuletContainer"
    ) as HTMLElement;
    this.goldenLayoutHostContainer = document.querySelector(
      "app-golden-layout-host"
    ) as HTMLElement;
    this.homeContainer = document.querySelector(
      "#homeContainer"
    ) as HTMLElement;
  }

  // START MENU MANAGEMENT

  override async subscribeMenus() {
    const defaultMenuMaintenanceIcon = Constants.DEFAULT_MENU_MAINTENANCE_ICON;
    this.menuService?.menuLoaded.subscribe((menuItems: Menu[]) => {
      if (menuItems) {
        const hasMenuAccess = this.getHasMenuAccesValue();
        if (
          hasMenuAccess &&
          !menuItems?.map((m: Menu) => m.uiUrl).includes("/core/security/menu")
        ) {
          this.pushMenuForSecurityMenu(defaultMenuMaintenanceIcon, menuItems);
        }

        menuItems = this.processMenuItemsByParentOrUnique(menuItems);
        const finalItems: Menu[] = this.processFinalMenuItems(menuItems);

        this.menuItems = [...finalItems];
        this.calculateMenubarItems();
      }
    });
    await this.menuService.loadMenu();
  }

  private processFinalMenuItems(menuItems: Menu[]) {
    const finalItems: Menu[] = [];
    menuItems.forEach((menu: Menu) => {
      if (!finalItems.map((x) => x.id).includes(menu.id)) {
        finalItems.push(menu);
      }
    });
    return finalItems;
  }

  private processMenuItemsByParentOrUnique(menuItems: Menu[]) {
    menuItems = menuItems.filter((m: Menu) => !m.parentId);

    menuItems?.forEach((m: Menu) => {
      if (m.uiUrl) {
        m.type = "link";
      }
      if (m.children && m.children.length) {
        m.type = "sub";
        m.collapse = "child" + m.id;
      }
      m.isVisible = true;
    });
    return menuItems;
  }

  private pushMenuForSecurityMenu(
    defaultMenuMaintenanceIcon: string,
    menuItems: Menu[]
  ) {
    const menuItem = new Menu();
    menuItem.uiUrl = "/core/security/menu";
    menuItem.name = "Menu";
    menuItem.svg = defaultMenuMaintenanceIcon;
    menuItem.toolTip = "Menu";

    menuItems.push(menuItem);
  }

  private getHasMenuAccesValue() {
    let hasMenuAccess = this.accountService.currentUser?.isGlobalAdmin;
    if (!hasMenuAccess) {
      hasMenuAccess = this.accountService.userHasPermission(
        Permissions.PERMISSIONS_DATA["viewMenus"].name
      );
    }
    return hasMenuAccess;
  }

  //START SECTION ROUTER EVENTS

  override subscribeToRouterEvents() {
    this.routeSubscription = this.router.events.subscribe((event: Event) => {
      this.handleNavigationEvent(event);
    }) as any;

    super.subscribeToRouterEvents();
  }

  private handleNavigationEvent(event: Event) {
    let currentRouterUrl = this.router.url.toString();
    if ((event as any).routerEvent instanceof NavigationEnd) {
      currentRouterUrl = (event as any).routerEvent?.url;
      if (this.useGoldenLayout) {
        let foundInLazy = false;
        const menuItems = this.menuService.currentMenu;
        let currentMenuView = this.findCurrentMenuItem(
          menuItems,
          currentRouterUrl
        );
        const registeredGoldenComponentTypes =
          this.goldenLayoutService.getRegisteredComponentTypes();

        let currentMenuViewUiUrl = currentMenuView?.uiUrl?.startsWith("/")
          ? currentMenuView.uiUrl?.substring(1)
          : currentMenuView?.uiUrl;

        const registeredGoldenComponents =
          this.goldenLayoutService.getRegisteredComponentTypeNames();
        if (!currentMenuView) {
          currentMenuView = this.findCurrentMenuItemDynamic(
            registeredGoldenComponentTypes,
            currentRouterUrl
          );
          if (currentMenuView) {
            foundInLazy = true;
            currentMenuViewUiUrl = currentMenuView?.uiUrl?.startsWith("/")
              ? currentMenuView.uiUrl?.substring(1)
              : currentMenuView?.uiUrl;
          }
        } else {
          //TODO hotfix to lazy modules
          if (
            !registeredGoldenComponents.find((r: any) =>
              r.startsWith(currentMenuViewUiUrl)
            )
          ) {
            currentMenuView = this.findCurrentMenuItemDynamic(
              registeredGoldenComponentTypes,
              currentRouterUrl
            );
            if (currentMenuView) {
              foundInLazy = true;
            }
          }
        }

        const haveDynamicChilds =
          registeredGoldenComponents.filter(
            (r) => r.startsWith(currentMenuViewUiUrl!) && r.includes(":")
          ).length > 0
            ? true
            : false;

        if (
          currentMenuView &&
          (registeredGoldenComponents.find((r: any) =>
            r.startsWith(currentMenuViewUiUrl)
          ) ||
            foundInLazy)
        ) {
          this.routerOuletContainer!.style.display = "none";
          this.homeContainer!.style.display = "none";
          this.goldenLayoutHostContainer!.style.zIndex = "1";

          this.viewGolden = true;
          this._goldenLayoutHostComponent?.openInGoldenLayout(currentMenuView);
        } else if (currentMenuView?.componentName != null) {
          this.viewGolden = false;
          this.routerOuletContainer!.style.display = "block";
          this.homeContainer!.style.display = "none";
          this.goldenLayoutHostContainer!.style.zIndex = "-1";
        }
      } else {
        if (this.sidebar) {
          const menuItems = this.sidebar.getCurrentMenu();
          if (menuItems) {
            this.menuService.updateMainRoute(menuItems);
          }
        }
      }
    }
  }

  findCurrentMenuItem(
    menuItems: Menu[],
    currentRouterUrl: string
  ): Menu | null {
    for (const menuItem of menuItems) {
      if (menuItem.uiUrl === currentRouterUrl) {
        return menuItem;
      }
      if (menuItem.children && menuItem.children.length > 0) {
        const foundInChildren = this.findCurrentMenuItem(
          menuItem.children,
          currentRouterUrl
        );
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }

    return null;
  }

  findCurrentMenuItemDynamic_old(
    menuItems: Menu[],
    currentRouterUrl: string
  ): Menu | null {
    for (const menuItem of menuItems) {
      const regexString = menuItem.uiUrl
        ?.replace(/\//g, "\\/")
        .replace(/:\w+/g, "(\\w|-)+");

      const regexPattern = new RegExp("^" + regexString + "$");
      if (regexPattern.test(currentRouterUrl)) {
        return menuItem;
      }
      // Recursividad en los hijos si los hay
      if (menuItem.children && menuItem.children.length > 0) {
        const foundInChildren = this.findCurrentMenuItemDynamic_old(
          menuItem.children,
          currentRouterUrl
        );
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }
    return null;
  }

  findCurrentMenuItemDynamic(
    menuItems: Route[],
    currentRouterUrl: string,
    basePath = ""
  ): Menu | Route | null {
    for (const menuItem of menuItems) {
      let fixedUrl = currentRouterUrl.startsWith("/")
        ? currentRouterUrl.substring(1)
        : currentRouterUrl;
      const fullPath = basePath + (menuItem.path || "");
      const regexString = fullPath
        ?.replace(/\//g, "\\/")
        .replace(/:\w+/g, "([^/]+)");

      const regexPattern = new RegExp("^" + regexString + "$");
      if (regexPattern.test(fixedUrl)) {
        const returnedMenuItem = { ...menuItem, path: fullPath };
        return returnedMenuItem;
      }

      // Recursividad en los hijos si los hay
      if (menuItem.children && menuItem.children.length > 0) {
        const foundInChildren = this.findCurrentMenuItemDynamic(
          menuItem.children,
          currentRouterUrl,
          fullPath + "/"
        );
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }
    return null;
  }

  //END SECTION ROUTER EVENTS

  closeRouterOutlet() {
    //FIXME: this,.goldenLayoutHost doesnt exists
    // if (this.goldenLayoutHost) {
    //   this.goldenLayoutHost.style.zIndex = "1";
    // }
    if (this.routerOuletContainer) {
      this.routerOuletContainer.style.display = "none";
    }
    const goldenContents = this._goldenLayoutHostComponent.goldenLayout.rootItem?.contentItems;
    if (!goldenContents || goldenContents?.length == 0) {
      if (this.homeContainer) {
        this.homeContainer.style.display = "block";
        this.goldenLayoutHostContainer!.style.zIndex = "-1";
      }
    } else {
      this.goldenLayoutHostContainer!.style.zIndex = "1";
    }
    this.routerOuletContainer!.style.display = "none";
    this._goldenLayoutHostComponent?.selectItemAndNavigate();
  }

  //START SECTION MENUBAR ITEMS
  override calculateMenubarItems() {
    logLog("CALCULATE MENUBAR ITEMS");

    this.languages = Constants.BASIC_LANGUAGES_MENU;

    this.setSelectedLanguage();

    const langs: any = this.getLangMenu();

    const tenants: any = this.getTenantMenu();

    const user: any = this.getUserMenu();

    const security: any = this.getSecurityMenu();

    this.setUserMenuChilds(user, security);

    this.setBasicMenuBarItemsByTenant(tenants, langs, user);

    const menuItemsFromService: any[] = [
      ...this.menuItems.filter((x) => x.isNavIcon || x.parentId),
    ];

    menuItemsFromService.forEach((menu) => {
      this.processMenuItem(menu);
    });
    this.menubarItems = [...this.menubarItems, ...menuItemsFromService];
  }

  private processMenuItem(menu: any) {
    if (menu.componentName == "GlobalFiltersComponent") {
      menu.command = (event: any) => {
        this.showGlobalFilter = !this.showGlobalFilter;
      };
    } else if (menu.uiUrl) {
      menu.command = (event: any) => {
        this.router.navigateByUrl(menu.uiUrl);
      };
    }
    if (menu.children?.length) {
      menu.children.forEach((c: any) => {
        if (c.showName) {
          c.label = c.name;
        }
        if (c.uiUrl) {
          c.command = (event: any) => {
            this.router.navigateByUrl(c.uiUrl);
          };
        }
      });
    }
    menu.items = menu.children;
    if (menu.parentId) {
      menu.position = "left";
    }
  }

  private setBasicMenuBarItemsByTenant(tenants: any, langs: any, user: any) {
    if (tenants && tenants.items && tenants.items.length > 1) {
      this.menubarItems = [tenants, langs, user];
    } else {
      this.menubarItems = [langs, user];
    }
  }

  private setUserMenuChilds(user: any, security: any) {
    if (
      this.accountService.currentUser?.isAdmin ||
      this.accountService.currentUser?.isGlobalAdmin
    ) {
      user.items = [security, ...user.items];
    }
  }

  private getSecurityMenu(): any {
    return {
      icon: "pi pi-shield",
      label: $localize`:@@common.security:Seguridad`,
      items: [
        {
          label: $localize`:@@common.roles:Roles`,
          icon: "pi pi-list",
          command: (event: any) => {
            this.router.navigateByUrl("core/security/roles");
          },
        },
        {
          label: $localize`:@@common.users:Usuarios`,
          icon: "pi pi-users",
          command: (event: any) => {
            this.router.navigateByUrl("core/security/users");
          },
        },
      ],
    };
  }

  private getUserMenu(): any {
    return {
      icon: "pi pi-user",

      items: [
        {
          label: $localize`:@@common.profile:Perfil`,
          icon: "pi pi-id-card",
          command: (event: any) => {
            this.router.navigateByUrl("core/settings/profile");
          },
        },
        {
          label: $localize`:@@common.closesession:Cerrar Sesión`,
          icon: "pi pi-sign-out",
          command: (event: any) => {
            this.router.navigateByUrl("core/settings/profileLogOut");
          },
        },
      ],
    };
  }

  private getTenantMenu(): any {
    return {
      type: "dropdown",
      label: $localize`:@@common.tenant:Tenant`,
      selectedId: this.tsmService.currentTenant.id,
      command: (key: any) => {
        const newTenant = this.tsmService.availableTenants.find(
          (t) => t.id === key
        );
        if (newTenant) {
          this.tsmService.changeTenant(newTenant);
        }
      },
      items: this.availableTenants?.map((l) => {
        return { id: l.id, label: l.displayName };
      }),
    };
  }

  private getLangMenu(): any {
    return {
      type: "dropdown",
      label: $localize`:@@common.language:Lenguaje`,
      selectedId: this.selectedLanguage?.key,
      command: (key: any) => {
        if (!this.selectedLanguage || key != this.selectedLanguage.key) {
          this.selectedLanguage = this.languages.find((l) => l.key === key);
          if (this.selectedLanguage) {
            localStorage.setItem("locale", this.selectedLanguage.key);
            location.reload();
          }
        }
      },
      items: this.languages.map((l) => {
        return { id: l.key, label: l.translateKey };
      }),
    };
  }

  private setSelectedLanguage() {
    const currentLang = localStorage.getItem("locale");

    const f = this.languages.find((l) => l.key === currentLang);

    if (f) {
      this.selectedLanguage = { ...f };
    }
  }

  //END SECTION MENUBAR ITEMS
}
