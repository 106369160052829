import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";

import { OveraBaseModule, SidebarModule } from "@overa/base";

import { OveraButtonModule } from "@overa/components/button";
import { OveraButtonTieredMenuModule } from "@overa/components/button-tiered-menu";
import { OveraCheckboxModule } from "@overa/components/checkbox";
import { OveraEchartModule } from "@overa/components/echart";
//import { OveraEchartModule } from "../shared/echart/overa-echart.module";
import { OveraFilterTextModule } from "@overa/components/filter-text";
import { OveraMultiSelectModule } from "@overa/components/multiselect";
import { OveraNavbarModule } from "@overa/components/navbar";
import { OveraTextAreaModule } from "@overa/components/text-area";
import { OveraToggleButtonModule } from "@overa/components/toggle-button";
import { OveraTreeTableModule } from "@overa/components/treetable";
import { OveraInputModule } from "@overa/components/input";

import { OveraDataTableModule } from "@overa/components/datatable";
import { OveraDatepickerModule } from "@overa/components/datepicker";
import { OveraDropdownModule } from "@overa/components/dropdown";
import { SecurityModule } from "@overa/security";
import { DynamicPipeModule, OveraUtils } from "@overa/shared";
import { OveraChipsModule } from "@overa/components/chips";
//import { OveraChipsObjectModule } from "@overa/components/chips-object";

@NgModule({
  imports: [
    OveraBaseModule,
    SecurityModule,
    OveraNavbarModule,
    SidebarModule,
    OveraCheckboxModule,
    OveraButtonModule,
    OveraEchartModule,
    OveraToggleButtonModule,
    OveraButtonTieredMenuModule,
    DynamicPipeModule,
    OveraFilterTextModule,
    OveraTextAreaModule,
    OveraTreeTableModule,
    OveraMultiSelectModule,
    OveraDropdownModule,
    OveraDatepickerModule,
    OveraDataTableModule,
    OveraInputModule,
    OveraChipsModule,
    //OveraChipsObjectModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [OveraUtils],
  exports: [
    OveraBaseModule,
    SecurityModule,
    OveraNavbarModule,
    SidebarModule,
    OveraCheckboxModule,
    OveraButtonModule,
    OveraEchartModule,
    OveraToggleButtonModule,
    OveraButtonTieredMenuModule,
    DynamicPipeModule,
    OveraFilterTextModule,
    OveraTextAreaModule,
    OveraTreeTableModule,
    OveraMultiSelectModule,
    OveraDropdownModule,
    OveraDatepickerModule,
    OveraDataTableModule,
    OveraInputModule,
    OveraChipsModule,
    //OveraChipsObjectModule,
  ],
})
export class OveraModule {}
