import { Injectable } from "@angular/core";
import { FilterInstance, Filters } from "../models/filters.model";
import { Subject } from "rxjs";
import { IGeneralData } from "../models/interfaces/iGeneralData.model";
import { BaseService } from "@overa/shared";

@Injectable({ providedIn: "root" })
export class RefreshService extends BaseService<any> {
  override get apiBaseUrl(): string {
    throw new Error("Method not implemented.");
  }
  override get path(): string {
    throw new Error("Method not implemented.");
  }

  private refreshSubject = new Subject<Filters>();
  refreshSource$ = this.refreshSubject.asObservable();
  refresh(action: Filters) {
    this.refreshSubject.next(action);
  }

  private refreshSubject3 = new Subject<FilterInstance[]>();
  refreshSource$3 = this.refreshSubject3.asObservable();
  refresh3(action: FilterInstance[]) {
    this.refreshSubject3.next(action);
  }

  private refreshGlobalFilterSubject = new Subject<
    [{ [key: string]: IGeneralData[] | IGeneralData | string | Date }]
  >();
  refreshGlobalFilter$ = this.refreshGlobalFilterSubject.asObservable();
  refreshGlobalFilter(
    generalData: {
      [key: string]: IGeneralData[] | IGeneralData | string | Date;
    } = {}
  ) {
    this.refreshGlobalFilterSubject.next([generalData]);
  }

  private refreshVisibleFiltersSubject = new Subject<{ viewFilters: any, add: boolean }>();
  refreshVisibleFilters$ = this.refreshVisibleFiltersSubject.asObservable();
  refreshVisibleFilters(viewFilters: any, add: boolean) {
    this.refreshVisibleFiltersSubject.next({viewFilters, add});
  }
}
