<div *ngIf="version" class="version-layer">**&nbsp;{{ version }}&nbsp;**</div>
<div class="container-master">
  <div *ngIf="!kioskMode" class="sidebar" data-color="white" data-background-color="black">
    <app-sidebar-cmp #sidebar [menuItems]="menuItems"></app-sidebar-cmp>
  </div>

  <div *ngIf="!kioskMode" class="main-navbar">
    <section *ngIf="!kioskMode" class="top-nav-responsive">
      <!-- <overa-checkbox id="menu_responsive-toggle" /> -->
      <label class="menu_responsive-button-container" for="menu_responsive-toggle">
        <div class="menu_responsive-button"></div>
      </label>
      <ul class="menu_responsive"></ul>
    </section>

    <overa-navbar #navbar [menuItems]="menuItems" [oldVersion]="false" [menubarItems]="menubarItems">
    </overa-navbar>
  </div>

  <!-- <div class="container-saveAndReloadLayoutSection">
    <app-controls #controls></app-controls>
  </div> -->

  <div [hidden]="!showGlobalFilter" class="container-globalFilters">
    <app-global-filters></app-global-filters>
  </div>

  <div class="container-level-2">
    <div class="root-layout">
      <ngx-spinner bdColor="rgba(0, 0, 0, 0.4)" size="default" color="#fff" type="ball-newton-cradle">
        <p class="spinner-text-color" i18n="@@messages.loading">Cargando</p>
      </ngx-spinner>

      <div id="routerOuletContainer" *ngIf="!viewGolden" #routerOuletContainer>
        <section class="lm_header">
          <section class="lm_controls">
            <div class="lm_close" title="close" (click)="closeRouterOutlet()"></div>
          </section>
        </section>

        <router-outlet></router-outlet>
      </div>

      <app-golden-layout-host #goldenLayoutHost [routerOuletContainer]="routerOuletContainer"
        [homeContainer]="homeContainer" [goldenLayoutHostContainer]="goldenLayoutHostContainer" [sidebar]="sidebar"
        [navbar]="navbar">
      </app-golden-layout-host>

      <div id="homeContainer" #homeContainer>
        <app-home></app-home>
      </div>
    </div>
  </div>
</div>