export enum GroupType {
  DEPARTMENT = "department",
  FUNCTION = "function",
  SOCIETY = "society",
  EMPLOYEE = "employee",
  TAG = "tag",
  USER = "user",
  HOST = "host",
  SOFTWARE_PRODUCT = "software_product",
  STARTDATE = "startdate",
  DAYTYPE = "daytype",
}
