<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" acceptLabel="Si"
  rejectLabel="No"></p-confirmDialog>
<p-toast></p-toast>

<div class="col filters-buttons-right" >

  <div class="o-dropdown-container">
    <overa-dropdown [tooltip]="'FILTERS.FILTERTYPE.TOOLTIP' | translate" [tooltipPosition]="'bottom'"
          [tooltipStyleClass]="'tooltip'" [tooltipDisabled]="tooltipIsHidden" (onHide)="showTooltip($event)"
          [virtualScroll]="true" [itemSize]="30" (valueChange)="filterTypeChange($event)"
          [options]="filtersOptions['filterType']" (onShow)="initTypeFilterDropdownFilter(); hideTooltip()"
          [(value)]="this.selectedFilters['filterType']" [optionLabel]="'name'"
          [placeholder]="'FILTERS.FILTERTYPE.PLACEHOLDER' | translate" [itemTemplate]="customItemTemplate"
          class="filterType slim-select filter-combo-with-search" [ngClass]="{'vcenter': !isGlobalFilterView}">
          <ng-template #customItemTemplate let-item>
            <div [pTooltip]="item.name">
              <li>
                <span>{{ item.name }}</span>
              </li>
            </div>
          </ng-template>
    </overa-dropdown>
  </div>

  <div class="d-flex">
    <span *ngIf="isGlobalFilterView" class="aux-button showFitersIcons">
      <overa-button-tiered-menu [iconClass]="'pi pi-cog'" [tooltip]="'FILTERS.CONFIGURATION.TOOLTIP' | translate"
        [tooltipPosition]="'bottom'" [configuration]="moreConfigs" [isPopup]="true"></overa-button-tiered-menu>
    </span>
  </div>

  <div class="d-flex">
    <span *ngIf="!isGlobalFilterView" class="aux-button showFitersIcons">
      <overa-button *ngIf="!isGlobalFilterView" [icon]="'pi pi-filter'"
        (onClick)="showFilters = !showFilters"></overa-button>
      <overa-button-tiered-menu [iconClass]="'pi pi-cog'" [configuration]="moreConfigs"
        [isPopup]="true"></overa-button-tiered-menu>
    </span>
  </div>

</div>


<div class="col-12">
  <ng-container *ngIf="filterTypeViewFilters && (showFilters || isGlobalFilterView)">
    <ng-container *ngFor="let filter of filterTypeViewFilters; let i = index">
      <div *ngIf="
          filter.visible &&
          filter.name !== 'filterType' &&
          filter.name !== 'visibleFilters'
        " class="d-inline-flex ml-10 w100">
        <ng-container *ngIf="filter.type === 'calendar'">
          <overa-button *ngIf="filter.previousBtn" [cssClass]="'filter-button-calendar left'"
            (onClick)="changeDays('previous')" (onDebounceClick)="changeDay('previous')"
            [icon]="'pi pi-angle-left'"></overa-button>
          <overa-datepicker [dateFormat]="'dd/mm/yy'" [(value)]="this.selectedFilters[filter.name]"
            (onSelect)="onDateSelect($event, filter.name)" (onClear)="onDateSelect('', filter.name)"
            [showButtonBar]="true" [cssClass]="'filter-inputs-calendar'" [placeHolder]="
              'FILTERS.' + filter.name.toUpperCase() + '.PLACEHOLDER' | translate
            " [showTime]="true" [hourFormat]="24" [hideOnDateTimeSelect]="true" [locale]="['es']">
          </overa-datepicker>
          <overa-button *ngIf="filter.nextBtn" [cssClass]="'filter-button-calendar right'" (onClick)="changeDays('next')"
            [icon]="'pi pi-angle-right'"></overa-button>
        </ng-container>
        <overa-input *ngIf="filter.type === 'input'" [cssClass]="'filter-input-header-table'" [placeholder]="
            'FILTERS.' + filter.name.toUpperCase() + '.PLACEHOLDER' | translate
          " [(value)]="this.selectedFilters[filter.name]" (valueChange)="this.userQuestionUpdate.next($event)" [tooltip]="
            'FILTERS.' + filter.name.toUpperCase() + '.TOOLTIP' | translate
          " [tooltipPosition]="'bottom'" [tooltipStyleClass]="'tooltip'"
          [tooltipDisabled]="tooltipIsHidden"></overa-input>
  
        <overa-dropdown *ngIf="filter.type === 'dropdown'" [options]="filtersOptions[filter.name]" [tooltip]="
            'FILTERS.' + filter.name.toUpperCase() + '.TOOLTIP' | translate
          " (onHide)="showTooltip()" [placeholder]="
            'FILTERS.' +
              filter.name.toUpperCase() +
              (filter.required ? '.REQUIRED_PLACEHOLDER' : '.PLACEHOLDER')
              | translate
          " [(value)]="this.selectedFilters[filter.name]"
          (onShow)="initDropdownFilterDynamic(filter); hideTooltip($event)" [optionLabel]="filter.optionLabel"
          [tooltipPosition]="'bottom'" [tooltipStyleClass]="'tooltip'" [tooltipDisabled]="tooltipIsHidden"
          [showClear]="filter.showClear" [virtualScroll]="true" [itemSize]="30" [filter]="filter.filter"
          class="slim-select filter-combo-with-search" [emptyFilterMessage]="'FILTERS.INFO.NO_RESULTS' | translate"
          (valueChange)="this.filterSelectionChange($event, filter.name)" [itemTemplate]="customFilterItemTemplate">
          <ng-template #customFilterItemTemplate let-item pTemplate="item">
            <div [pTooltip]="item[filter.optionLabel]">
              <li>
                <span>{{ item[filter.optionLabel] }}</span><span *ngIf="item.hasNoData" style="color: red"> [*]</span>
              </li>
            </div>
          </ng-template>
        </overa-dropdown>
  
        <overa-multiselect *ngIf="filter.type === 'multiSelect'" [tooltip]="
            'FILTERS.' + filter.name.toUpperCase() + '.TOOLTIP' | translate
          " [tooltipPosition]="'bottom'" [tooltipStyleClass]="'tooltip'" [tooltipDisabled]="tooltipIsHidden"
          [options]="filtersOptions[filter.name]" [placeholder]="
            'FILTERS.' +
              filter.name.toUpperCase() +
              (filter.required ? '.REQUIRED_PLACEHOLDER' : '.PLACEHOLDER')
              | translate
          " [selectedItemsLabel]="
            'FILTERS.' + filter.name.toUpperCase() + '.SELECTED_ITEMS_LABEL'
              | translate
          " [(value)]="this.selectedFilters[filter.name]" (onShow)="initDropdownFilterDynamic(filter); hideTooltip()"
          [optionLabel]="'filter.optionLabel'" (onHide)="showTooltip()" [virtualScroll]="true"
          [virtualScrollItemSize]="30" [maxSelectedLabels]="0" class="slim-select filter-combo-with-search-multiple"
          (valueChange)="this.filterSelectionChange($event, filter.name)"
          [itemTemplate]="customMultiselectFilterTemplate">
          <ng-template #customMultiselectFilterTemplate let-item>
            <div [pTooltip]="item[filter.optionLabel]">
              <li>
                <span>{{ item[filter.optionLabel] }}</span><span *ngIf="item.hasNoData" style="color: red"> [*]</span>
              </li>
            </div>
          </ng-template>
        </overa-multiselect>
      </div>
    </ng-container>
  
    <!-- <div class="col filters-buttons-right">
      <overa-toggle-button *ngIf="filterInit.exportMode" [onLabel]="'COMMONS.MODES.NORMAL' | translate"
        [offLabel]="'COMMONS.MODES.EXPORT' | translate" [cssClass]="'aux-button'" [onIconClass]="'pi pi-check'"
        [offIconClass]="'pi pi-times'" [(value)]="exportModeEnabled"
        (valueChange)="exportModeChanged()"></overa-toggle-button>
    </div> -->
  </ng-container>
</div>