import { Injectable } from "@angular/core";
import { BaseService, ConfigsService } from "@overa/shared";
import { HttpClient } from "@angular/common/http";
import { Filters } from "../models/filters.model";
import { Observable } from "rxjs/internal/Observable";
import { of } from "rxjs";
import {
  OveraEchartData,
  OveraEchartMultipleData,
} from "@overa/components/echart";
import { LastSignalReport } from "../models/devices/lastSignalReport.model";

@Injectable({
  providedIn: "root",
})
export class MeasurementDeviceService extends BaseService<OveraEchartData> {
  constructor(override http: HttpClient, override config: ConfigsService) {
    super(http, config);
  }

  override get apiBaseUrl(): string {
    return this.config.getModuleConfig("overa-security", "apiBaseUrl") + "/api";
  }
  override get path(): string {
    throw new Error("Method not implemented.");
  }

  getUserDeviceApps(): Observable<OveraEchartData> {
    const filters = new Filters();

    const dataToReturn: OveraEchartData = {
      series: [
        {
          data: [3700, 1951, 800],
          select: {
            disabled: false,
          },
          selectedMode: "multiple",
        },
      ],
      axisCategories: ["activityDuration", "afkDuration", "effectiveDuration"],
      info: {
        inDateTime: "2024-02-21T07:07:40Z",
        outDateTime: "2024-02-21T07:53:31Z",
      },
    };

    /* let dataToReturn: OveraEchartData = {
      series: [
        {
          name: "serie1",
          data: [
            3700,
            1951,
            800,
          ],
          select: {
            disabled: false,
          },
          selectedMode: "multiple",
        },
        {
          name: "serie2",
          data: [
            8000,
            4000,
            100,
          ]
        }
      ],
      axisCategories: [
        "activityDuration",
        "afkDuration",
        "effectiveDuration"
      ],
      info: {
        "inDateTime": "2024-02-21T07:07:40Z",
        "outDateTime": "2024-02-21T07:53:31Z",
      }
    }; */

    return of(dataToReturn);
  }

  getLastSignalReport(filters: Filters) {
    return this.http.post<LastSignalReport[]>(
      `${this.apiBaseUrl}/MeasurementDevices/lastSignalReport`,
      filters
    );
  }

  getProcessConsumptionByHost(filters: Filters): Observable<OveraEchartData[]> {
    //TODO: Remove mock filters
    filters = new Filters();
    filters["startDate"] = new Date(2024, 6, 14);
    return this.http.post<OveraEchartData[]>(
      `${this.apiBaseUrl}/MeasurementDevices/processConsumptionByHost`,
      filters
    );

    /* let dataFromApi: OveraEchartData[] = [
      {
        axisCategories: [
          "07:00:00\n14/06/2024",
          "07:01:00\n14/06/2024",
          "07:02:00\n14/06/2024",
          "07:03:00\n14/06/2024",
          "07:04:00\n14/06/2024",
          "07:05:00\n14/06/2024",
          "07:06:00\n14/06/2024",
          "07:07:00\n14/06/2024",
          "07:08:00\n14/06/2024",
          "07:09:00\n14/06/2024",
          "07:10:00\n14/06/2024",
          "07:11:00\n14/06/2024",
          "07:12:00\n14/06/2024",
          "07:13:00\n14/06/2024",
          "07:14:00\n14/06/2024",
          "07:15:00\n14/06/2024",
        ],
        series: [
          {
            name: "servicio 1",
            data: [10, 2, 50.25, 0, 5, 12, 3, 0.5, 5, 25, 10, 50, 75, 0, 5],
          },
          {
            name: "servicio 2",
            data: [50, 0, 0, 0, 0.2, 20, 20, 20, 0, 50, 10, 2, 75, 0, 0],
          },
          {
            name: "servicio 3",
            data: [0, 20, 50, 0.3, 100, 100, 0, 12, 75, 10, 45, 10, 0, 0, 15],
          },
        ],
      },
      {
        axisCategories: [
          "07:00:00\n14/06/2024",
          "07:01:00\n14/06/2024",
          "07:02:00\n14/06/2024",
          "07:03:00\n14/06/2024",
          "07:04:00\n14/06/2024",
          "07:05:00\n14/06/2024",
        ],
        series: [
          {
            name: "servicio 1",
            data: [10, 2, 50, 0, 5],
          },
          {
            name: "servicio 2",
            data: [50, 0, 0, 0, 0],
          },
          {
            name: "servicio 3",
            data: [0, 20, 50, 0, 100],
          },
        ],
      },
    ];

    return of(dataFromApi); */
  }
}
