import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { AccountService } from "@overa/security";

@Injectable({
  providedIn: "root",
})
export class ActivityDynamicMaintenancesGuard implements CanActivate {
  constructor(private router: Router, private accountService: AccountService) {}

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      console.log("XXXXXXXXXXXXXXXXX");
      return true;
    } catch (error) {
      return false;
    }
  }
}
