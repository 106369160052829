import { AuthGuard, DefaultRedirectGuard, MenuDefinition } from "@overa/base";

import { LayoutComponent } from "./components/layout.component";

import { TextComponent } from "./components/goldenLayout/exampleComponents/text.component";

import { UserSummaryComponent } from "./components/userActivity/userSummary/userSummary.component";
import { UserSummaryUrlComponent } from "./components/userActivity/userSummaryUrl/userSummaryUrl.component";
import { TelecommutingIndicatorsComponent } from "./components/indicators/telecommuting-indicators/telecommuting-indicators.component";
import { UserActivitySummary2WeeksComponent } from "./components/userActivity/user-activity-summary2-weeks/user-activity-summary2-weeks.component";
import { PercentageUsersTelemetryComponent } from "./components/audit/percentage-users-telemetry/percentageUsersTelemetry.component";

import { UserStatusSummaryOneLineComponent } from "./components/userActivity/userStatusSummaryOneLine/userStatusSummaryOneLine.component";
import { UserActivitySummaryGroupedComponent } from "./components/userActivity/user-activity-summary-grouped/user-activity-summary-grouped.component";
import { DetailedViewComponent } from "./components/userActivity/detailed-view/detailed-view.component";
import { UserActivitySummaryComponent } from "./components/userActivity/user-activity-summary/user-activity-summary.component";
import { DeviceProcessConsumptionComponent } from "./components/deviceActivity/device-process-consumption/device-process-consumption.component";

// import { AuthGuard } from "./helpers/auth-guard";
import { HomeComponent } from "./components/home/home.component";
import { Injector, Type, inject } from "@angular/core";
import { GoldenLayoutService } from "./services/golden-layout.service";
import { DigitalDisconnectComponent } from "./components/indicators/digitalDisconnect/digitalDisconnect.component";
import { FormioAlerts } from "@formio/angular";
import { DeviceSignalReportComponent } from "./components/signals/device-signal-report/device-signal-report.component";
import { ProductiveUsageComponent } from "./components/indicators/productive-usage/productive-usage.component";
import { EntryExitComponent } from "./components/indicators/entry-exit/entry-exit.component";
import { ProductiveWeekComponent } from "./components/indicators/productive-week/productive-week.component";
import { ProductiveVsAfkComponent } from "./components/indicators/productive-vs-afk/productive-vs-afk.component";
import { AppUrlScheduleComponent } from "./components/userActivity/app-url-schedule/app-url-schedule.component";
import { ProcessConsumptionComponent } from "./components/deviceActivity/process-consumption/process-consumption.component";
import { TerminalComponent } from "./components/deviceActivity/terminal/terminal.component";
import { DeviceCommandsComponent } from "./components/deviceActivity/device-commands/device-commands.component";

export class MenuSettings {
  static glService: GoldenLayoutService;
  constructor() {
    MenuSettings.glService = inject(GoldenLayoutService);
  }

  public static MENU: MenuDefinition = {
    routes: [
      {
        path: "",
        pathMatch: "prefix",
        component: LayoutComponent,
        canActivate: [DefaultRedirectGuard],
        canActivateChild: [AuthGuard],

        children: [
          {
            path: "view",
            component: DetailedViewComponent,
            pathMatch: "full",
          },
          {
            path: "test",
            component: TextComponent,
            pathMatch: "full",
          },
          {
            path: "deviceActivity",
            children: [
              {
                path: "deviceProcessConsumption",
                component: DeviceProcessConsumptionComponent,
              },
            ],
          },
          {
            path: "hrPointers/digitalDisconnect",
            component: DigitalDisconnectComponent,
            pathMatch: "full",
          },

          {
            path: "userActivity",
            children: [
              {
                path: "",
                component: TextComponent,
              },
              {
                path: "telecommutingIndicators",
                component: TelecommutingIndicatorsComponent,
              },
              {
                path: "userActivitySummary",
                component: UserActivitySummaryComponent,
              },
              {
                path: "userStatusSummaryOneLine",
                component: UserStatusSummaryOneLineComponent,
              },
              {
                path: "userSummary",
                component: UserSummaryComponent,
              },
              {
                path: "userActivitySummaryUrl",
                component: UserSummaryUrlComponent,
              },
              {
                path: "userActivitySummaryGrouped",
                component: UserActivitySummaryGroupedComponent,
              },
              {
                path: "userActivity2WeeksComponent",
                component: UserActivitySummary2WeeksComponent,
              },
              {
                path: "percentageUsersTelemetryComponent",
                component: PercentageUsersTelemetryComponent,
              },
              {
                path: "appUrlShedule",
                component: AppUrlScheduleComponent,
              },
            ],
          },
          {
            path: "deviceActivity",
            children: [
              {
                path: "deviceProcessConsumption",
                component: DeviceProcessConsumptionComponent,
              },
              {
                path: "processConsumption",
                component: ProcessConsumptionComponent,
              },
              {
                path: "terminal",
                component: TerminalComponent,
              },
              {
                path: "deviceCommands",
                component: DeviceCommandsComponent,
              },
            ],
          },
          {
            path: "hrPointers",
            children: [
              {
                path: "digitalDisconnect",
                component: DigitalDisconnectComponent,
              },
              {
                path: "productiveUsage",
                component: ProductiveUsageComponent,
              },
              {
                path: "inputOutputRanges",
                component: EntryExitComponent,
              },
              {
                path: "productiveWeek",
                component: ProductiveWeekComponent,
              },
              {
                path: "productiveVsAfk",
                component: ProductiveVsAfkComponent,
              },
            ],
          },
          {
            path: "signalAnalisys",
            children: [
              {
                path: "deviceSignalReport",
                component: DeviceSignalReportComponent,
              },
            ],
          },
          {
            path: "dynamic",
            loadChildren: () =>
              import(
                /* webpackChunkName: "activity-dynamic.module" */ "./modules/activity-dynamic.module"
              ).then((m) => {
                const routes = m.OVERA_DYNAMIC_ROUTES;

                this.glService.registerComponentTypes("dynamic", routes);
                return m.ActivityDynamicModule;
              }),
          },
          {
            path: "dynamicMaintenances",
            loadChildren: () =>
              import(
                /* webpackChunkName: "dynamicMaintenances" */ "@overa/dynamic/components"
              ).then((m) => {
                const routes = m.DYNAMIC_MAINTENANCE_ROUTES;

                this.glService.registerComponentTypes(
                  "dynamicMaintenances",
                  routes
                );

                return m.OveraDynamicMaintenancesRoutingModule;
              }),
          },
          {
            path: "m",
            loadChildren: () =>
              import(
                /* webpackChunkName: "activity-dynamic-form.module" */ "./modules/activity-dynamic-form.module"
              ).then((m) => {
                const routesDynamicForm = m.OVERA_DYNAMIC_FORM_ROUTES;
                this.glService.registerComponentTypes("m", routesDynamicForm);
                return m.ActivityDynamicFormModule;
              }),
          },

          {
            path: "core",
            loadChildren: () =>
              import("@overa/base").then((m) => {
                const routes = m.NOTIFICATION_ROUTES.concat(
                  m.SECURITY_ROUTES
                ).concat(m.BASE_ROUTES);

                this.glService.registerComponentTypes("core", routes);
                return m.LibRoutingModule;
              }),
          },
        ],
      },
    ],
  };
}
