import { ChangeDetectorRef, Component } from "@angular/core";
import { ActivityBaseComponent } from "../../shared/activity-base.component";
import { Filters } from "src/app/models/filters.model";
import {
  ChartFormat,
  OveraEchartLineOptions,
  OveraEchartMultipleData,
  OveraEchartPieOptions,
} from "@overa/components/echart";

import { MeasurementService } from "src/app/services/measurement.service";
import { clone } from "lodash";

@Component({
  selector: "app-digital-disconnect",
  templateUrl: "./digitalDisconnect.component.html",
  styleUrls: ["./digitalDisconnect.component.scss"],
})
export class DigitalDisconnectComponent extends ActivityBaseComponent {
  lineChartOptions: OveraEchartLineOptions = new OveraEchartLineOptions();
  pieChartOptions: OveraEchartPieOptions = new OveraEchartPieOptions();

  // All charts info
  chartsData: OveraEchartMultipleData = {
    LINE_AFTER19: {
      id: "after19",
    },
    PIE_TOTAL: {
      id: "total",
    },
    PIE_WEEKEND: {
      id: "weekend",
    },
  };

  // Other info
  averageHoursAfter19: number = 0;
  averageHoursWeekend: number = 0;

  showCharts: boolean = false;

  formatTime = this.formatShortTime;

  constructor(private measurementService: MeasurementService) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    //configure line chart
    this.lineChartOptions.setZoomInside(true);

    if (this.lineChartOptions.grid) {
      this.lineChartOptions.grid.left = "1%";
    }

    //configure pie chart
    this.pieChartOptions.seriesOption.radius = [0, "70%"];
    this.pieChartOptions.seriesOption.label!.formatter = ChartFormat.Percent;
    //this.pieChartOptions.tooltip.formatter = ChartFormat.Percent;
    this.pieChartOptions.legend.show = false;
  }

  override loadData(filters: Filters): void {
    this.loading = true;

    this.measurementService
      .getActivityEmployeeByRange(filters)
      .subscribe((res) => {
        // set chart titles
        this.chartsData["PIE_TOTAL"].title = this.getChartTitle();
        this.chartsData["PIE_WEEKEND"].title = this.getChartTitle();

        // set series
        Object.values(this.chartsData).forEach((chartFront) => {
          const chart = res.find((chart) => chart.chartKey == chartFront.id);
          if (chart) {
            // Apply title to received data
            chart.chartName = chartFront.title ?? chart.chartName;
            // Apply data with new title included to chart
            chartFront.data = chart;

            //Apply extra info
            this.averageHoursAfter19 =
              chart.chartKey == "total"
                ? chart.info?.["averageExtraHours"]
                : this.averageHoursAfter19;
            this.averageHoursWeekend =
              chart.chartKey == "weekend"
                ? chart.info?.["averageExtraHours"]
                : this.averageHoursWeekend;
          }
        });

        this.showCharts = true;
        this.loading = false;
      });
  }

  //TODO: This is a mockup method
  getChartTitle(): string {
    let titleChart: string = "";
    if (this.selectedFilters["departments"]?.length) {
      titleChart += " (" + this.selectedFilters["departments"] + ")";
    } else {
      titleChart += " (Global)";
    }
    return titleChart;
  }
}
