import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivityBaseTableComponent } from "../../shared/activity-base-table-component.component";
import { OveraDataTableComponent } from "@overa/components/datatable";
import { Filters } from "src/app/models/filters.model";
import { MeasurementService } from "src/app/services/measurement.service";
import { DurationPipe } from "../../../shared/pipes/duration.pipe";
import { mockUserActivitySummaryGroupedData } from "../user-activity-summary-grouped/model";
import { COLUMN_DATETIME_LONG_PIPE } from "src/app/shared/pipes/column_pipes/column-data-formatter.pipe";
import { DatePipe } from "@angular/common";
import { TimeFromSecondsPipe } from "@overa/shared";
import { ActivitySummary } from "src/app/models/userActivity/activitySummary.model";
import { OveraDataTableColumn } from "@overa/components/common";

@Component({
  selector: "app-user-activity-summary",
  templateUrl: "./user-activity-summary.component.html",
  styleUrls: ["./user-activity-summary.component.scss"],
  providers: [DatePipe, TimeFromSecondsPipe, DurationPipe],
})
export class UserActivitySummaryComponent
  extends ActivityBaseTableComponent<any>
  implements OnInit
{
  secondaryColumns: OveraDataTableColumn[] = [];
  @ViewChild("datatable", { static: true })
  treeTable?: OveraDataTableComponent<any>;
  data: ActivitySummary[] = [];
  itemsTemp: ActivitySummary[] = [];
  totalRecords: number = 20;

  constructor(private measurementService: MeasurementService) {
    super();
  }

  loadData(filters: Filters): void {
    // TODO: filters doesnt load measureType "SUM" by default
    filters["measureType"] = ["sum"];

    this.subscriptions.push(
      this.measurementService.getActivitySummary(filters).subscribe(
        (res) => {
          this.data = res;
          this.itemsTemp = this.data;
          this.totalRecords = res.length;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      )
    );
  }

  override ngOnInit(): void {
    this.createColumns();
  }

  createColumns() {
    let durationColumns = [
      {
        field: "firstStart",
        header: "Primer Inicio",
        type: "DateTime",
        pipes: COLUMN_DATETIME_LONG_PIPE,
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "lastEnd",
        header: "Último Fin",
        type: "DateTime",
        pipes: COLUMN_DATETIME_LONG_PIPE,
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "effectiveDuration",
        header: "Efectivo",
        type: "Integer",
        pipes: [{ name: DurationPipe, args: [] }],
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "productiveDuration",
        header: "Productivo",
        type: "Integer",
        pipes: [{ name: DurationPipe, args: [] }],
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "afkDuration",
        header: "AFK",
        type: "Integer",
        pipes: [{ name: DurationPipe, args: [] }],
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "appUsageDuration",
        header: "Uso de la App",
        type: "Integer",
        pipes: [{ name: DurationPipe, args: [] }],
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "inactivityDuration",
        header: "Inactivo",
        type: "Integer",
        pipes: [{ name: DurationPipe, args: [] }],
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "blockedDuration",
        header: "Bloqueado",
        type: "Integer",
        pipes: [{ name: DurationPipe, args: [] }],
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "notRegisteredDuration",
        header: "No Registrado",
        type: "Integer",
        pipes: [{ name: DurationPipe, args: [] }],
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "vConfDuration",
        header: "Videoconferencias",
        type: "Integer",
        pipes: [{ name: DurationPipe, args: [] }],
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "vConfNumber",
        header: "Nº Videoconferencias",
        type: "Integer",
        responsive: true,
        responsiveHeader: true,
      },
    ];

    this.columns = [
      {
        field: "groupKey",
        header: "Departamento",
        type: "String",
        responsive: true,
        responsiveHeader: true,
      },
      ...durationColumns,
    ];

    this.secondaryColumns = [...durationColumns];
  }

  onPageChange(event: any) {}

  loadDataFunction = (
    pag: number,
    items_per_pag: number,
    order: { field: string; order: number }[]
  ): any[] => {
    if (pag != undefined && pag != null) {
      pag = pag * items_per_pag;

      const startIndex = pag;
      const endIndex = Math.min(pag + items_per_pag, this.itemsTemp.length);
      const loadItems = this.itemsTemp.slice(startIndex, endIndex);
      this.data = loadItems;
      if (this.dataTable) this.dataTable.items = loadItems;
      return loadItems;
    } else {
      return [];
    }
  };
}
