<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <mat-card-content>
                            <div class="container-fluid main-container">

                                <div class="container-individualFilters">
                                    <app-filters #filterCmp
                                        [filterInit]="filterInit"
                                        (loading)="setLoading($event)"
                                        (loadData)="loadData($event)"
                                        (filterChange)="changeFilter($event)"
                                        (exportOnClick)="csvExport($event)"
                                        (validAllRequiredFilters)="validAllRequiredFilters($event)"
                                        (viewModeChange)="viewModeChange($event)">
                                    </app-filters>
                                </div>

                                <p-toggleButton onLabel="{{'VIEWS.TIMELINE_VIEW.TITLE' | translate}}" offLabel="{{'VIEWS.TIMELINE_VIEW.TITLE' | translate}}" class="aux-button"
                                onIcon="pi pi-check" offIcon="pi pi-times" [(ngModel)]="timeLineEnabled" (click)="filterCmp.reloadData()"  [disabled]="loading"
                                >
                                </p-toggleButton>

                                <div class="row">
                                    <div class="col-12 loading-wrapper">

                                        <div *ngIf="loading" class="p-component-overlay loading-overlay">
                                            <em class="pi pi-spin pi-spinner p-datatable-loading-icon" style="font-size: 2rem;">
                                            </em>
                                        </div>

                                        <overa-echart #chart [options]="chartOptions" [data]="chartData" [size]="chartHeight"></overa-echart>

                                    </div>
                                </div>
                                
                            </div>
                        </mat-card-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
