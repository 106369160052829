import { Component, OnInit } from "@angular/core";
import { ActivityBaseTableComponent } from "../../shared/activity-base-table-component.component";
import { DatePipe } from "@angular/common";
import { DurationPipe } from "src/app/shared/pipes/duration.pipe";
import { Filters } from "src/app/models/filters.model";
import { OveraDialogService } from "@overa/components/dialog";
import { COLUMN_DATETIME_LONG_PIPE } from "src/app/shared/pipes/column_pipes/column-data-formatter.pipe";
import { MeasurementDeviceService } from "src/app/services/measurementDevice.service";
import { LastSignalReport } from "src/app/models/devices/lastSignalReport.model";

@Component({
  selector: "app-device-signal-report",
  templateUrl: "./device-signal-report.component.html",
  styleUrls: ["./device-signal-report.component.scss"],
  providers: [DatePipe, DurationPipe],
})
export class DeviceSignalReportComponent
  extends ActivityBaseTableComponent<LastSignalReport>
  implements OnInit
{
  data: LastSignalReport[] = [];
  itemsTemp: LastSignalReport[] = [];
  totalRecords: number = 20;

  datatableLiterals: { [literal: string]: string } = {
    NoData: $localize`:@@common.nodata: No hay datos para mostrar`,
    Yes: $localize`:@@common.yes: Yes`,
    No: $localize`:@@common.no: No`,
    FilterBy: $localize`:@@datatable.filterby: Filtrar por`,
    checkboxOff: $localize`:@@common.no: No`,
    checkboxOn: $localize`:@@common.yes: Sí`,
  };

  constructor(
    private dialogService: OveraDialogService,
    private measurementDeviceService: MeasurementDeviceService
  ) {
    super();
    this.createColumns();
  }

  override ngOnInit() {
    let filters: Filters = {
      page: 1,
      itemsPerPage: 5,
      filterType: "global",
      startDate: new Date("2024-02-04T23:00:00.000Z"),
      endDate: new Date("2024-02-11T22:59:59.000Z"),
    };

    this.loadData(filters);
  }

  override loadData(filters: Filters): void {
    this.subscriptions.push(
      this.measurementDeviceService.getLastSignalReport(filters).subscribe(
        (res) => {
          this.data = res;
          (this.itemsTemp = res), (this.totalRecords = res.length);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      )
    );
  }

  loadDataFunction = (
    pag: number,
    items_per_pag: number,
    order: { field: string; order: number }[]
  ): any[] => {
    if (pag != undefined && pag != null) {
      pag = pag * items_per_pag;

      const startIndex = pag;
      const endIndex = Math.min(pag + items_per_pag, this.itemsTemp.length);
      const loadItems = this.itemsTemp.slice(startIndex, endIndex);
      this.data = loadItems;
      if (this.dataTable) this.dataTable.items = loadItems;
      return loadItems;
    } else {
      return [];
    }
  };

  // TODO: Aplicar traducciones en los headers en lugar de strings harcodeados
  createColumns() {
    this.columns = [
      {
        field: "hostName",
        header: "Dispositivo",
        type: "String",
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "appVersion",
        header: "Versión",
        type: "String",
        responsive: true,
        responsiveHeader: true,
      },

      {
        field: "lastActivity",
        header: "Última actividad",
        type: "DateTime",
        pipes: COLUMN_DATETIME_LONG_PIPE,
        responsive: true,
        responsiveHeader: true,
      },
    ];
  }
}
