<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" acceptLabel="Si"
    rejectLabel="No"></p-confirmDialog>
<p-toast></p-toast>
<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <mat-card-content>
                            <div class="container-fluid main-container">
                                <div class="container-individualFilters">
                                    <app-filters #filterCmp [filterInit]=filterInit (loadData)=loadData($event)
                                        (filterChange)=changeFilter($event)
                                        (validAllRequiredFilters)=validAllRequiredFilters($event)>
                                    </app-filters>
                                </div>
                                <!-- <p-button type="button" (click)="openWorkOfflineFillDialog()" *ngIf="isAdmin" icon="pi pi-plus"
                                    label="{{'ACTION.ADD_OFFLINE' | translate}}">
                                </p-button> -->
                                <div class="row">
                                    <div class="col-12 loading-wrapper">
                                        <div *ngIf="loading" class="p-component-overlay loading-overlay">
                                            <em class="pi pi-spin pi-spinner p-datatable-loading-icon"></em>
                                        </div>

                                        <overa-echart [size]="height" [options]="chartOptions"
                                            [data]="chartData"></overa-echart>
                                        <div *ngIf="!showCharts" i18n="@@VIEWS.COMMONS.FILTERS.INFO.NO_RESULTS"></div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="device-analisys col-md-12">
                                <div class="shadow-table-content">

                                    <div class="row">
                                        <div class="col-md-12 data-titles" i18n="@@VIEWS.DEVICE_ANALYSIS_VIEW.OFFLINE_SUMMARY_TITLE"></div>
                                    </div>

                                    <mat-card-content>
                                        <div *ngIf="viewOffline" class="device-analisysAdd">
                                            <p-dropdown optionLabel="name" [style]="{minWidth: '200px'}"
                                                class="slim-select filter-combo-with-search"
                                                i18n-placeholder="@@VIEWS.USER_STATUS_SUMMARY_VIEW.OFFLINE.WORKTYPE"
                                                [(ngModel)]="workTypeOffline" [options]="workTypesOptions">
                                            </p-dropdown>
                                            <input class="filter-input-header-table" class="inputs-deviceanalisys" pInputText
                                                type="text"
                                                i18n-placeholder="@@VIEWS.USER_STATUS_SUMMARY_VIEW.OFFLINE.NOTES"
                                                [(ngModel)]="notesOffline">
                                            <p-calendar [firstDayOfWeek]="1" [(ngModel)]="startOffline" [timeOnly]="true" inputId="timeonly"
                                                class="filter-inputs-calendar-time"
                                                i18n-placeholder="@@VIEWS.DEVICE_ANALYSIS_VIEW.START_TIME">
                                            </p-calendar>
                                            <p-calendar [firstDayOfWeek]="1" [(ngModel)]="endOffline" [timeOnly]="true" inputId="timeonlys"
                                                class="filter-inputs-calendar-time"
                                                i18n-placeholder="@@VIEWS.DEVICE_ANALYSIS_VIEW.END_TIME">
                                            </p-calendar>
                                            <button pButton pRipple type="button" icon="pi pi-plus" class="button-round-new"
                                                (click)="addWorkOffline()"></button>
                                        </div>
                                        <p-table #workOffline [value]="principalEntity.workOffline"
                                            [loading]="loadingWorkOffline" [columns]="columnsDetailsWorkOffline"
                                            id="work-offline" class="small-td table-device-analisys data-table"  responsiveLayout="stack" [breakpoint]="'960px'">

                                            <ng-template pTemplate="header" let-columns>
                                                <tr id="header">
                                                    <ng-container *ngFor="let col of columns">
                                                        <th scope="col" class="{{col.field}} {{col.class}}"
                                                            *ngIf="col.selected !== false" pSortableColumn="{{col.field}}">
                                                            {{col.header}}
                                                            <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                                        </th>
                                                    </ng-container>
                                                </tr>
                                            </ng-template>

                                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                                <tr>
                                                    <ng-container *ngFor="let col of columns; let i = index">
                                                        <td *ngIf="col.selected !== false" class="{{col.field}} {{col.class}}">
                                                            <span
                                                                *ngIf="i == 0 && (rowData.pending || rowData.rejected || rowData.isApproved)"
                                                                [class]="rowData.cssClass" class="material-icons notranslate"
                                                                [title]="rowData.tooltip">
                                                                {{rowData.icon}}
                                                            </span>
                                                            <span class="p-column-title">  {{col.header}}</span>
                                                            {{ col.pipes ? (rowData[col.field]| dynamicPipe :
                                                            col.pipes) : rowData[col.field] }}
                                                            <a *ngIf='col.field=="notes" && rowData["isOverlapping"]'>
                                                                [{{'VIEWS.COMMONS.TABLES.COLUMNS.WORK_OFFLINE.DATE_OVERLAP_DETECTED'
                                                                | translate}}]
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <td class="table-column-button" *ngIf="viewOffline">
                                                        <p-button type="button" (click)="deleteWorkOffline(rowData)"
                                                            icon="pi pi-times">
                                                        </p-button>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="footer">
                                                <span *ngIf="principalEntity.workOffline && principalEntity.workOffline.length"
                                                    class="totals">{{
                                                    'VIEWS.COMMONS.TABLES.COLUMNS.TOTAL.HEADER' | translate }}:
                                                    {{ ((workOffline.filteredValue?
                                                    workOffline.filteredValue:
                                                    workOffline.value)|dynamicPipe:pipeSumAndFormatTimeDuration)}}
                                                    |
                                                </span>
                                                <span *ngIf="principalEntity.workOffline && principalEntity.workOffline.length"
                                                    class="totals">{{
                                                    'VIEWS.COMMONS.TABLES.COLUMNS.TOTAL_APPROVED.HEADER' |
                                                    translate }}:
                                                    {{ approvedWorkOffline|dynamicPipe:pipeSumAndFormatTimeDuration}}
                                                </span>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr>
                                                    <td colspan="10" i18n="@@VIEWS.COMMONS.TABLES.INFO.NO_RESULTS"></td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </mat-card-content>

                                </div>
                            </div> -->
                        </mat-card-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>