import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { BaseService, ConfigsService } from "@overa/shared";
import { Report } from "../models/report.model";

@Injectable({ providedIn: "root" })
export class ReportingService extends BaseService<Report> {
  override get apiBaseUrl(): string {
    return (
      this.config.getModuleConfig("overa-reporting", "reportingBaseUrl") +
      "/api"
    );
  }
  override get path(): string {
    return "Report";
  }
  constructor(http: HttpClient, config: ConfigsService) {
    super(http, config);
  }

  getReport(
    reportId: string,
    data: any,
    filename: string,
    topdf: boolean = false
  ): Observable<Blob> {
    const endpointUrl = `${this.apiBaseUrl}/${this.path}/run/${reportId}/${filename}/${topdf}`;
    return this.http.post<Blob>(endpointUrl, data, {
      responseType: "blob" as "json",
    });
  }
}
