import { ChangeDetectorRef, Component } from "@angular/core";
import { ActivityBaseComponent } from "../../shared/activity-base.component";
import { Filters } from "src/app/models/filters.model";
import { MeasurementUserService } from "src/app/services/measurementUser.service";
import * as moment from "moment";
import {
  OveraEchartUtils,
  ICustomSeriesInfo,
  transformDataIntoCustomData,
} from "@overa/components/echart";
import {
  OveraEchartData,
  OveraEchartTimeLineOptions,
} from "@overa/components/echart";

@Component({
  selector: "app-signal-analysis",
  templateUrl: "./signalAnalysis.component.html",
  styleUrls: ["./signalAnalysis.component.scss"],
})
export class SignalAnalysisComponent extends ActivityBaseComponent {
  chartOptions: OveraEchartTimeLineOptions = new OveraEchartTimeLineOptions(3);
  chartData?: OveraEchartData;
  height: number = 0;
  showCharts: boolean = false;

  typesInfo: ICustomSeriesInfo[] = [
    {
      key: "unproductive",
      isAfk: false,
      name: $localize`:@@VIEWS.COMMONS.CHARTS.ECHARTS.LEGEND.UNPRODUCTIVE:`,
      color: "#ff111c",
      enabled: true,
      graphIndex: 0,
    },
    {
      key: "productive",
      isAfk: false,
      name: $localize`:@@VIEWS.COMMONS.CHARTS.ECHARTS.LEGEND.PRODUCTIVE:`,
      color: "#7d11ff",
      enabled: true,
      graphIndex: 0,
    },
    {
      key: "uknownUrl",
      isAfk: false,
      name: $localize`:@@UNKNOWN_SOURCES:`,
      color: "#000000",
      enabled: true,
      graphIndex: 0,
    },
    {
      key: "offline",
      isAfk: false,
      name: $localize`:@@VIEWS.COMMONS.CHARTS.ECHARTS.LEGEND.OFFLINE:`,
      color: "#FF8000",
      enabled: false,
      graphIndex: 0,
    },
    {
      key: "offlineNotApproved",
      isAfk: true,
      name: $localize`:@@VIEWS.COMMONS.CHARTS.ECHARTS.LEGEND.OFFLINE_REJECTED:`,
      color: "#970b0b",
      enabled: false,
      graphIndex: 0,
    },
    {
      key: "offlinePending",
      isAfk: true,
      name: $localize`:@@VIEWS.COMMONS.CHARTS.ECHARTS.LEGEND.OFFLINE_PENDING:`,
      color: "#ECDA21",
      enabled: false,
      graphIndex: 0,
    },
    {
      key: "afk",
      isAfk: true,
      name: "afk",
      color: "#ECDA21",
      enabled: true,
      graphIndex: 0,
    },
    {
      key: "vconf",
      isAfk: false,
      name: "vconf",
      color: "#08FF00",
      enabled: true,
      graphIndex: 0,
    },
    {
      key: "alive",
      isAfk: false,
      name: "alive",
      color: "#0017FF",
      enabled: true,
      graphIndex: 2,
    },
    {
      key: "current",
      isAfk: false,
      name: "current",
      color: "#000000",
      enabled: true,
      graphIndex: 1,
    },
    {
      key: "started",
      isAfk: false,
      name: "started",
      color: "#ECDA31",
      enabled: true,
      graphIndex: 3,
    },
    {
      key: "logon",
      isAfk: false,
      name: "logon",
      color: "#00FF0C",
      enabled: true,
      graphIndex: 3,
    },
    {
      key: "logoff",
      isAfk: false,
      name: "logoff",
      color: "#FF0000",
      enabled: true,
      graphIndex: 3,
    },
    {
      key: "unlock",
      isAfk: false,
      name: "unlock",
      color: "#00FF0C",
      enabled: true,
      graphIndex: 3,
    },
    {
      key: "lock",
      isAfk: false,
      name: "lock",
      color: "#FF0000",
      enabled: true,
      graphIndex: 3,
    },
  ];

  constructor(private measurementUserService: MeasurementUserService) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    //configure chart

    this.chartOptions.tooltip.formatter = (params: any) => {
      const texts = [
        params.value[5].charAt(0).toUpperCase() + params.value[5].slice(1),
        params.value[6],
        $localize`:@@VIEWS.COMMONS.CHARTS.ECHARTS.TOOLBOX.START:` +
          ": " +
          OveraEchartUtils.formatDate(new Date(params.value[3])),
        $localize`:@@VIEWS.COMMONS.CHARTS.ECHARTS.TOOLBOX.END:` +
          ": " +
          OveraEchartUtils.formatDate(new Date(params.value[4])),
      ];
      return texts.join("<br/>");
    };

    this.chartOptions.axisLabelFormatter = (value: any) => {
      return moment(value).format("HH:mm").toString().replace(" ", "\n");
    };
  }

  override ngAfterViewInit(): void {}

  override loadData(filters: Filters): void {
    this.loading = true;

    this.measurementUserService.getStatusOneLine(filters).subscribe((res) => {
      // Transform data from API for being used in customSeries
      const customOveraEchartData = transformDataIntoCustomData(
        res,
        this.typesInfo
      );

      // set legend
      this.setLegend(
        customOveraEchartData.series!.map((serie) => serie.name as string)
      );

      // set series
      this.chartData = customOveraEchartData;

      this.showCharts = true;
      this.loading = false;
    });
  }

  private setLegend(seriesNames: string[]) {
    const data: string[] = [];
    const selected: { [key: string]: boolean } = {};

    this.typesInfo.forEach((type) => {
      if (seriesNames.includes(type.name)) {
        data.push(type.name);
        selected[type.name] =
          this.chartOptions.legend.selected?.[type.name] ?? type.enabled;
      }
    });

    this.chartOptions.legend.data = data;
    this.chartOptions.legend.selected = selected;
  }
}
