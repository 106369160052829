import { NgModule } from "@angular/core";
import { TieredMenuModule } from "primeng/tieredmenu";
import { TooltipModule } from "primeng/tooltip";
import { ButtonModule } from "primeng/button";
import { MultiSelectModule } from "primeng/multiselect";
import { DropdownModule } from "primeng/dropdown";
import { ToggleButtonModule } from "primeng/togglebutton";

@NgModule({
  imports: [
    ToggleButtonModule,
    DropdownModule,
    MultiSelectModule,
    ButtonModule,
    TieredMenuModule,
    TooltipModule,
  ],
  providers: [],
  exports: [
    ToggleButtonModule,
    DropdownModule,
    MultiSelectModule,
    ButtonModule,
    TieredMenuModule,
    TooltipModule,
  ],
})
export class PrimeNgModule {}
