import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, map } from "rxjs";

import { BaseService, ConfigsService } from "@overa/shared";
import { ControlCommand } from "../models/controlCommand.model";
import { ControlCommandDelivery } from "../models/controlCommandDelivery.model";
import { Filters } from "../models/filters.model";

@Injectable({ providedIn: "root" })
export class ControlCommandService extends BaseService<ControlCommand> {
  private controlCommandRouteSubject: ReplaySubject<string> = new ReplaySubject(
    1
  ); // Uno es el numero de valores a reemitir
  public controlCommandRouteObservable: Observable<string> =
    this.controlCommandRouteSubject.asObservable();

  private controlCommandSubject: ReplaySubject<boolean> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public controlCommandObservable: Observable<boolean> =
    this.controlCommandSubject.asObservable();

  private endpointUrl = `${this.apiBaseUrl}${this.path}`;

  override get apiBaseUrl(): string {
    return this.config.getModuleConfig("overa-security", "apiBaseUrl") + "/api";
  }
  override get path(): string {
    return "/ControlCommands";
  }

  constructor(override http: HttpClient, config: ConfigsService) {
    super(http, config);
  }

  // TODO: Hay que pasarle el tenantUid del cliente que está realizando la consulta
  // TODO: Comentado con Rust => Ahora mismo la llamada al API externa devuelve todos los elementos. Es necesario poder aplicar filtrados (por fecha, host, etc)
  getControlCommands(filters: Filters): Observable<ControlCommand[]> {
    let tenantUid = "9hfAuTuh9U8GMKoVAzBGM"; //TODO: Test using DevTeam
    return this.http
      .get<ControlCommand[]>(
        `${this.endpointUrl}/GetControlCommands/${tenantUid}`
      )
      .pipe(
        map((data: ControlCommand[]) => {
          // Calcular el porcentaje de completado ("completed")
          return data.map((command) => {
            let totalTasks =
              (command.queuedCount ?? 0) +
              (command.completedCount ?? 0) +
              (command.failedCount ?? 0) +
              (command.abortedCount ?? 0);
            command.completedPercentage =
              totalTasks - (command.queuedCount ?? 0) + "/" + totalTasks;
            return command;
          });
        })
      );
  }

  // TODO: Este método necesita modificarse para que reciba el tenantUid y el controlCommandId para obtener las deliveries
  // Se usan esos datos para llamar al servicio externo.
  // TODO: (31/07/2024). Aparentemente no se están recuperando los controlCommand antiguos pero sí se pueden recuperar sus deliveries. Por ejemplo, al reuperar los CC no aparece el 102593 pero sí se pueden obtener sus deliveries.
  getControlCommandDeliveries(): Observable<ControlCommandDelivery[]> {
    let tenantUid = "9hfAuTuh9U8GMKoVAzBGM"; //TODO: Test using DevTeam
    let commandId = 102593; //TODO: Test using existing command for that tenant
    return this.http.get<ControlCommandDelivery[]>(
      `${this.endpointUrl}/GetControlCommandDeliveries/${tenantUid}/${commandId}`
    );
  }

  getDeliveriesFromControlCommands(
    ids: number[]
  ): Observable<ControlCommandDelivery[]> {
    return this.http.post<ControlCommandDelivery[]>(
      `${this.endpointUrl}/DeliveriesFromControlCommands`,
      ids
    );
  }

  addControlCommand(command: ControlCommand): Observable<ControlCommand> {
    return this.http.post<ControlCommand>(
      `${this.endpointUrl}/AddControlCommand`,
      command
    );
  }
}
