import { ChangeDetectorRef, Component } from "@angular/core";
import { MeasurementService } from "../../../services/measurement.service";
import { Filters } from "src/app/models/filters.model";
import { ActivityBaseComponent } from "../../shared/activity-base.component";
import { OveraEchartMultipleData } from "@overa/components/echart";
import { DynamicPipe, TimeFromSecondsPipe } from "@overa/shared";
import { OveraEchartPieOptions } from "@overa/components/echart";

@Component({
  selector: "app-telecommuting-indicators",
  templateUrl: "./telecommuting-indicators.component.html",
  providers: [DynamicPipe, TimeFromSecondsPipe],
})
export class TelecommutingIndicatorsComponent extends ActivityBaseComponent {
  chartOptions: OveraEchartPieOptions = new OveraEchartPieOptions();

  chartsData: OveraEchartMultipleData = {
    ACTIVE_CHART: {
      id: "activeDurationAverage",
      title: $localize`:@@VIEWS.TELECOMMUTING_INDICATORS.DURATION_TITLE:Promedio de tiempo activo`,
    },
    EFFECTIVE_CHART: {
      id: "effectiveDurationAverage",
      title: $localize`:@@VIEWS.TELECOMMUTING_INDICATORS.EFFECTIVE_DURATION_TITLE:Promedio de tiempo de uso de aplicaciones`,
    },
    PRODUCTIVE_CHART: {
      id: "productiveDurationAverage",
      title: $localize`:@@VIEWS.TELECOMMUTING_INDICATORS.PRODUCTIVE_DURATION_TITLE:Promedio de tiempo productivo`,
    },
    AFK_CHART: {
      id: "afkDurationAverage",
      title: $localize`:@@VIEWS.TELECOMMUTING_INDICATORS.AFK_DURATION_TITLE:Promedio de tiempo afk`,
    },
    VCONF_CHART: {
      id: "vConfDurationAverage",
      title: $localize`:@@VIEWS.TELECOMMUTING_INDICATORS.CALL_DURATION_TITLE:Promedio de tiempo en Videoconferencias`,
    },
  };

  timeFromSecondsPipe = new TimeFromSecondsPipe();
  showCharts: boolean = false;

  departmentNames: string[] = [];

  constructor(private measurementService: MeasurementService) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    // Set chart options
    this.chartOptions.tooltip.formatter = (a: any) =>
      a.name +
      ": " +
      this.timeFromSecondsPipe.transform(a.value, this.formatShortTime) +
      " (" +
      a.percent +
      "%)";
    this.chartOptions.seriesOption.label!.formatter = (value: any) =>
      value.data.name +
      ": " +
      this.timeFromSecondsPipe.transform(
        value.data.value,
        this.formatShortTime
      );
    this.chartOptions.seriesOption.selectedMode = "single";
  }

  loadData(filters: Filters): void {
    this.loading = true;

    this.measurementService
      .getMeasurementsGroupedTelecommuting(filters)
      .subscribe(
        (result) => {
          if (result) {
            Object.values(this.chartsData).forEach((chartFront) => {
              const chart = result.find(
                (chart) => chart.chartKey == chartFront.id
              );
              if (chart) {
                chart.chartName = chartFront.title ?? chart.chartName;
                chartFront.data = chart;
              }
            });

            this.showCharts = true;
          } else {
            this.showCharts = false;
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  // TODO: Redefine this method to get departments from filters selection
  private getDepartmentName() {
    const departmentName =
      this.departmentNames.length <= 1
        ? this.departmentNames
            .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
            .join(", ")
        : this.departmentNames.length
        ? $localize`:@@COMMONS.SEVERAL:`
        : "";

    return departmentName;
  }

  getChartTitle(path: string): string {
    let title = path;
    // let title = this.translate.getTranslations(path);

    if (this.selectedFilters["departments"].length) {
      const departmentName = this.getDepartmentName();
      title += " (" + departmentName + ")";
    } else {
      title += " (Global)";
    }
    return title;
  }
}
