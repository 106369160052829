<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <mat-card-content>
                            <div class="container-fluid main-container">

                                <div class="container-individualFilters">
                                    <app-filters #filterCmp
                                        [filterInit]="filterInit"
                                        (loading)="setLoading($event)"
                                        (loadData)="loadData($event)"
                                        (filterChange)="changeFilter($event)"
                                        (exportOnClick)="csvExport($event)"
                                        (validAllRequiredFilters)="validAllRequiredFilters($event)"
                                        (viewModeChange)="viewModeChange($event)">
                                    </app-filters>
                                </div>

                                <div class="row mt-5 mb-5">                                
                                    <div class="col-12">
                                        <div *ngIf="loading" class="p-component-overlay loading-overlay">
                                            <em class="pi pi-spin pi-spinner p-datatable-loading-icon"></em>
                                        </div>
                                        
                                        <h3 class="col-12 text-center mb-3" i18n="@@VIEWS.DIGITAL_DISCONNECT_VIEW.CHART_AFTER_19">% de usuarios que trabajan fuera de horario</h3>
                                
                                    </div>
                            
                                    <div class="col-xxl-7 col-xl-7 col-lg-7 col-12">
                                        <overa-echart [options]="lineChartOptions"
                                        [data]="chartsData['LINE_AFTER19'].data"></overa-echart>
                                    </div>

                                    <div class="col-xxl-5 col-xl-5 col-lg-5 col-12 mt-5">
                                        <overa-echart [size]="250" [options]="pieChartOptions"
                                        [data]="chartsData['PIE_TOTAL'].data"></overa-echart>

                                    </div>
                                
                                </div>    

                                <hr>


                                <div class="row mt-5 mb-5">
                                
                                    <div class="col-12 mb-4">
                                        <div *ngIf="loading" class="p-component-overlay loading-overlay">
                                            <em class="pi pi-spin pi-spinner p-datatable-loading-icon"></em>
                                        </div>
                                    
                                        <h3 class="col-12 text-center" i18n="@@VIEWS.DIGITAL_DISCONNECT_VIEW.CHART_WEEKEND">% de usuarios que trabajan los fines de semana</h3>
                                
                                    </div>
                            
                                    <div class="col-xxl-7 col-xl-7 col-lg-7 col-12">
                                        <overa-echart [size]="250" [options]="pieChartOptions"
                                            [data]="chartsData['PIE_WEEKEND'].data"></overa-echart>
                                    </div>

                                    <div class="col-xxl-5 col-xl-5 col-lg-5 col-12 mt-5">
                                        <div class="activities-container-shadow-black">
                                            <p class="time-titles text-center" i18n="@@VIEWS.DIGITAL_DISCONNECT_VIEW.AVERAGE_TIME_WEEKEND">Media de tiempo dedicado por los usuarios en fin de semana</p>
                                            <h4 class="time-data text-center"><strong>{{averageHoursWeekend > 0 ? (averageHoursWeekend |
                                                timeFromSeconds:formatTime) : '00:00:00'}}</strong>
                                        </h4>
                                        </div>

                                    </div>
                            
                                </div>  



                            
                                <!-- <div class="row">

                                    <h3 class="col-12 text-center" i18n="@@VIEWS.DIGITAL_DISCONNECT_VIEW.CHART_AFTER_19">% de usuarios que trabajan fuera de horario</h3>

                                    <div class="col-7 loading-wrapper">
                                        <div *ngIf="loading" class="p-component-overlay loading-overlay">
                                            <em class="pi pi-spin pi-spinner p-datatable-loading-icon"
                                                style="font-size: 2rem;"></em>
                                        </div>

                                        <overa-echart [options]="lineChartOptions"
                                            [data]="chartsData['LINE_AFTER19'].data"></overa-echart>

                                    </div>
                                    <div class="row col-5 align-items-center">

                                        <div class="col-12 loading-wrapper">
                                            <div *ngIf="loading" class="p-component-overlay loading-overlay">
                                                <i class="pi pi-spin pi-spinner p-datatable-loading-icon"
                                                    style="font-size: 2rem;"></i>
                                            </div>
                                            <overa-echart [size]="250" [options]="pieChartOptions"
                                                [data]="chartsData['PIE_TOTAL'].data"></overa-echart>
                                        </div>
                                        <div class="col-12 centered">
                                            <div class="activities-container-shadow-black">
                                                <span class="time-titles" i18n="@@VIEWS.DIGITAL_DISCONNECT_VIEW.AVERAGE_TIME_AFTER_19">Media de tiempo dedicado por los usuarios fuera de horario</span>
                                                <span class="time-data">{{averageHoursAfter19 > 0 ? (averageHoursAfter19 |
                                                    timeFromSeconds:formatTime) : '00:00:00'}}</span>
                                            </div>
                                        </div>
                                    </div>


                                </div> -->

                                <!-- <div class="row align-items-center">

                                    <h3 class="col-12 text-center" i18n="@@VIEWS.DIGITAL_DISCONNECT_VIEW.CHART_WEEKEND">% de usuarios que trabajan los fines de semana</h3>

                                    <div class="col-6 loading-wrapper">
                                        <div *ngIf="loading" class="p-component-overlay loading-overlay">
                                            <em class="pi pi-spin pi-spinner p-datatable-loading-icon"
                                                style="font-size: 2rem;"></em>
                                        </div>
                                        <overa-echart [size]="250" [options]="pieChartOptions"
                                            [data]="chartsData['PIE_WEEKEND'].data"></overa-echart>
                                    </div>
                                    <div class="col-6 centered">
                                        <div class="activities-container-shadow-black ">
                                            <span class="time-titles" i18n="@@VIEWS.DIGITAL_DISCONNECT_VIEW.AVERAGE_TIME_WEEKEND">Media de tiempo dedicado por los usuarios en fin de semana</span>
                                            <span class="time-data">{{averageHoursWeekend > 0 ? (averageHoursWeekend |
                                                timeFromSeconds:formatTime) : '00:00:00'}}</span>
                                        </div>
                                    </div>



                                </div> -->

                            </div>
                        </mat-card-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>