<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <mat-card-content>
                            <div class="container-fluid main-container">  
                                
                                <div class="container-individualFilters">
                                    <overa-filters #filterCmp
                                        [filterInit]="filterInit"
                                        (loading)="setLoading($event)"
                                        (loadData)="loadData($event)"
                                        (filterChange)="changeFilter($event)"
                                        (exportOnClick)="csvExport($event)"
                                        (validAllRequiredFilters)="validAllRequiredFilters($event)"
                                        (viewModeChange)="viewModeChange($event)">
                                    </overa-filters>
                                </div>

                                <!-- TODO: Reemplazar strings por traducciones -->

                                <overa-input
                                    title="Nombre de la tarea"
                                    [(value)]="this.taskName"
                                    placeholder="Nombre de la tarea"
                                    tooltip="Nombre de la tarea"
                                    [tooltipPosition]="'bottom'"
                                    [tooltipStyleClass]="'tooltip'"
                                />

                                <overa-dropdown
                                    title="Comando"
                                    [options]="optionsCommandsDropdown"
                                    [(value)]="selectedCommand"
                                    [optionLabel]="'publicName'"
                                    (valueChange)="createFormGroup()"
                                ></overa-dropdown>

                                <ng-container *ngIf="!selectedCommand?.sendDataVoid">
                                    <ng-container *ngFor="let field of selectedCommand?.fields">
                                        <span *ngIf="field.type != 'datavoid'">{{ field.name }} <span *ngIf="field.isRequired" style="color:red">(*)</span> </span>
                                                
                                                <ng-container [ngSwitch]="field.type">

                                                    <ng-container *ngSwitchCase="'multi'">
                                                        <overa-chips-object                          
                                                            [controlName]="field.name"
                                                            [formGroup]="formGroup"
                                                            [placeholder]="'Inserta parámetros...'"
                                                        >
                                                        </overa-chips-object>
                                                    </ng-container>

                                                    <ng-container *ngSwitchCase="'dropdown'">
                                                        <overa-dropdown
                                                            [options]="field.options"
                                                            [optionLabel]="'value'"                                                            
                                                            [placeholder]="field.placeholder ?? '' "
                                                            [tooltip]="field.tooltip"
                                                            [tooltipPosition]="'bottom'"
                                                            [tooltipStyleClass]="'tooltip'"
                                                            [controlName]="field.name"
                                                            [formGroup]="formGroup"
                                                        >
                                                        </overa-dropdown>
                                                    </ng-container>

                                                    <ng-container *ngSwitchCase="'boolean'">
                                                        <overa-dropdown
                                                            [options]= "booleanOptions"
                                                            [optionLabel]="'label'"
                                                            [placeholder]="field.placeholder ?? '' "
                                                            [tooltip]="field.tooltip"
                                                            [tooltipPosition]="'bottom'"
                                                            [tooltipStyleClass]="'tooltip'"
                                                            [controlName]="field.name"
                                                            [formGroup]="formGroup"
                                                        >
                                                        </overa-dropdown>
                                                    </ng-container>

                                                    <ng-container *ngSwitchDefault>
                                                        <ng-container *ngIf="field.type == 'json' || field.type == 'text'; else elseContainer">                                               
                                                            <overa-text-area
                                                                [placeholder]="field.placeholder ?? '' "
                                                                [tooltip]="field.tooltip"
                                                                [tooltipPosition]="'bottom'"
                                                                [tooltipStyleClass]="'tooltip'"
                                                                [controlName]="field.name"
                                                                [formGroup]="formGroup"
                                                            />                                                
                                                        </ng-container>
                                                    
                                                        <ng-template #elseContainer>     
                                                            <overa-input
                                                                [inputType]="field.type == 'number' ? 'number' : 'text'"
                                                                [placeholder]="field.placeholder ?? '' "
                                                                [tooltip]="field.tooltip"
                                                                [tooltipPosition]="'bottom'"
                                                                [tooltipStyleClass]="'tooltip'"
                                                                [controlName]="field.name"
                                                                [formGroup]="formGroup"
                                                            />
                                                        </ng-template>
                                                                                                    
                                                    </ng-container>

                                                </ng-container>

                                    </ng-container>
                                </ng-container>
                        
                            </div>
                        </mat-card-content>

                        <mat-card-actions [align]="'end'">

                            <overa-button
                                (onClick)="createControlCommand()"
                                [value]="'Crear'"
                                [isDisabled]="formGroup.invalid">
                            </overa-button>

                            <overa-button
                                (onClick)="closeDialog()"
                                [value]="'Cancelar'">
                            </overa-button>

                        </mat-card-actions>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

