import { Component } from "@angular/core";
import { ActivityBaseComponent } from "../../shared/activity-base.component";
import { Filters } from "src/app/models/filters.model";
import {
  OveraEchartData,
  OveraEchartHBarOptions,
} from "@overa/components/echart";
import { MeasurementService } from "src/app/services/measurement.service";
import { TimeFromSecondsPipe } from "@overa/shared";

@Component({
  selector: "app-productive-week",
  templateUrl: "./productive-week.component.html",
  styleUrls: ["./productive-week.component.scss"],
})
export class ProductiveWeekComponent extends ActivityBaseComponent {
  chartOptions: OveraEchartHBarOptions = new OveraEchartHBarOptions();
  chartData?: OveraEchartData; // Can be modified when showing percentages
  originalChartData?: OveraEchartData; // Unaltered received data
  chartHeight: number = 200;

  timeLineEnabled = false;
  timeFromSecondsPipe = new TimeFromSecondsPipe();

  constructor(private measurementService: MeasurementService) {
    super();
  }

  override ngOnInit(): void {
    this.startChartHeight = 200;

    // TODO: Use translations
    this.chartOptions.setTitle("Horas productivas semana");
    this.chartOptions.setGrid(["", "4%", 0, "20%"]);

    this.chartOptions.axisLabelFormatter = (value: any) => {
      return this.timeFromSecondsPipe.transform(value, this.formatLongTime);
    };

    this.chartOptions.setTooltipFormatter(
      (series) => `${series[0].axisValueLabel}`,
      (serie, series) => {
        let dataIndex = serie.dataIndex!;
        let timeSerie = 0;
        let percentageSerie = 0;
        let percentageSerieGlobal = 0;
        let info = this.originalChartData!.info!;

        switch (serie.seriesName) {
          case "total": {
            timeSerie = (
              this.originalChartData!.series!.find(
                (serie) => serie.name == "total"
              )!.data as any
            )[dataIndex].value;
            percentageSerieGlobal = info["totalGlobal"][dataIndex];
            break;
          }
          case "productive": {
            timeSerie = (
              this.originalChartData!.series!.find(
                (serie) => serie.name == "productive"
              )!.data as any
            )[dataIndex].value;
            percentageSerie = info["productiveDepartment"][dataIndex];
            percentageSerieGlobal = info["productiveGlobal"][dataIndex];
            break;
          }
          case "unproductive": {
            timeSerie = (
              this.originalChartData!.series!.find(
                (serie) => serie.name == "unproductive"
              )!.data as any
            )[dataIndex].value;
            percentageSerie = info["unproductiveDepartment"][dataIndex];
            percentageSerieGlobal = info["unproductiveGlobal"][dataIndex];
            break;
          }
        }

        return `<br/>${serie.marker}
        ${serie.seriesName}: ${this.timeFromSecondsPipe.transform(
          timeSerie,
          this.formatLongTime
        )}
        ${
          serie.seriesName != "total"
            ? ` (${percentageSerie.toFixed(
                0
              )} %) - [${percentageSerieGlobal.toFixed(0)} % Global]`
            : ""
        }
        `;
      }
    );

    this.chartOptions.legend.top = 25;
    this.chartOptions.seriesOption.barGap = 0;

    this.chartOptions.seriesOption.label!.formatter = (value) =>
      this.getLabel(value);
    this.chartOptions.seriesOption.label!.position = undefined;
  }

  override loadData(filters: Filters): void {
    this.loading = true;

    this.measurementService
      .getProductivityWeekByDepartment(filters)
      .subscribe((res) => {
        this.originalChartData = res;
        this.updateChart();

        this.chartData = this.timeLineEnabled
          ? res
          : this.transformSeriesDataIntoPercentages(res);

        this.loading = false;
      });
  }

  updateChart() {
    this.chartOptions.xAxis.show = this.timeLineEnabled;

    this.chartHeight =
      this.startChartHeight +
      this.chartHeightPerRow * this.originalChartData!.axisCategories!.length;
  }

  getLabel(serie: any): string {
    let dataIndex = serie.dataIndex;
    let seriesName = serie.seriesName;
    let percentages = this.chartData!.info!;

    let value = this.timeLineEnabled
      ? parseFloat(serie.data.value)
      : (
          this.originalChartData!.series!.find(
            (serie) => serie.name == seriesName
          )!.data as any
        )[dataIndex].value;
    let message = "";

    switch (serie.seriesName) {
      case "total": {
        message = this.timeFromSecondsPipe.transform(
          value,
          this.formatLongTime
        );
        break;
      }
      case "productive": {
        message = `${percentages["productiveDepartment"][dataIndex].toFixed(
          2
        )} %`;
        break;
      }
      case "unproductive": {
        message = `${percentages["unproductiveDepartment"][dataIndex].toFixed(
          2
        )} %`;
        break;
      }
    }

    return message;
  }

  transformSeriesDataIntoPercentages(data: OveraEchartData) {
    let percentages = data!.info!;
    let modifiedData = { ...data };

    modifiedData.series = data.series!.map((serie) => {
      let percentageData = "";
      switch (serie.name) {
        case "total": {
          percentageData = (serie.data as any).map((entry: any) => ({
            value: 100,
          }));
          break;
        }
        case "productive": {
          percentageData = percentages["productiveDepartment"];
          break;
        }
        case "unproductive": {
          percentageData = percentages["unproductiveDepartment"];
          break;
        }
      }
      return {
        name: serie.name,
        stack: (serie as any).stack,
        data: percentageData,
      };
    });

    return modifiedData;
  }
}
