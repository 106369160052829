import { ChangeDetectorRef, Component } from "@angular/core";
import { ActivityBaseComponent } from "../../shared/activity-base.component";
import { Filters } from "src/app/models/filters.model";
import { MeasurementAppsService } from "src/app/services/measurementApps.service";
import {
  OveraEchartData,
  OveraEchartHBarOptions,
  ResponsiveChartOptions,
} from "@overa/components/echart";

@Component({
  selector: "app-user-summary",
  templateUrl: "./userSummary.component.html",
  providers: [],
})
export class UserSummaryComponent extends ActivityBaseComponent {
  chartOptions: OveraEchartHBarOptions = new OveraEchartHBarOptions();
  chartData?: OveraEchartData;
  responsiveOptions?: ResponsiveChartOptions<OveraEchartHBarOptions>[];

  constructor(private measurementAppsService: MeasurementAppsService) {
    super();
  }

  loadData(filters: Filters): void {
    if (Object.keys(filters).length != 0) {
      const filters2 = { ...filters };
      this.loading = true;
      this.measurementAppsService
        .getSingleApplications(filters2)
        .subscribe((res) => {
          this.chartData = res;
          this.loading = false;
        });
    }
  }

  override ngOnInit() {
    super.ngOnInit();

    this.chartOptions.axisLabelFormatter = (value: any) => {
      return this.secondsToDHMS(value);
    };
    if (this.chartOptions.seriesOption.label) {
      this.chartOptions.seriesOption.label.formatter = (params: any) => {
        return this.secondsToDHMS(params.value);
      };
    }
    this.chartOptions.tooltip.show = false;

    this.responsiveOptions = [
      {
        condition: (width, height, ratio) => width < 500,
        changes: (chartOptions, chart) => {
          this.chartOptions.xAxis.interval = 1;
        },
      },
      {
        condition: (width, height, ratio) => width < 1000,
        changes: (chartOptions, chart) => {
          this.chartOptions.xAxis.interval = 2;
        },
      },
      {
        condition: (width, height, ratio) => width > 1000,
        changes: (chartOptions, chart) => {
          this.chartOptions.xAxis.interval = undefined;
        },
      },
    ];
  }
}
export namespace UserSummaryComponent {
  export const componentTypeName = "UserSummaryComponent";
  export const undefinedTextValue = "<undefined>";

  type TextPropertyName = "text";
  export type State = { [propertyName in TextPropertyName]: string };
}
