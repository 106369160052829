import { Component } from "@angular/core";
import {
  OveraEchartData,
  OveraEchartVBarOptions,
} from "@overa/components/echart";
import { ActivityBaseComponent } from "../../shared/activity-base.component";
import { Filters } from "src/app/models/filters.model";
import { MeasurementService } from "src/app/services/measurement.service";
import { TimeFromSecondsPipe } from "@overa/shared";

@Component({
  selector: "app-productive-usage",
  templateUrl: "./productive-usage.component.html",
  styleUrls: ["./productive-usage.component.scss"],
})
export class ProductiveUsageComponent extends ActivityBaseComponent {
  chartOptions: OveraEchartVBarOptions = new OveraEchartVBarOptions();
  chartData?: OveraEchartData;
  timeFromSecondsPipe = new TimeFromSecondsPipe();
  axisYIsDynamic = true;
  filters?: Filters;

  constructor(private measurementService: MeasurementService) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    // Set chart properties
    // TODO: Aplicar traducción
    this.chartOptions.title.text = "Tiempo Productivo VS Tiempo de Uso";

    this.chartOptions.setTooltipFormatter(
      (series) => `${series[0].axisValue}`,
      (serie) =>
        `<br/>${serie.marker}${
          serie.seriesName
        }: ${this.timeFromSecondsPipe.transform(
          serie.value,
          this.formatLongTime
        )}`
    );

    this.chartOptions.setLegend(
      [
        { name: "Activo", selected: true },
        { name: "Productivo", selected: true },
      ],
      {
        top: 25,
      }
    );

    this.chartOptions.yAxis.min = 0;
    this.chartOptions.axisLabelInterval = 3600; // 1 hour in seconds
    this.chartOptions.yAxis.minInterval = 3600; // 1 hour in seconds

    this.chartOptions.axisLabelFormatter = (value: any) => {
      let item = this.timeFromSecondsPipe.transform(value, this.formatLongTime);
      return item.length > item.indexOf("h") + 2 ? "" : item;
    };
  }

  override loadData(filters: Filters): void {
    //TODO: Mockup filters
    if (!filters || Object.keys(filters).length == 0) {
      filters = {
        startDate: new Date("2024-04-30T23:00:00.000Z"),
        endDate: new Date("2024-05-26T22:59:59.000Z"),
        itemsPerPage: 10,
        page: 1,
      };
    }
    this.filters = filters;
    this.loading = true;

    this.subscriptions.push(
      this.measurementService.getProductivityByDay(filters).subscribe(
        (res) => {
          res.series!.find((s) => s.name == "Productivo")!.type = "line"; //change bar serie into line serie
          this.chartData = res;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      )
    );
  }

  onSlideToggleClick(event: any) {
    // TODO: mat-slide-toggle no funciona y se pone un botón provisional
    //this.axisYIsDynamic = event.checked;
    this.axisYIsDynamic = !this.axisYIsDynamic;

    if (!this.axisYIsDynamic) {
      this.chartOptions.yAxis.max = 3600 * 16; // 16h in seconds
    } else {
      delete this.chartOptions.yAxis.max;
    }

    this.loadData(this.filters!);
  }
}
