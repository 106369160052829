import { Injectable } from "@angular/core";
import { User } from "@overa/security";
import { Filters } from "../models/filters.model";
import { Globals } from "@overa/base";
import { IGlobals } from "@overa/shared";

export class ActivityUser extends User {
  override defaultFilters?: DefaultFilters[];
  override currentGlobalFilters?: Filters;
  override filters?: Filters;

  tenantId?: string;
  override viewLinkFilter?: any;
}

export interface DefaultFilters {
  [key: string]: string | string[] | undefined;
  view?: string;
  filter?: string;
  value?: string | string[];
  type?: string;
}

@Injectable({
  providedIn: "root",
})
export class ActivityGlobals implements IGlobals {
  // menuItems: Menu[] = [];

  user: ActivityUser = this.globals.user as ActivityUser;
  cache = this.globals.cache;

  constructor(private globals: Globals) {
    console.log(this.globals.user);
  }
}
