import { Component, ChangeDetectorRef } from "@angular/core";

import { ActivityBaseComponent } from "../../shared/activity-base.component";
import { MeasurementAppsService } from "src/app/services/measurementApps.service";
import { Filters } from "src/app/models/filters.model";
import {
  OveraEchartData,
  OveraEchartHBarOptions,
} from "@overa/components/echart";

@Component({
  selector: "app-user-summary-url",
  templateUrl: "./userSummaryUrl.component.html",
})
export class UserSummaryUrlComponent extends ActivityBaseComponent {
  chartOptions: OveraEchartHBarOptions = new OveraEchartHBarOptions();
  chartData?: OveraEchartData;

  constructor(private measurementAppsService: MeasurementAppsService) {
    super();
  }

  loadData(filters: Filters): void {
    this.loading = true;

    this.measurementAppsService.getSingleUrls(filters).subscribe((res) => {
      this.chartData = res;
      this.loading = false;
    });
  }

  override ngOnInit() {
    super.ngOnInit();

    this.chartOptions.axisLabelFormatter = (value: any) => {
      return this.secondsToDHMS(value);
    };
    this.chartOptions.seriesOption.label!.formatter = (params: any) => {
      return this.secondsToDHMS(params.value);
    };
    this.chartOptions.tooltip.show = false;
  }
}
