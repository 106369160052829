<div class="noGoldenLayout-2">

   <div class="no-responsive-home-map centered-2">

      <img src="../../../assets/img/home/soluciones_overa_activity_ozona_tech.png" alt="Delegaciones de Ozona Tech"
         title="Delegaciones de Ozona Tech" name="overa_activity" border="0" usemap="#Map" />

      <map name="Map" id="Map">

         <area _ngcontent-tps-c582="" (click)="navigationClick($event,'Servicios Gestionados')" shape="rect"
            coords="1090, 900, 1460, 1100" alt="Servicios Gestionados" title="Servicios Gestionados"
            onmouseover="MM_swapImage('overa_activity', '', '../../../assets/img/home/soluciones_overa_activity_ozona_tech-servicios_gestionados.png', 1)"
            onmouseout="MM_swapImgRestore()"> <!-- href="/servicios-gestionados-cau-html" -->
         <area _ngcontent-tps-c582="" (click)="navigationClick($event,'RRHH')" shape="rect"
            coords="1210, 607, 1440, 794" alt="RRHH" title="RRHH"
            onmouseover="MM_swapImage('overa_activity', '', '../../../assets/img/home/soluciones_overa_activity_ozona_tech-rrhh.png', 1)"
            onmouseout="MM_swapImgRestore()"> <!-- href="/rrhh.html" -->
         <area _ngcontent-tps-c582="" (click)="navigationClick($event,'Seguridad')" shape="rect"
            coords="1170, 352, 1400, 520" alt="Seguridad" title="Seguridad"
            onmouseover="MM_swapImage('overa_activity', '', '../../../assets/img/home/soluciones_overa_activity_ozona_tech-seguridad.png', 1)"
            onmouseout="MM_swapImgRestore()"> <!-- href="/seguridad.html" -->
         <area _ngcontent-tps-c582="" (click)="navigationClick($event,'TI')" shape="rect" coords="205, 810, 420, 1000"
            alt="TI" title="TI"
            onmouseover="MM_swapImage('overa_activity', '', '../../../assets/img/home/soluciones_overa_activity_ozona_tech-ti.png', 1)"
            onmouseout="MM_swapImgRestore()"> <!-- href="/ti.html" -->
         <area _ngcontent-tps-c582="" (click)="navigationClick($event,'Negocio')" shape="rect" coords="50, 60, 280, 240"
            alt="Negocio" title="Negocio"
            onmouseover="MM_swapImage('overa_activity', '', '../../../assets/img/home/soluciones_overa_activity_ozona_tech-negocio.png', 1)"
            onmouseout="MM_swapImgRestore()"> <!-- href="/negocio.html" -->
         <area _ngcontent-tps-c582="" (click)="navigationClick($event,'Proyectos')" shape="rect"
            coords="1160, 152, 1450, 320" alt="Proyectos" title="Proyectos"
            onmouseover="MM_swapImage('overa_activity', '', '../../../assets/img/home/soluciones_overa_activity_ozona_tech-proyectos.png', 1)"
            onmouseout="MM_swapImgRestore()"> <!-- href="/proyectos-y-procesos.html" -->
         <area _ngcontent-tps-c582="" (click)="navigationClick($event,'UX')" shape="rect" coords="635, 1, 855, 181"
            alt="UX" title="UX"
            onmouseover="MM_swapImage('overa_activity', '', '../../../assets/img/home/soluciones_overa_activity_ozona_tech-ux.png', 1)"
            onmouseout="MM_swapImgRestore()"> <!-- href="/ux.html" -->
         <area _ngcontent-tps-c582="" (click)="navigationClick($event,'Assessment')" shape="rect"
            coords="65, 530, 285, 700" alt="Assessment" title="Assessment"
            onmouseover="MM_swapImage('overa_activity', '', '../../../assets/img/home/soluciones_overa_activity_ozona_tech-assessment.png', 1)"
            onmouseout="MM_swapImgRestore()"> <!-- href="/assessment.html" -->

      </map>

   </div>



   <div class="responsive-home-map">
      <div class="container">
         <div class="row">

            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-5 mb-5">
               <a title="UX" href="/ux.html"> <img class="btn-image" src="../../../assets/img/home/btn_ux-appm.png"
                     title="UX" alt="UX"> </a>
            </div>

            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  mt-5 mb-5">
               <a title="Negocio" href="/negocio.html"> <img class="btn-image"
                     src="../../../assets/img/home/btn_negocio.png" title="Negocio" alt="Negocio"> </a>
            </div>

            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  mt-5 mb-5">
               <a title="Assessment" href="/assessment.html"> <img class="btn-image"
                     src="../../../assets/img/home/btn_assessment.png" title="Assessment" alt="Assessment"> </a>
            </div>

            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  mt-5 mb-5">
               <a title="TI" href="/ti.html"> <img class="btn-image" src="../../../assets/img/home/btn_ti.png"
                     title="TI" alt="TI"> </a>
            </div>

            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  mt-5 mb-5">
               <a title="Proyectos y Procesos" href="/proyectos-y-procesos.html"> <img class="btn-image"
                     src="../../../assets/img/home/btn_proyectos-y-procesos.png" title="Proyectos y Procesos"
                     alt="Proyectos y Procesos"> </a>
            </div>

            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-5 mb-5">
               <a title="Seguridad" href="/seguridad.html"> <img class="btn-image"
                     src="../../../assets/img/home//btn_seguridad.png" title="Seguridad" alt="Seguridad"> </a>
            </div>

            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-5 mb-5">
               <a title="RRHH" href="/rrhh.html"> <img class="btn-image" src="../../../assets/img/home/btn_rrhh.png"
                     title="RRHH" alt="RRHH"> </a>
            </div>

            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  mt-5 mb-5">
               <a title="Servicios Gestionados CAU" href="servicios-gestionados-cau-html"> <img class="btn-image"
                     src="../../../assets/img/home/btn_servicios-gestionados-cau.png" title="Servicios Gestionados CAU"
                     alt="Servicios Gestionados CAU"> </a>
            </div>


         </div>
      </div>
   </div>


</div>