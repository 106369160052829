import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, ReplaySubject } from "rxjs";
import { BaseService, ConfigsService } from "@overa/shared";
import { ActivityCommand } from "../models/activityCommand.model";
import {
  ActivityCommandField,
  ActivityCommandFieldType,
} from "../models/activityCommandField.model";

@Injectable({ providedIn: "root" })
export class ActivityCommandsService extends BaseService<ActivityCommand> {
  private activityCommandsRabbitRouteSubject: ReplaySubject<string> =
    new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public activityCommandsRabbitRouteObservable: Observable<string> =
    this.activityCommandsRabbitRouteSubject.asObservable();

  private activityCommandsRabbitSubject: ReplaySubject<boolean> =
    new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public activityCommandsRabbitObservable: Observable<boolean> =
    this.activityCommandsRabbitSubject.asObservable();

  private endpointUrl = `${this.apiBaseUrl}${this.path}`;

  override get apiBaseUrl(): string {
    return this.config.getModuleConfig("overa-security", "apiBaseUrl") + "/api";
  }
  override get path(): string {
    return "/ActivityCommandsRabbit";
  }

  constructor(override http: HttpClient, config: ConfigsService) {
    super(http, config);
  }

  getActivityCommands(): Observable<ActivityCommand[]> {
    //return this.http.get<ActivityCommand[]>(`${this.endpointUrl}/GetCommands`);
    const commandsFromApi: ActivityCommand[] = [
      {
        id: "cmd1",
        privateName: "cmdPrivate1",
        publicName: "Command 1 Send data void",
        isExtensible: true,
        onlyOzona: false,
        fields: [
          new ActivityCommandField("Field 1-1"),
          {
            name: "Campo input",
            order: 1,
            type: ActivityCommandFieldType.INPUT,
            values: "Simple Value 1-2",
          },
        ],
        isExtendedCommand: true,
        sendDataVoid: true,
        dataIsSimpleParameter: true,
      },
      {
        id: "cmd2",
        privateName: "cmdPrivate2",
        publicName: "Command 2 Multi",
        isExtensible: false,
        onlyOzona: true,
        fields: [
          {
            name: "Campo input",
            order: 1,
            type: ActivityCommandFieldType.INPUT,
            values: "Simple Value 2-2",
          },
          {
            name: "Campo multi",
            order: 2,
            type: ActivityCommandFieldType.MULTI,
            values: [
              { id: "val3", isFixed: false, value: "par1" },
              { id: "val4", isFixed: true, value: "par2-fixed" },
            ],
          },
        ],
        isExtendedCommand: false,
        sendDataVoid: false,
        dataIsSimpleParameter: false,
      },
      {
        id: "cmd3",
        privateName: "cmdPrivate3",
        publicName: "Command 3",
        isExtensible: true,
        onlyOzona: false,
        fields: [
          {
            name: "campo dropdown",
            order: 1,
            type: ActivityCommandFieldType.DROPDOWN,
            options: [{ value: "Option A" }, { value: "Option B" }],
            values: { value: "Option B" },
          },
        ],
        isExtendedCommand: true,
        sendDataVoid: false,
        dataIsSimpleParameter: false,
      },
      {
        id: "cmd4",
        privateName: "cmdPrivate4",
        publicName: "Command 4 DataIsSimpleParameter",
        isExtensible: false,
        onlyOzona: true,
        fields: [
          {
            name: "Campo input",
            order: 4,
            type: ActivityCommandFieldType.INPUT,
            values: "Valor del campo SIMPLE PARAMETER",
          },
        ],
        isExtendedCommand: false,
        sendDataVoid: false,
        dataIsSimpleParameter: true,
      },
      {
        id: "cmd5",
        privateName: "cmdPrivate5",
        publicName: "Command 5 - Todos los tipos",
        isExtensible: true,
        onlyOzona: false,
        fields: [
          {
            name: "Campo Input",
            order: 1,
            type: ActivityCommandFieldType.INPUT,
            placeholder: "Insertar un valor",
            values: "Valor en Input",
            isFixed: true,
          },
          {
            name: "Campo Text",
            order: 2,
            type: ActivityCommandFieldType.TEXT,
            placeholder: "Insertar un valor",
            values: "Valor en Text",
          },
          {
            name: "Campo Multi",
            order: 3,
            type: ActivityCommandFieldType.MULTI,
            placeholder: "Insertar un valor",
            values: [
              { isFixed: true, value: "par1-fixed" },
              { isFixed: false, value: "par2" },
              { value: "par3" },
            ],
          },
          {
            name: "Campo Dropdown",
            order: 4,
            type: ActivityCommandFieldType.DROPDOWN,
            placeholder: "Seleccionar un valor",
            isRequired: true,
            options: [
              { value: "Option 1" },
              { value: "Option 2" },
              { value: "Option 3" },
            ],
            //values: { value: "Option 2" },
          },
          {
            name: "Campo Number",
            order: 5,
            type: ActivityCommandFieldType.NUMBER,
            placeholder: "Insertar un número",
            values: "8",
          },
          {
            name: "Campo Boolean",
            order: 6,
            type: ActivityCommandFieldType.BOOLEAN,
            placeholder: "Seleccionar true/false",
            values: { label: "False", value: false },
          },
          {
            name: "Campo JSON",
            order: 7,
            type: ActivityCommandFieldType.JSON,
            placeholder: "Insertar un json",
            values:
              '{"text":"valor1", "number":100, "bool": true, "json": {"prop":"valor"}}',
          },
        ],
        isExtendedCommand: true,
        sendDataVoid: false,
        dataIsSimpleParameter: false,
      },
    ];

    return of(commandsFromApi);
  }
}
