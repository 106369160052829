import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import {
  ChangeFilter,
  FilterInit,
  Filters,
} from "src/app/models/filters.model";
import { IGeneralData } from "src/app/models/interfaces/iGeneralData.model";
import { FiltersComponent } from "./filters/filters.component";
import { DatePipe } from "@angular/common";
import { DinamicPipeData } from "src/app/models/dinamicPipeData.model";
import { TimeFromSecondsPipe } from "@overa/shared";

@Component({
  template: "",
})
export abstract class ActivityBaseComponent implements OnInit, AfterViewInit {
  @ViewChild("filterCmp") filterCmp!: FiltersComponent;
  public selectedFilters: Filters;

  private _loading: boolean = false;

  // Indicate when filters change. Exclude pagination filter.
  /* isFilterChange: boolean = false;
  IsEmployeeMode: boolean = false;
  IsResponsibleMode: boolean = false;
  cancelReload: boolean = false; */
  subscriptions: Subscription[] = [];

  //Formats for dates and times
  protected formatLongTime = "longTime";
  protected formatShortTime = "HH:mm:ss";
  protected formatLongDate = "dd/MM/yyyy";
  protected formatShortDate = "HH:mm:ss";
  protected formatDateTime = "dd/MM/yyyy HH:mm:ss";
  protected formatShortYearDate = "DD/MM/YY";

  protected defaultFormatDate = this.formatLongDate;
  protected defaultFormatTimeDate = this.formatShortDate;
  protected defaultFormatTime = this.formatShortTime;
  protected defaultFormatDateTime = this.formatDateTime;

  public filterInit!: FilterInit;
  //columns: Column[] = [];
  isFilterChange: boolean = false;
  //table?: Table;

  protected startChartHeight = 100;
  protected chartHeightPerRow = 50;

  constructor() {
    this.selectedFilters = {};
    this.loading = false;
    this.setFilterInit();
  }

  abstract loadData(filters: Filters): void;

  ngOnInit(): void {
    console.log("onInit - BaseComponent");
    this.subscriptions = [];

    // this.loadData(this.selectedFilters);
  }

  ngAfterViewInit(): void {
    console.log("ngAfterViewInit - BaseComponent");
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  secondsToHMS(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  }

  secondsToDHMS(seconds: number): string {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let result = "";
    if (days > 0) {
      result += `${days}d `;
    }
    if (hours > 0 || days > 0) {
      result += `${hours}h `;
    }
    if (minutes > 0 || hours > 0 || days > 0) {
      result += `${minutes}m `;
    }
    result += `${remainingSeconds}s`;

    return result;
  }

  private setFilterInit() {
    this.filterInit = new FilterInit();

    this.filterInit.viewName = this.constructor.name;
    this.filterInit.autoloadData = true;
    this.filterInit.isLazyLoadData = false;
    this.filterInit.loadFilterWhenDropdown = true;
    this.filterInit.autoloadChangeFilter = true;
    this.filterInit.cleanFilterOnReloadData = false;
    //this.filterInit.notifyOnInitChangeFilter = false;
    this.filterInit.dataRangeDays = 1;
  }

  viewModeChange(viewMode: IGeneralData) {
    //this.setViewModes(viewMode.id);
  }

  // Component filter event emit.
  /* changeFilter(changeFilter: ChangeFilter): void {
      if (changeFilter) {
          this.isFilterChange = true;
      }
  } */

  // Component filter event emit.
  setLoading(value: any) {
    this.loading = value;
    this.cancelSubscriptions();
  }
  protected cancelSubscriptions() {
    this.subscriptions?.forEach((x) => {
      if (!x.closed) {
        x.unsubscribe();
      }
    });
    this.subscriptions = [];
  }

  /* protected setFilter(value: any, name: any) {
      if (!this.filters) {
          this.filters = new Filters();
      }
  
      this.filters[name] = value;
  } */

  /* protected addReloadInterval(time: number) {
      setTimeout(() => {
          if (!this.cancelReload) {
              this.loadData(this.filters);
          }
      }, time * 1000);
  } */

  /**
   * Converts a size from one measure to another.
   * @param size The value to convert.
   * @param from The measure of the value to be converted. Possible values: B, KB, MB, GB, TB, PB, EB, ZB, YB.
   * @param to The measure of the resulting value. Possible values: B, KB, MB, GB, TB, PB, EB, ZB, YB.
   * @param round If true, it returns the value rounded to two decimal places. Otherwise, it returns it without round. Default true.
   */
  /* protected convertSizeMeasurement(size: number, from: string, to: string, round: boolean = true): number {
      let sizeMeasurements = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      let fromIndex = sizeMeasurements.indexOf(from.toUpperCase());
      let toIndex = sizeMeasurements.indexOf(to.toUpperCase());
      if (fromIndex < 0) { throw new Error("Invalid parameter 'from'"); }
      if (toIndex < 0) { throw new Error("Invalid parameter 'to'"); }
      let exponent = toIndex - fromIndex;
      let convertedSize = size / Math.pow(1024, exponent);
      return round ? Math.round(convertedSize * 100) / 100 : convertedSize;
  } */

  validAllRequiredFilters(valid: boolean) {
    //TODO Add configurable message to show user that need set required filters.
  }

  /* protected setSelectedColumns(columns: Column[], selectedColumns: Column[]) {
    columns.forEach((column) => {
      if (column.selectable !== false) {
        // Filter by display name only.
        // If exists multiple fields with same name apply to all.
        column.selected =
          selectedColumns.filter((c) => c.header == column.header).length > 0;
      }
    });
  } */

  /* columChange(selectedColumns: Column[]) {
    this.setSelectedColumns(this.columns, selectedColumns);
  } */

  csvExport(options?: any) {
    //this.csvExportDefault(this.csvExportName, options, this.csvExportLevels, this.onlyExportLevels);
  }

  changeFilter(changeFilter: ChangeFilter): void {
    //super.changeFilter(changeFilter);
    if (changeFilter) {
      this.isFilterChange = true;
    }
    /* if (changeFilter) {
      let filterElement = changeFilter.filterElement;
      const value = changeFilter.value ? changeFilter.value : null;
      const name = changeFilter.name;

      if (filterElement && (filterElement as Table)) {
        filterElement.filter(value, name, "equals");
        filterElement = null;
        // With lazyLoad == false no filter is needed
      } else if (this.filterCmp?.filterInit?.isLazyLoadData) {
        if (this.table !== undefined) {
          if (value === "") {
            this.table.filter(null, name, "equals");
          } else {
            this.table.filter(value, name, "equals");
          }
        }
      }
    } */
  }
}
