import { Component } from "@angular/core";
import { ActivityBaseComponent } from "../../shared/activity-base.component";
import { Filters } from "src/app/models/filters.model";
import { MeasurementService } from "src/app/services/measurement.service";
import {
  OveraEchartHBarOptions,
  OveraEchartData,
  XAxisOptionType,
} from "@overa/components/echart";
import { TimeFromSecondsPipe } from "@overa/shared";
import { cloneDeep } from "lodash";

@Component({
  selector: "app-productive-vs-afk",
  templateUrl: "./productive-vs-afk.component.html",
  styleUrls: ["./productive-vs-afk.component.scss"],
})
export class ProductiveVsAfkComponent extends ActivityBaseComponent {
  commonChartOptions: OveraEchartHBarOptions = new OveraEchartHBarOptions();
  chartsOptions: OveraEchartHBarOptions[] = [];
  chartsData?: OveraEchartData[];
  chartsHeight: number[] = [];

  timeFromSecondsPipe = new TimeFromSecondsPipe();
  isPercent = true;

  constructor(private measurementService: MeasurementService) {
    super();
  }

  override ngOnInit(): void {
    this.commonChartOptions.setGrid(["", "4%", "3%", "3%"]);

    this.commonChartOptions.legend.show = false;

    this.commonChartOptions.setTooltipFormatter(
      (series) => `${series[0].axisValueLabel}<br/>`,
      (serie) =>
        this.isPercent
          ? `${serie.marker}${serie.seriesName}: ${serie.value} %<br/>`
          : `${serie.marker}${
              serie.seriesName
            }: ${this.timeFromSecondsPipe.transform(
              serie.value,
              this.formatLongTime
            )} <br/>`
    );

    this.commonChartOptions.axisLabelFormatter = (value: any) => {
      let message = this.isPercent
        ? `${value}` + " %"
        : `${this.timeFromSecondsPipe.transform(value, this.formatLongTime)}`;
      return message;
    };

    this.commonChartOptions.seriesOption.label!.position = "inside";

    this.commonChartOptions.seriesOption.label!.formatter = (serie: any) => {
      let label =
        serie.data.value != 0
          ? this.isPercent
            ? `${serie.data.value}` + " %"
            : `${this.timeFromSecondsPipe.transform(
                serie.data.value,
                this.formatLongTime
              )}`
          : ""; //Don't show label if value is 0
      return label;
    };

    this.commonChartOptions.seriesOption.labelLayout = {
      hideOverlap: true,
    };

    this.commonChartOptions.seriesOption.emphasis = {
      label: {
        show: false,
      },
    };
  }

  override loadData(filters: Filters): void {
    this.loading = true;
    //TODO: El filtro de tipo booleano isPercentage no funciona bien al pasar un array
    filters["isPercentage"] = true;
    this.isPercent = filters["isPercentage"];

    /* Para que el parámetro max de cada gráfica funcione correctamente, es necesario
    asignar las opciones actualizadas en el momento en que se renderiza el gráfico.
    Si intentamos crear los gráficos con las opciones por defecto combinadas con chartData
    y luego modificar el valor de xAxis.max, no funciona porque ejecuta primero este método,
    cambiando el valor de max en cada instancia, pero después ejecuta el setData con las
    opciones por defecto, sin modificar el valor de max, con lo que vuelve a sobreescribir
    los cambios. */
    this.measurementService
      .getProductivityByDayType(filters)
      .subscribe((res) => {
        this.chartsOptions = [];
        this.chartsHeight = [];
        res.forEach((chart) => {
          this.chartsOptions.push(this.getChartOptions(chart));
          this.chartsHeight.push(
            this.startChartHeight +
              this.chartHeightPerRow * chart.axisCategories!.length
          );
        });
        this.chartsData = res;

        this.loading = false;
      });
  }

  /**
   *
   * @param data Obtiene las opciones para cada gráfica que se genera de
   * forma dinámica al recibir el chartData. Permite generar opciones
   * independientes de las otras gráficas y modificar sus parámetros.
   * @returns Un objeto de opciones para la gráfica.
   */
  getChartOptions(data: OveraEchartData): OveraEchartHBarOptions {
    let options = cloneDeep(this.commonChartOptions);

    (options.xAxis! as XAxisOptionType).max =
      this.isPercent == true ? 100 : data.info!["max"];

    return options;
  }

  getChartHeight() {}
}
