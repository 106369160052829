import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { DinamicPipeData } from "src/app/models/dinamicPipeData.model";
import { DurationPipe } from "../../../shared/pipes/duration.pipe";
import { DialogUpdateDetailComponent } from "./dialog-update-detail/dialog-update-detail.component";
import { detailedViews } from "./model";
import { OveraDialogService } from "@overa/components/dialog";
import { MeasurementService } from "src/app/services/measurement.service";
import { Filters } from "src/app/models/filters.model";
import { ActivityBaseTableComponent } from "../../shared/activity-base-table-component.component";
import {
  COLUMN_DATETIME_LONG_PIPE,
  COLUMN_TIME_DURATION_PIPE,
} from "src/app/shared/pipes/column_pipes/column-data-formatter.pipe";
import { OveraDataTableColumn } from "@overa/components/common";

@Component({
  selector: "app-detailed-view",
  templateUrl: "./detailed-view.component.html",
  styleUrls: ["./detailed-view.component.scss"],
  providers: [DatePipe, DurationPipe],
})
export class DetailedViewComponent
  extends ActivityBaseTableComponent<any>
  implements OnInit
{
  //columns: OveraDataTableColumn[] = [];
  data: any[] = [];
  itemsTemp: any[] = [];
  totalRecords: number = 20;
  //datePipe = DatePipe;
  durationPipe = DurationPipe;

  /*   @ViewChild("datatable", { static: true })
    dataTable?: OveraDataTableComponent<any>;
  
    columnDateTimePipe: DinamicPipeData[] = [];
    columnTimePipe: DinamicPipeData[] = [];
    protected formatDateTime = "dd/MM/yyyy HH:mm:ss";
  
    protected defaultFormatDateTime = this.formatDateTime; */

  datatableLiterals: { [literal: string]: string } = {
    NoData: $localize`:@@common.nodata: No hay datos para mostrar`,
    Yes: $localize`:@@common.yes: Yes`,
    No: $localize`:@@common.no: No`,
    FilterBy: $localize`:@@datatable.filterby: Filtrar por`,
    checkboxOff: $localize`:@@common.no: No`,
    checkboxOn: $localize`:@@common.yes: Sí`,
  };

  constructor(
    private dialogService: OveraDialogService,
    private measurementService: MeasurementService
  ) {
    super();

    /* this.columnDateTimePipe = [
      { name: this.datePipe, args: [this.defaultFormatDateTime] },
    ];
    this.columnTimePipe = [{ name: this.durationPipe, args: [] }]; */
    this.createColumns();
  }

  override ngOnInit() {
    this.data = detailedViews;
    this.totalRecords = this.data.length;
    this.itemsTemp = this.data;

    let filters: Filters = {
      page: 1,
      itemsPerPage: 5,
      filterType: "global",
      startDate: new Date("2024-02-04T23:00:00.000Z"),
      endDate: new Date("2024-02-11T22:59:59.000Z"),
    };

    this.loadData(filters);
  }

  loadData(filters: Filters): void {
    this.measurementService.getDetailedView(filters).subscribe((res) => {
      this.data = res;
    });
  }

  loadDataFunction = (
    pag: number,
    items_per_pag: number,
    order: { field: string; order: number }[]
  ): any[] => {
    if (pag != undefined && pag != null) {
      pag = pag * items_per_pag;

      const startIndex = pag;
      const endIndex = Math.min(pag + items_per_pag, this.itemsTemp.length);
      const loadItems = this.itemsTemp.slice(startIndex, endIndex);
      this.data = loadItems;
      if (this.dataTable) this.dataTable.items = loadItems;
      return loadItems;
    } else {
      return [];
    }
  };

  onSelectedItems(items: any[]) {
    console.log("ITEMS SELECCIONADOS", items);
  }

  createColumns() {
    this.columns = [
      {
        field: "selected",
        header: "Seleccionar",
        type: "Checkbox",
        responsive: true,
        responsiveHeader: true,
        isDisabled: false,
        command: (index, item) => {
          console.log("INDEX-ITEM", index, item);
        },
      },
      {
        field: "app",
        header: "App",
        type: "String",
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "device",
        header: "Dispositivo",
        type: "String",
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "type",
        header: "Tipo",
        type: "String",
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "startDate",
        header: "Fecha Inicio",
        type: "DateTime",
        pipes: COLUMN_DATETIME_LONG_PIPE,
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "endDate",
        header: "Fecha Fin",
        type: "DateTime",
        pipes: COLUMN_DATETIME_LONG_PIPE,
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "duration",
        header: "Duración",
        type: "Integer",
        pipes: COLUMN_TIME_DURATION_PIPE,
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "edit",
        header: "Editar",
        type: "Button",
        icon: "pi pi-pencil",
        responsive: true,
        responsiveHeader: true,
        command: (index: number, item: any) => {
          console.log("index,item", index, item);
          this.openUpdateDialog(item);
        },
      },
    ];
  }
  private openUpdateDialog(item: any) {
    const dialogRef = this.dialogService.openConfirm(
      DialogUpdateDetailComponent,
      {},
      {
        header: $localize`:@@steps.editStage:Editando Detalle`,
        width: "60vw",
        height: "20vh",
      }
    );

    // dialogRef.onClose.subscribe((result: any) => {});
  }
}
