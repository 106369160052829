//import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
/* import { AccountService } from '@overa/security';
import { OveraExportUtils } from "@overa/shared";
import * as echarts from 'echarts/dist/echarts.js';
import * as moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { WorkOffline } from 'src/app/models/workOrganization/workOffline.model';
import { WorkType } from 'src/app/models/workOrganization/workType.model'; */
/* import { WorkOfflineService } from 'src/app/services/workOffline.service';
import { WorkTypeService } from 'src/app/services/workType.service'; */
import { Filters } from "../../../../models/filters.model";
import { GeneralDataService } from "../../../../services/generalData.service";
/* import { MeasurementUserService } from '../../../../services/measurementUser.service';
import { DynamicPipe, SumPipe, TimeFromSecondsPipe } from '../../../../shared/shared.pipes'; */
import { ActivityBaseComponent } from "../../activity-base.component";
import { FiltersComponent } from "../../../shared/filters/filters.component";
import { RefreshService } from "@overa/shared";

@Component({
  selector: "app-global-filters",
  templateUrl: "./globalFilters.component.html",
  providers: [
    /*DynamicPipe , MessageService, ConfirmationService, SumPipe, TimeFromSecondsPipe, DatePipe */
  ],
})
export class GlobalFiltersComponent extends ActivityBaseComponent {
  //subscription1$: Subscription;
  //subscriptions: Subscription[] = [];
  //datePipe = DatePipe;
  //filterCmp: FiltersComponent;
  //telemetry: any[];
  //employeeUsername: { [key: string]: string; };
  //employeeUsers: any[];
  //appCategory;
  //appTelemetry: any[];
  //showCharts: boolean = false;
  //statusChartOption: any;
  //statusOveraEchartData: any;

  constructor(
    //private measurementUserService: MeasurementUserService,
    //protected override generalDataService: GeneralDataService,
    //dynamicPipe: DynamicPipe,
    //private workOfflineService: WorkOfflineService,
    //public accountService: AccountService,
    //private workTypeService: WorkTypeService,
    //protected exportUtils: OveraExportUtils,
    private refreshService: RefreshService
  ) {
    super(/* dynamicPipe,  generalDataService,*/);
    //TODOFIRST DASH
    refreshService.refreshSource$.subscribe((refresh) => {
      this.loadData(refresh);
    });
  }

  override ngOnInit() {
    super.ngOnInit();
    this.filterInit.exportMode = true;
    /*this.filterInit.autoloadData = true;
    this.filterInit.isLazyLoadData = false;
    this.filterInit.loadFilterWhenDropdown = true;
    this.filterInit.autoloadChangeFilter = true;
    this.filterInit.cleanFilterOnReloadData = false;
    //this.filterInit.notifyOnInitChangeFilter = false;
    this.filterInit.dataRangeDays = 1;    

    //TODOFIRST DASH
    this.refreshService.refresh3(this.filterInit.filter);
    //this.filterInit.export = true;   */
  }

  override ngAfterViewInit() {}

  loadData(filters: Filters) {
    this.selectedFilters = filters;
    console.log("filters on global filters loadData", this.selectedFilters);
  }

  override validAllRequiredFilters(valid: boolean) {
    super.validAllRequiredFilters(valid);
    if (!valid) {
      //this.resetView();
    }
  }
}
