import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Injector,
  NgModule,
  isDevMode,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";

import {
  AccountService,
  AuthInterceptor,
  AuthService,
  ErrorHandlerInterceptor,
  MenuService,
  TenantInterceptor,
  UserInterceptor,
} from "@overa/security";

import {
  InitModule,
  OveraAppComponent,
  OveraAppRoutingModule,
} from "@overa/base";

import {
  AUTH_SERVICE_TOKEN,
  GLOBALS_TOKEN,
  MENU_SERVICE_TOKEN,
  NgxTranslateService,
  OveraTranslateModule,
  USER_SERVICE_TOKEN,
  UtcDateInterceptor,
} from "@overa/shared";

import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { APP_BASE_HREF, CommonModule } from "@angular/common";

import { ServiceWorkerModule } from "@angular/service-worker";
import { LayoutComponent } from "./components/layout.component";

import { GoldenLayoutComponent } from "./components/goldenLayout/golden-layout.component";
import { GoldenLayoutService } from "./services/golden-layout.service";

import { TextComponent } from "./components/goldenLayout/exampleComponents/text.component";

import { FiltersComponent } from "./components/shared/filters/filters.component";
import { GlobalFiltersComponent } from "./components/shared/filters/globalFilters/globalFilters.component";
import { FilterDefaultDialog } from "./components/shared/filters/filterDefaultDialog/filterDefaultDialog.component";

import { UserSummaryComponent } from "./components/userActivity/userSummary/userSummary.component";
import { UserSummaryUrlComponent } from "./components/userActivity/userSummaryUrl/userSummaryUrl.component";
import { TelecommutingIndicatorsComponent } from "./components/indicators/telecommuting-indicators/telecommuting-indicators.component";
import { UserActivitySummary2WeeksComponent } from "./components/userActivity/user-activity-summary2-weeks/user-activity-summary2-weeks.component";
import { PercentageUsersTelemetryComponent } from "./components/audit/percentage-users-telemetry/percentageUsersTelemetry.component";
import { UserStatusSummaryOneLineComponent } from "./components/userActivity/userStatusSummaryOneLine/userStatusSummaryOneLine.component";
import { DigitalDisconnectComponent } from "./components/indicators/digitalDisconnect/digitalDisconnect.component";
import { SignalAnalysisComponent } from "./components/audit/signalAnalysis/signalAnalysis.component";
import { UserActivitySummaryGroupedComponent } from "./components/userActivity/user-activity-summary-grouped/user-activity-summary-grouped.component";
import { DetailedViewComponent } from "./components/userActivity/detailed-view/detailed-view.component";
import { DialogUpdateDetailComponent } from "./components/userActivity/detailed-view/dialog-update-detail/dialog-update-detail.component";
import { UserAnalisysComponent } from "./components/userActivity/userAnalisys/userAnalisys.component";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { AppConfig } from "./app.config";

import { OveraModule } from "./modules/overa.module";

import { MenuSettings } from "./menusettings";
import { UserActivitySummaryComponent } from "./components/userActivity/user-activity-summary/user-activity-summary.component";
import { DeviceProcessConsumptionComponent } from "./components/deviceActivity/device-process-consumption/device-process-consumption.component";
import { HomeComponent } from "./components/home/home.component";
import { PrimeNgModule } from "./modules/primeng.module";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import {
  MonacoEditorModule,
  NGX_MONACO_EDITOR_CONFIG,
  NgxMonacoEditorConfig,
} from "ngx-monaco-editor-v2";
import { OveraDataTableModule } from "@overa/components/datatable";
import {
  DYNAMIC_GUARD_TOKEN,
  DYNAMIC_MAINTENANCE_GUARD_TOKEN,
  OveraDynamicModule,
} from "@overa/dynamic/components";
import { ActivityDynamicMaintenancesGuard } from "./guards/activitydynamic-maintenances.guard";
import { ActivityDynamicGuard } from "./guards/activitydynamic.guard";
import { DialogService } from "primeng/dynamicdialog";
import { DeviceSignalReportComponent } from "./components/signals/device-signal-report/device-signal-report.component";
import { ProductiveUsageComponent } from "./components/indicators/productive-usage/productive-usage.component";
import { EntryExitComponent } from "./components/indicators/entry-exit/entry-exit.component";
import { ProductiveWeekComponent } from "./components/indicators/productive-week/productive-week.component";
import { ProductiveVsAfkComponent } from "./components/indicators/productive-vs-afk/productive-vs-afk.component";
import { SaveLayoutDialog } from "./components/goldenLayout/saveLayoutDialog/saveLayoutDialog.component";
import { AppUrlScheduleComponent } from "./components/userActivity/app-url-schedule/app-url-schedule.component";
import { ProcessConsumptionComponent } from "./components/deviceActivity/process-consumption/process-consumption.component";
import { NoPreloading, RouterModule } from "@angular/router";
import { TerminalComponent } from "./components/deviceActivity/terminal/terminal.component";
import { OveraTerminalComponent } from "./components/shared/overa-terminal/overa-terminal.component";
import { DeviceCommandsComponent } from "./components/deviceActivity/device-commands/device-commands.component";
import { OveraCommandConsoleComponent } from "./components/shared/overa-command-console/overa-command-console.component";
import { OveraFiltersModule } from "@overa/components/filters";
import { ActivityGlobals } from "./utils/globals";

export function getBaseUrlFactory() {
  try {
    return document.getElementsByTagName("base")[0].href ?? "/";
  } catch {
    return "/";
  }
}

@NgModule({
  declarations: [
    LayoutComponent,
    SaveLayoutDialog,
    HomeComponent,
    TextComponent,
    GoldenLayoutComponent,
    FiltersComponent,
    GlobalFiltersComponent,
    FilterDefaultDialog,

    UserSummaryComponent,
    UserSummaryUrlComponent,
    TelecommutingIndicatorsComponent,

    UserActivitySummary2WeeksComponent,
    PercentageUsersTelemetryComponent,
    UserStatusSummaryOneLineComponent,
    DigitalDisconnectComponent,
    SignalAnalysisComponent,
    UserActivitySummaryGroupedComponent,
    DetailedViewComponent,
    DialogUpdateDetailComponent,
    UserAnalisysComponent,
    UserActivitySummaryComponent,
    DeviceProcessConsumptionComponent,
    DeviceProcessConsumptionComponent,
    DeviceSignalReportComponent,
    ProductiveUsageComponent,
    EntryExitComponent,
    ProductiveWeekComponent,
    ProductiveVsAfkComponent,
    AppUrlScheduleComponent,
    ProcessConsumptionComponent,
    TerminalComponent,
    OveraTerminalComponent,
    DeviceCommandsComponent,
    OveraCommandConsoleComponent,
  ],
  imports: [
    InitModule.setConfig(AppConfig),
    OveraTranslateModule.setConfig("es", [
      "calendar",
      "charts",
      "common",
      "views",
      "filters",
    ]),
    OveraDataTableModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    NgxSpinnerModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    OveraDynamicModule,
    OveraFiltersModule,

    OveraModule,
    PrimeNgModule,

    //ToDo: modify when new overa base lib version admit onSameUrlNavigation parameter
    //OveraAppRoutingModule.forRoot(MenuSettings.MENU.routes ?? []),
    RouterModule.forRoot(MenuSettings.MENU.routes ?? [], {
      enableTracing: true,
      preloadingStrategy: NoPreloading,
      onSameUrlNavigation: "reload",
    }),

    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
      timeOut: 5000,
      enableHtml: true,
    }),

    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    GoldenLayoutService,
    {
      provide: DYNAMIC_GUARD_TOKEN,
      useClass: ActivityDynamicGuard,
    },
    {
      provide: DYNAMIC_MAINTENANCE_GUARD_TOKEN,
      useClass: ActivityDynamicMaintenancesGuard,
    },
    { provide: APP_BASE_HREF, useFactory: getBaseUrlFactory },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UserInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UtcDateInterceptor, multi: true },

    { provide: GLOBALS_TOKEN, useClass: ActivityGlobals },

    //TODO: Remove thhis providers, its a test to develop overa-navbar
    { provide: USER_SERVICE_TOKEN, useClass: AccountService },
    { provide: AUTH_SERVICE_TOKEN, useClass: AuthService },
    { provide: MENU_SERVICE_TOKEN, useClass: MenuService },

    DialogService,
    // NgxTranslateService,
  ],
  bootstrap: [OveraAppComponent],
  entryComponents: [],
})
export class AppModule {
  constructor(
    private translate: NgxTranslateService,
    private injector: Injector
  ) {
    this.translate.setTranslations();
    const menu = new MenuSettings();
  }
  static injector: Injector;
  ngDoBootstrap() {
    AppModule.injector = this.injector;
  }
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
