import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { BaseService, ConfigsService } from "@overa/shared";
import { Department } from "../models/businessOrganization/department.model";

@Injectable({
  providedIn: "root",
})
export class DepartmentService extends BaseService<Department> {
  override get apiBaseUrl(): string {
    return this.config.getModuleConfig("overa-security", "apiBaseUrl") + "/api";
  }
  override get path(): string {
    return "/department";
  }

  private endpointUrl = `${this.apiBaseUrl}${this.path}`;

  constructor(override http: HttpClient, config: ConfigsService) {
    super(http, config);
  }

  getDepartments(): Observable<Department[]> {
    return this.http.get<Department[]>(this.endpointUrl);
  }

  getDepartmentsWithEmployees(): Observable<Department[]> {
    return this.http.get<Department[]>(`${this.endpointUrl}/all`);
  }

  getDepartmentsBoss(id: string): Observable<Department[]> {
    return this.http.get<Department[]>(`${this.endpointUrl}/boss/${id}`);
  }
  getDepartment(id: string): Observable<Department> {
    return this.http.get<Department>(`${this.endpointUrl}/${id}`);
  }

  addDepartment(department: Department): Observable<Department> {
    return this.http.post<Department>(this.endpointUrl, department);
  }

  updateDepartment(department: Department): Observable<Department> {
    return this.http.put<Department>(this.endpointUrl, department);
  }

  deleteDepartment(id: string): Observable<any> {
    return this.http.delete<any>(`${this.endpointUrl}/${id}`);
  }
  importDepartments(departments: any[]) {
    return this.http.post<Department[]>(
      `${this.endpointUrl}/Import`,
      departments
    );
  }

  getDepartmentNames(): Observable<Department[]> {
    return this.http.get<Department[]>(`${this.endpointUrl}/departmentNames`);
  }
}
