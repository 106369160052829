import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component } from "@angular/core";
import { DialogService } from "primeng/dynamicdialog";
import { Filters } from "src/app/models/filters.model";
import { ActivityBaseComponent } from "../../shared/activity-base.component";
import { TimeFromSecondsPipe, DynamicPipe } from "@overa/shared";
import { MeasurementService } from "src/app/services/measurement.service";
import {
  OveraEchartData,
  OveraEchartVBarOptions,
} from "@overa/components/echart";

@Component({
  selector: "app-user-activity-summary-2-weeks",
  templateUrl: "./user-activity-summary2-weeks.component.html",
  providers: [DialogService, DatePipe, TimeFromSecondsPipe, DynamicPipe],
})
export class UserActivitySummary2WeeksComponent extends ActivityBaseComponent {
  chartOptions: OveraEchartVBarOptions = new OveraEchartVBarOptions();
  chartData?: OveraEchartData;

  isGsgrupoTenant: boolean = false;

  timeFromSecondsPipe = new TimeFromSecondsPipe();

  constructor(private measurementService: MeasurementService) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    //configure chart
    this.filterInit.dataRangeDays = 14;
    const legendData = {
      expected: $localize`:@@VIEWS.COMMONS.CHARTS.ECHARTS.LEGEND.EXPECTED:`,
      productive: $localize`:@@VIEWS.COMMONS.CHARTS.ECHARTS.LEGEND.PRODUCTIVE:`,
      effective: $localize`:@@VIEWS.COMMONS.CHARTS.ECHARTS.LEGEND.EFFECTIVE:`,
      active: $localize`:@@VIEWS.COMMONS.CHARTS.ECHARTS.LEGEND.ACTIVE:`,
      signingDuration: $localize`:@@VIEWS.COMMONS.CHARTS.ECHARTS.LEGEND.SIGNING_TIME:`,
    };

    const data = [
      { name: legendData.expected, selected: true },
      { name: legendData.productive, selected: true },
      { name: legendData.effective, selected: false },
      { name: legendData.active, selected: false },
      { name: legendData.signingDuration, selected: false },
    ];
    if (this.isGsgrupoTenant) {
      data.pop();
    }

    this.chartOptions.setLegend(data);

    this.chartOptions.tooltip.formatter = (params): string => {
      let message = "";
      if (Array.isArray(params)) {
        params.forEach((item) => {
          message +=
            `${item.marker}${item.seriesName}: ` +
            this.getTimeFromValue(item.value as number) +
            " - " +
            this.getPercentage(
              item.value as number,
              params[0].value as number
            ) +
            "<br/>";
        });
      }
      return message;
    };

    this.chartOptions.tooltip.axisPointer!.type = "line";
    this.chartOptions.grid = { containLabel: true }; // use default echarts margins
  }

  loadData(filters: Filters) {
    this.loading = true;
    this.measurementService
      .getSimpleActivitySummaryByEmployee(filters)
      .subscribe((res) => {
        this.chartData = res;
        this.loading = false;
      });
  }

  getTimeFromValue(value: number) {
    if (value != 0) {
      return this.timeFromSecondsPipe.transform(
        value * 3600,
        this.formatLongTime
      );
    } else {
      return "0h";
    }
  }

  getPercentage(itemValue: number, totalValue: number) {
    if (totalValue == 0) {
      return "0 %";
    }
    const percentage = ((itemValue * 100) / totalValue).toFixed(2);
    return percentage + " %";
  }
}
