import { Component } from "@angular/core";
import { ActivityBaseComponent } from "../../shared/activity-base.component";
import { Filters } from "src/app/models/filters.model";
import { MeasurementUserService } from "src/app/services/measurementUser.service";
import * as moment from "moment";
import {
  OveraEchartData,
  OveraEchartUtils,
  ICustomSeriesInfo,
  OveraEchartTimeLineOptions,
  transformDataIntoCustomData,
} from "@overa/components/echart";

@Component({
  selector: "app-user-status-summary-online",
  templateUrl: "./userStatusSummaryOneLine.component.html",
  styleUrls: ["./userStatusSummaryOneLine.component.scss"],
})
export class UserStatusSummaryOneLineComponent extends ActivityBaseComponent {
  chartOptions: OveraEchartTimeLineOptions = new OveraEchartTimeLineOptions(6);
  chartData?: OveraEchartData;
  height: number = 0;
  showCharts: boolean = false;

  typesInfo: ICustomSeriesInfo[] = [
    {
      key: "app",
      isAfk: false,
      name: "app",
      color: "#ff111c",
      enabled: true,
      graphIndex: 0,
    },
    {
      key: "url",
      isAfk: false,
      name: "url",
      color: "#7d11ff",
      enabled: true,
      graphIndex: 0,
    },
    {
      key: "afk",
      isAfk: true,
      name: "afk",
      color: "#ECDA21",
      enabled: true,
      graphIndex: 1,
    },
    {
      key: "alive",
      isAfk: false,
      name: "alive",
      color: "#05a400",
      enabled: true,
      graphIndex: 2,
    },
    {
      key: "lock",
      isAfk: false,
      name: "lock",
      color: "#FF8000",
      enabled: true,
      graphIndex: 4,
    },
    {
      key: "current",
      isAfk: false,
      name: "current",
      color: "#000000",
      enabled: true,
      graphIndex: 3,
    },
  ];

  constructor(private measurementUserService: MeasurementUserService) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    // const data = [
    //   { name: "app", selected: true, color: "#ff111c" },
    //   { name: "url", selected: true, color: "#7d11ff" },
    //   { name: "afk", selected: true, color: "#ECDA21" },
    //   { name: "alive", selected: true, color: "#05a400" },
    //   { name: "lock", selected: true, color: "#FF8000" },
    //   { name: "current", selected: true, color: "#FF8000" },
    // ];
    // this.chartOptions.setLegend(data);
    this.chartOptions.tooltip.formatter = (params: any) => {
      const texts = [
        params.value[5].charAt(0).toUpperCase() + params.value[5].slice(1),
        params.value[6],
        $localize`:@@VIEWS.COMMONS.CHARTS.ECHARTS.TOOLBOX.START:` +
          ": " +
          OveraEchartUtils.formatDate(new Date(params.value[3])),
        $localize`:@@VIEWS.COMMONS.CHARTS.ECHARTS.TOOLBOX.END:` +
          ": " +
          OveraEchartUtils.formatDate(new Date(params.value[4])),
      ];
      return texts.join("<br/>");
    };

    this.chartOptions.axisLabelFormatter = (value: number | Date) => {
      return moment(value).format("HH:mm").toString().replace(" ", "\n");
    };
  }

  loadData(filters: Filters): void {
    this.loading = true;

    this.measurementUserService.getStatusOneLine(filters).subscribe((res) => {
      this.loading = false;
      this.height = 90 * 5 * (res.axisCategories?.length ?? 1);
      const customOveraEchartData = transformDataIntoCustomData(
        res,
        this.typesInfo
      );

      // set legend
      // this.chartOptions.setLegend(
      //   customOveraEchartData.series?.map((serie) => serie.name as string) ?? []
      // );

      // set series
      this.chartData = customOveraEchartData;

      this.showCharts = true;
    });
  }

  private setLegend(seriesNames: string[]) {
    const data: string[] = [];
    const data2: string[] = [];
    const selected: { [key: string]: boolean } = {};

    this.typesInfo.forEach((type) => {
      if (seriesNames.includes(type.name)) {
        data.push(type.name);
        selected[type.name] =
          this.chartOptions.legend.selected?.[type.name] ?? type.enabled;
        data2.push(type.color);
      }
    });

    this.chartOptions.legend.data = data;
    this.chartOptions.legend.selected = selected;
    this.chartOptions.legend.itemStyle = {};
  }
}
