import { Component } from "@angular/core";
import { ActivityBaseComponent } from "../../shared/activity-base.component";
import { Filters } from "src/app/models/filters.model";
import {
  ChartFormat,
  OveraEchartMultipleData,
  OveraEchartPieOptions,
} from "@overa/components/echart";
import { MeasurementService } from "src/app/services/measurement.service";

@Component({
  selector: "app-entry-exit",
  templateUrl: "./entry-exit.component.html",
  styleUrls: ["./entry-exit.component.scss"],
})
export class EntryExitComponent extends ActivityBaseComponent {
  // Common options for both charts
  pieChartOptions: OveraEchartPieOptions = new OveraEchartPieOptions();
  showCharts = false;

  // Series for each chart
  chartsData: OveraEchartMultipleData = {
    INPUT: {
      id: "input",
    },
    OUTPUT: {
      id: "output",
    },
  };

  constructor(private measurementService: MeasurementService) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    // Set chart options
    this.pieChartOptions.seriesOption.label!.formatter =
      ChartFormat.Serie_Value_Percent;
    this.pieChartOptions.tooltip.formatter = ChartFormat.Serie_Value_Percent;
  }

  override loadData(filters: Filters): void {
    this.loading = true;

    this.measurementService
      .getMeasurementsGroupedEntryExit(filters)
      .subscribe((res) => {
        // set series
        Object.values(this.chartsData).forEach((chartFront) => {
          const chart = res.find((chart) => chart.chartKey == chartFront.id);
          if (chart) {
            chart.chartName = chartFront.title ?? chart.chartName;
            chartFront.data = chart;
          }
        });

        this.showCharts = true;
        this.loading = false;
      });
  }
}
