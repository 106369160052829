import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { ActivityBaseComponent } from "./activity-base.component";
import { OveraDataTableComponent } from "@overa/components/datatable";
import { ChangeFilter } from "src/app/models/filters.model";
import { OveraTreetableComponent } from "@overa/components/treetable";
import { OveraDataTableColumn } from "@overa/components/common";

@Component({
  template: "",
})
export abstract class ActivityBaseTableComponent<
  T
> extends ActivityBaseComponent {
  @ViewChild("datatable", { static: true })
  dataTable?: OveraDataTableComponent<any> | OveraTreetableComponent;
  columns: OveraDataTableColumn[] = [];

  constructor() {
    super();
  }

  protected setSelectedColumns(
    columns: OveraDataTableColumn[],
    selectedColumns: OveraDataTableColumn[]
  ) {
    columns.forEach((column) => {
      //TODO: Check this method for new columns of type OveraDataTableColumn
      /* if (column.selectable !== false) {
        // Filter by display name only.
        // If exists multiple fields with same name apply to all.
        column.selected =
          selectedColumns.filter((c) => c.header == column.header).length > 0;
      } */
    });
  }

  columChange(selectedColumns: OveraDataTableColumn[]) {
    this.setSelectedColumns(this.columns, selectedColumns);
  }

  override changeFilter(changeFilter: ChangeFilter): void {
    super.changeFilter(changeFilter);

    if (changeFilter) {
      let filterElement = changeFilter.filterElement;
      const value = changeFilter.value ? changeFilter.value : null;
      const name = changeFilter.name;

      if (filterElement && (filterElement as OveraDataTableComponent<T>)) {
        filterElement.filter(value, name, "equals");
        filterElement = null;
        // With lazyLoad == false no filter is needed
      } else if (this.filterCmp?.filterInit?.isLazyLoadData) {
        if (this.dataTable !== undefined) {
          // TODO: Check this section for new OveraDataTableComponent
          /* if (value === "") {
            this.dataTable.filter(null, name, "equals");
          } else {
            this.dataTable.filter(value, name, "equals");
          } */
        }
      }
    }
  }
}
