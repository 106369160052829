import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FitAddon } from "@xterm/addon-fit";
import { Terminal } from "@xterm/xterm";

@Component({
  selector: "overa-terminal",
  templateUrl: "./overa-terminal.component.html",
  styleUrls: ["./overa-terminal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OveraTerminalComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("terminal", { static: true }) terminalDiv!: ElementRef;
  @Input() host: string = "";

  //TODO: Crear una estructura de datos a recibir en el callback para ofrecer más capacidades de manipularlos al terminal.
  // De momento solo espera recibir un id del ControlCommand creado, pero podría recibir otros datos
  @Output() commandSent = new EventEmitter<{
    command: string;
    callback: (commandId: number) => void;
  }>();

  private terminal!: Terminal;
  private fitAddon!: FitAddon;
  private commandHistory: string[] = [];
  private currentCommandIndex: number = 0;
  private currentCommand: string = "";
  private resizeObserver!: ResizeObserver;

  private readonly PROMPT = "$ ";
  private promptY: number = 0;
  private userHasWritten: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.initializeTerminal();
  }

  ngAfterViewInit(): void {
    this.setupResizeObserver();
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
  }

  initializeTerminal(): void {
    this.terminal = new Terminal({
      cursorBlink: true,
    });

    this.fitAddon = new FitAddon();
    this.terminal.loadAddon(this.fitAddon);
    this.terminal.open(this.terminalDiv.nativeElement);
    this.fitAddon.fit();

    this.terminal.writeln(`Connected to ${this.host}`);
    this.prompt();

    // Eventos de introducción de caracteres
    this.terminal.onKey((e) => this.handleKeyInput(e));

    // Manejador de eventos de pulsación de teclas que se ejecuta antes que onKey
    this.terminal.attachCustomKeyEventHandler((e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === "v" && e.type == "keydown") {
        this.pasteFromClipboard();
        return false;
      } else if (e.ctrlKey && e.key === "c" && e.type == "keydown") {
        this.copyToClipboard();
        return false;
      } else {
        // El evento lo manejará onKey
        return true;
      }
    });
  }

  setupResizeObserver(): void {
    this.resizeObserver = new ResizeObserver(() => {
      this.fitAddon.fit();
      this.updatePromptY();
    });
    this.resizeObserver.observe(this.terminalDiv.nativeElement);
  }

  prompt(): void {
    this.terminal.write("\r\n$ ");
    this.currentCommand = "";
    setTimeout(() => {
      this.promptY = this.getCursorPosition()[2];
    }, 0);
  }

  copyToClipboard(): void {
    const selectedText = this.terminal.getSelection();
    if (selectedText) {
      navigator.clipboard
        .writeText(selectedText)
        .then(() => {
          console.log("Texto copiado al portapapeles");
        })
        .catch((err) => {
          console.error("Error al copiar el texto: ", err);
        });
    }
  }

  pasteFromClipboard(): void {
    navigator.clipboard.readText().then((text) => {
      this.terminal.write(text);
      this.currentCommand += text;
    });
  }

  /* handleKeyInput(event: KeyboardEvent): void {
    if (event.type == "keydown") {
      switch (event.key) {
        case "Enter":
          this.handleCommand();
          break;
        case "Backspace":
          this.handleBackspace();
          break;
        case "ArrowUp":
          this.handleHistoryNavigation(-1);
          break;
        case "ArrowDown":
          this.handleHistoryNavigation(1);
          break;
        case "ArrowLeft":
          this.moveCursorLeft();
          break;
        case "ArrowRight":
          this.moveCursorRight();
          break;
        default:
          if (event.key.length === 1) {
            this.handleCharacterInput(event.key);
          }
      }
    } */

  handleKeyInput(event: { key: string; domEvent: KeyboardEvent }): void {
    const { key, domEvent } = event;
    switch (domEvent.key) {
      case "Enter":
        this.handleCommand();
        break;
      case "Backspace":
        this.handleBackspace();
        break;
      case "ArrowUp":
        this.handleHistoryNavigation(-1);
        break;
      case "ArrowDown":
        this.handleHistoryNavigation(1);
        break;
      case "ArrowLeft":
        this.moveCursorLeft();
        break;
      case "ArrowRight":
        this.moveCursorRight();
        break;
      default:
        if (domEvent.key.length === 1) {
          this.handleCharacterInput(domEvent.key);
        }
    }
  }

  async handleCommand(): Promise<void> {
    const command = this.currentCommand.trim();
    if (command) {
      this.commandHistory.push(command);
      this.currentCommandIndex = this.commandHistory.length;

      // Esperar la respuesta del comando emitido
      const response = await this.waitForCommandResponse(command);
      this.writePlaceholder(response);
    }
    this.prompt();

    // TODO: Mock que simula que se ha recibido la respuesta del comando.
    // Debe manejarse en un método aparte.
    // IMPORTANTE: Ver cómo manejar los saltos de línea. Deben transformarse en \r\n para que los escriba bien la terminal
    setTimeout(() => {
      let commandId = 101675;
      this.handleResponse(
        commandId,
        "Respuesta del comando " +
          commandId +
          "\r\nlinea 1\r\n  -linea 2\r\n  -linea 3"
      );
    }, 4000);
  }

  // TODO: Mocked response received from service
  handleResponse(commandId: number, response: string) {
    this.replacePlaceholder(commandId, response);

    setTimeout(() => {
      // Actualizar promptY
      this.updatePromptY();

      // Si en la última línea no había nada introducido, mover el cursor
      // a la derecha un espacio para que quede "$ "
      /* if (!this.currentCommand) {
        this.terminal.write(Cursor.moveRight(1));
      } */
    }, 0);
  }

  waitForCommandResponse(command: string): Promise<number> {
    return new Promise((resolve) => {
      this.commandSent.emit({ command, callback: resolve });
    });
  }

  private generatePlaceholderText(commandId: number): string {
    return `Command sent (id=${commandId}). Waiting for response...`;
  }

  writePlaceholder(commandId: number) {
    let placeholder = this.generatePlaceholderText(commandId);
    this.moveCursorToEndOfCommand();
    this.terminal.write("\r\n" + placeholder + "\r\n");
  }

  replacePlaceholder(commandId: number, response: string): void {
    // texto de la terminal
    let terminalText = this.getTextFromTerminal();

    // Texto del placeholder
    const placeholderText = this.generatePlaceholderText(commandId);

    // Escapar caracteres especiales para usar en la regex
    const escapedPlaceholderText = placeholderText.replace(
      /[.*+?^${}()|[\]\\]/g,
      "\\$&"
    );
    // Generar la expresión regular a aplicar en la sustitución del texto
    const regex = new RegExp(`${escapedPlaceholderText}\\s*`);

    //const replacedText = terminalText.replace(regex, response + "\r\n" + "\r\n"); // No funciona
    const replacedText = terminalText.replace(regex, (match) => {
      return response + "\r\n" + "\r\n";
    });

    // Limpiar la terminal y escribir el texto actualizado
    this.terminal.write(Cursor.Codes.cleanScreen);
    this.terminal.write(replacedText);
  }

  getTextFromTerminal(): string {
    // Obtener todo el texto de la terminal como un solo string
    let terminalText = "";
    const totalLines = this.promptY + 1;
    for (let i = 0; i < totalLines; i++) {
      const line = this.terminal.buffer.active.getLine(i);
      const lineIsWrapped =
        this.terminal.buffer.active.getLine(i + 1)?.isWrapped ?? false;

      if (line) {
        if (i < totalLines - 1) {
          terminalText +=
            line?.translateToString(true) + (lineIsWrapped ? "" : "\r\n");
        } else {
          terminalText += line?.translateToString(true);
        }
      }
    }

    return terminalText;
  }

  handleBackspace(): void {
    let pos = this.getPositionInCurrentCommand();

    if (pos > 0) {
      let commandBeforeCursor = this.currentCommand.slice(0, pos - 1);
      let commandAfterCursor = this.currentCommand.slice(pos);
      this.currentCommand = commandBeforeCursor + commandAfterCursor;

      this.moveCursorLeft();

      // Crear una pequeña pausa para que el terminal pueda actualizar los valores
      setTimeout(() => {
        let newCursorX = this.getCursorPosition()[0];
        let newCursorY = this.getCursorPosition()[2];
        this.terminal.write(
          Cursor.Codes.deleteFromCursorToEndOfScreen +
            commandAfterCursor +
            Cursor.moveTo(newCursorY + 1, newCursorX + 1) // rows and columns start in 1
        );
      }, 0);
    }
  }

  handleHistoryNavigation(direction: number): void {
    this.currentCommandIndex = Math.max(
      0,
      Math.min(this.commandHistory.length, this.currentCommandIndex + direction)
    );

    // Al seleccionar comandos de la historia, al llegar al último, si se pulsa abajo, se borra
    // la selección, excepto si el usuario ya ha introducido algo
    if (
      this.currentCommandIndex == this.commandHistory.length &&
      !this.userHasWritten
    ) {
      this.terminal.write(
        Cursor.moveTo(this.promptY + 1, this.PROMPT.length + 1) +
          Cursor.Codes.deleteFromCursorToEndOfScreen
      );
      this.currentCommand = "";
    } else {
      this.currentCommand = this.commandHistory[this.currentCommandIndex] ?? "";
      if (this.currentCommand) {
        this.terminal.write(
          Cursor.moveTo(this.promptY + 1, this.PROMPT.length + 1) +
            Cursor.Codes.deleteFromCursorToEndOfScreen +
            this.currentCommand
        );
        this.userHasWritten = false;
      }
    }
  }

  handleCharacterInput(char: string): void {
    let pos = this.getPositionInCurrentCommand();

    if (pos < this.currentCommand.length) {
      let cursorX = this.getCursorPosition()[0];
      let cursorY = this.getCursorPosition()[2];
      let commandBeforeCursor = this.currentCommand.slice(0, pos);
      let commandAfterCursor = this.currentCommand.slice(pos);
      this.currentCommand = commandBeforeCursor + char + commandAfterCursor;

      this.terminal.write(
        Cursor.Codes.deleteFromCursorToEndOfScreen +
          char +
          commandAfterCursor +
          Cursor.moveTo(cursorY + 1, cursorX + 1) // rows and columns start in 1
      );
      this.moveCursorRight();
    } else {
      this.currentCommand += char;
      this.terminal.write(char);
    }
    this.userHasWritten = true;
  }

  moveCursorLeft(): void {
    let pos = this.getPositionInCurrentCommand();
    let cursorX = this.getCursorPosition()[0];

    if (pos > 0) {
      if (cursorX > 0) {
        this.terminal.write(Cursor.moveLeft(1));
      } else {
        this.terminal.write(Cursor.moveUp(1));
        this.terminal.write(Cursor.moveToEndOfLine());
      }
    }
  }

  moveCursorRight(): void {
    let pos = this.getPositionInCurrentCommand();
    let cursorX = this.getCursorPosition()[0];
    let cols = this.getCursorPosition()[3];

    if (pos < this.currentCommand.length) {
      if (cursorX < cols - 1) {
        this.terminal.write(Cursor.moveRight(1));
      } else {
        this.terminal.write(Cursor.moveDown(1));
        this.terminal.write(Cursor.moveToColumn(1));
      }
    }
  }

  moveCursorToEndOfCommand(): void {
    const cols = this.getCursorPosition()[3];
    const commandLength = this.currentCommand.length + this.PROMPT.length;
    const endCursorX = commandLength % cols;
    const endCursorY = Math.floor(commandLength / cols) + this.promptY;

    this.terminal.write(Cursor.moveTo(endCursorY + 1, endCursorX + 1));
  }

  getCursorPosition() {
    let baseY = this.terminal.buffer.active.viewportY;
    let cursorX = this.terminal.buffer.active.cursorX;
    let cursorYRelative = this.terminal.buffer.active.cursorY;
    let cursorYAbsolute = baseY + cursorYRelative;
    let cols = this.terminal.cols;

    return [cursorX, cursorYRelative, cursorYAbsolute, cols];
  }

  getPositionInCurrentCommand(): number {
    const [cursorX, , cursorY, cols] = this.getCursorPosition();
    let rows = cursorY - this.promptY;
    let pos = rows * cols + cursorX - this.PROMPT.length;

    return pos;
  }

  // Usar solo con el cursor al final de línea para evitar cálculos erróneos
  updatePromptY() {
    const [, , cursorLine, cols] = this.getCursorPosition();
    let lines = Math.ceil(
      (this.currentCommand.length + this.PROMPT.length) / cols
    );
    this.promptY = cursorLine - (lines - 1);
  }
}

/**
 * Clase con funciones que permiten realizar acciones en la terminal.
 */
export class Cursor {
  /**
   * Secuencias de escape de códigos ANSI para utilizar en la terminal.
   * "\x1b" representa el carácter de escape, y le sigue el código ANSI con
   * la función a realizar.
   */
  static readonly Codes = {
    resetText: "\x1b[0m", // default text
    redText: "\x1b[31m",
    greenText: "\x1b[32m",
    boldText: "\x1b[1m",

    deleteCurrentLine: "\x1b[2K",
    deleteFromCursorToEndOfLine: "\x1b[K",
    deleteFromStartOfLineToCursor: "\x1b[1K",
    cleanScreen: "\x1b[H\x1b[2J",
    deleteFromCursorToEndOfScreen: "\x1b[J",
  };

  // Cursor movement
  static moveLeft(n: number = 1): string {
    return `\x1b[${n}D`;
  }

  static moveRight(n: number = 1): string {
    return `\x1b[${n}C`;
  }

  static moveUp(n: number = 1): string {
    return `\x1b[${n}A`;
  }

  static moveDown(n: number = 1): string {
    return `\x1b[${n}B`;
  }

  static moveToColumn(col: number): string {
    return `\x1b[${col}G`;
  }

  static moveToRow(row: number): string {
    return `\x1b[${row};H`;
  }

  static moveTo(row: number, col: number): string {
    return `\x1b[${row};${col}H`;
  }

  static moveToEndOfLine(): string {
    return `\x1b[999C`;
  }
}
