<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" acceptLabel="Si"
    rejectLabel="No"></p-confirmDialog>
<p-toast></p-toast>
<div class="main-content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <mat-card-content>
                    <div class="container-fluid main-container">
                        <!-- <div *ngIf="hidde">
                            <filters #filterCmp [filters]=filters [filterInit]=filterInit (loading)=setLoading($event)
                                (loadData)=loadData($event) (filterChange)=changeFilter($event)
                                (columChange)=columChange($event) (exportOnClick)=csvExport($event)
                                (validAllRequiredFilters)=validAllRequiredFilters($event)
                                (viewModeChange)=viewModeChange($event)>
                            </filters>
                        </div>
                        <div class="col-lg-3">
                            <mat-slide-toggle (change)="onSlideToggleClick($event)" i18n="@@VIEWS.COMMONS.FILTERS.VIEW_OVERLAPPING.TITLE"></mat-slide-toggle>
                            <mat-slide-toggle *ngIf='!isInta' (change)="onSlideToggleClickDetail($event)" i18n="@@VIEWS.COMMONS.FILTERS.VIEW_MORE_DETAIL.TITLE"></mat-slide-toggle>
                        </div> -->
                        <div class="row">
                            <!-- <div [ngClass]="!loading ?'d-none':'col-12 loading-wrapper'">
                                <div *ngIf="loading" class="p-component-overlay loading-overlay">
                                    <em class="pi pi-spin pi-spinner p-datatable-loading-icon"
                                        style="font-size: 2rem;"></em>
                                </div>
                            </div> -->
                            <!-- <div class="col-md-12" *ngIf="principalEntity && principalEntity.indicators"> -->
                            <div class="col-md-12">
                                <div class="row mb-12">

                                    <!-- Recuadros con información -->
                                    <div class="row fluid cards-link-application">


                                        <div class="col-lg-2 col-md-6 col-sm-6">
                                            <div class="card card-stats">
                                                <div class="card-body ">
                                                    <div class="row">
                                                        <div class="col-12 col-md-12">
                                                            <div class="icon-big text-center icon-warning">
                                                                <i class="fa fa-bug text-danger"></i>
                                                            </div>
                                                            <div class="numbers">
                                                                <p class="card-category text-success icon-big text-center"
                                                                    style="margin-bottom: 10px; margin-top: 10px">
                                                                    Entrada
                                                                </p>
                                                            </div>
                                                            <div class="row text-center time-data">
                                                                {{horaEntrada|dynamicPipe:columnTimePipe}}
                                                                <!-- <div class="col-lg-6 col-md-6 col-sm-6"> Ejecuciones <br> <span class="datos-numbers">20</span> </div>
                                                                <div class="col-lg-6 col-md-6 col-sm-6"> Dispositivos <br> <span class="datos-numbers">2</span> </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-2 col-md-6 col-sm-6">
                                            <div class="card card-stats">
                                                <div class="card-body ">
                                                    <div class="row">
                                                        <div class="col-12 col-md-12">
                                                            <div class="icon-big text-center icon-warning">
                                                                <i class="fa fa-clock"></i>
                                                            </div>
                                                            <div class="numbers">
                                                                <p class="card-category text-success icon-big text-center"
                                                                    style="margin-bottom: 10px; margin-top: 10px">
                                                                    Entrada
                                                                </p>
                                                            </div>
                                                            <div class="row text-center">
                                                                {{horaEntrada|dynamicPipe:columnTimePipe}}
                                                                <!-- <div class="col-lg-6 col-md-6 col-sm-6"> Ejecuciones <br> <span class="datos-numbers">20</span> </div>
                                                                <div class="col-lg-6 col-md-6 col-sm-6"> Dispositivos <br> <span class="datos-numbers">2</span> </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>



                                    <!-- Gráfica Indicadores -->
                                    <overa-echart [size]="chartSize" [options]="chartOptions"
                                        [data]="chartData"></overa-echart>


                                </div>

                                <div class="row mb-3 not-spacing">
                                    <!-- Tablas de la parte inferior: Resumen de Aplicación y Detalles de proceso -->
                                </div>

                                <div class="row top-margin">
                                    <!-- Tablas que aparecen cuando se activa "Detalles" en el switch -->
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </div>
        </div>
    </div>
</div>