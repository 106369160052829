// import {
//   AfterViewInit,
//   Component,
//   ElementRef,
//   Input,
//   OnDestroy,
//   ViewChild,
//   ViewEncapsulation,
// } from "@angular/core";
// import { Terminal } from "@xterm/xterm";
// import { FitAddon } from "@xterm/addon-fit";
// import { AccountService } from "@overa/security";
// import { ControlCommandService } from "src/app/services/controlCommand.service";
// import { TenantIdTenantUidService } from "src/app/services/tenantIdTenantUidService";
// import { ActivityCommandsService } from "src/app/services/activityCommandsService.service";
// import { ControlCommand } from "src/app/models/controlCommand.model";

// @Component({
//   selector: "app-terminal",
//   templateUrl: "./terminal.component.html",
//   styleUrls: ["./terminal.component.scss"],
//   encapsulation: ViewEncapsulation.None,
// })
// export class TerminalComponent implements AfterViewInit, OnDestroy {
//   readonly PROMPT = ">>> ";

//   // ANSI scape sequences for changing text properties
//   readonly resetText = "\x1b[0m"; // default
//   readonly redText = "\x1b[31m";
//   readonly greenText = "\x1b[32m";
//   readonly boldText = "\x1b[1m";

//   @ViewChild("terminalContainer")
//   terminalContainer!: ElementRef;

//   @Input() host?: string = undefined; // Target host
//   @Input() interactive: boolean = true; // Allows inserting commands
//   @Input() content?: string; // Content to show in terminal

//   terminal?: Terminal;
//   fitAddon?: FitAddon;

//   input: string = ""; // User input when pressing ENTER
//   text: string = ""; // All the text shown in terminal (commands and responses)
//   tenantUid: string = "";

//   constructor(
//     public accountService: AccountService,
//     protected tenantService: TenantIdTenantUidService,
//     protected commandsService: ActivityCommandsService,
//     protected controlCommandService: ControlCommandService
//   ) {}

//   ngAfterViewInit(): void {
//     let selectedTenant = this.accountService.currentTenant;
//     let tenantId = selectedTenant?.id;

//     if (tenantId) {
//       this.tenantService.getTenantById(tenantId).subscribe((res) => {
//         if (res) {
//           this.tenantUid = res.tenantUid ?? "";
//         }
//       });
//     }

//     this.fitAddon = new FitAddon();
//     this.terminal = new Terminal({
//       rows: 20,
//     });

//     this.terminal.options = {
//       convertEol: true,
//       cursorBlink: false,
//       cursorStyle: "block",
//     };

//     this.terminal.loadAddon(this.fitAddon);
//     this.terminal.open(this.terminalContainer.nativeElement);
//     this.fitAddon.fit();

//     this.prompt();
//   }

//   ngOnDestroy(): void {
//     this.terminal?.dispose();
//   }

//   sendInput() {
//     let text = this.input.replace(/[^\S\r\n]+/g, " "); // Remove multiple spaces but not line breaks

//     this.write(text);
//     this.processCommand(text);
//   }

//   /* Update terminal text replacing placeholders for commands with their responses */
//   refresh(event: any) {
//     const regex = /Command sent \(id=(\d+)\)\. Waiting for response.../g; // Placeholder created when sending a command
//     const ids: number[] = []; // ControlCommands ids generated

//     // Get all ids of controlCommands with waiting response
//     let match;
//     while ((match = regex.exec(this.text)) !== null) {
//       const id = Number(match[1]);
//       ids.push(id);
//     }

//     // Get deliveries of controlCommands with waiting response
//     this.controlCommandService
//       .getDeliveriesFromControlCommands(ids)
//       .subscribe((res) => {
//         let deliveries = res;

//         // Get Responses from deliveries
//         deliveries.forEach((delivery) => {
//           if (delivery.response) {
//             let responseObject = this.getResultObject(delivery.response);
//             let responseText = "";

//             if (responseObject) {
//               responseText =
//                 this.getStdout(responseObject) +
//                 this.redText +
//                 this.boldText +
//                 this.getStderr(responseObject) +
//                 this.getRuntimeFailure(responseObject) +
//                 this.resetText;

//               // Replace placeholders with responses
//               this.processResponse({
//                 id: delivery.controlCommandId!,
//                 text: responseText,
//               });
//             }
//           }
//         });

//         // Clean terminal and write its text again with replacements made
//         this.terminal?.clear();
//         this.terminal?.write("\r");
//         this.terminal?.write(this.text);
//       });
//   }

//   /*
//   Writes a prompt in the terminal
//   */
//   prompt() {
//     this.terminal?.write(this.PROMPT);
//     this.text += this.PROMPT;
//   }

//   /*
//   Writes the user input in the terminal
//   */
//   write(input: string) {
//     input.split("\n").forEach((line, index) => {
//       if (index > 0) {
//         this.prompt();
//       }
//       this.terminal?.write(line + "\r\n");
//       this.text += line + "\r\n";
//     });
//     this.terminal?.write("\r\n");
//     this.text += "\r\n";
//     this.input = "";
//   }

//   /* Extract result object from string that contains it */
//   getResultObject(content: string): ResultObject | undefined {
//     const startIndex = content.indexOf("{");
//     const endIndex = content.lastIndexOf("}");
//     const jsonSubstring = content.substring(startIndex, endIndex + 1);

//     let result: ResultObject | undefined;

//     try {
//       result = JSON.parse(jsonSubstring);
//     } catch {
//       result = undefined;
//     }

//     return result;
//   }

//   /* Returns content of stdout property */
//   getStdout(resultObject: ResultObject): string {
//     return this.replaceNewlines(resultObject.stdout);
//   }

//   /* Returns content of stderr property */
//   getStderr(resultObject: ResultObject): string {
//     return this.replaceNewlines(resultObject.stderr);
//   }

//   /* Returns content of runtime_failure property */
//   getRuntimeFailure(resultObject: ResultObject): string {
//     return this.replaceNewlines(resultObject.runtime_failure);
//   }

//   private replaceNewlines(input: string | undefined): string {
//     return input?.replace(/(\\+r\\+n|\\+n\\+r|\\+r|\n)+/g, "\r\n") ?? "";
//   }

//   /* Reads user input and executes an action */
//   processCommand(command: string) {
//     switch (command.toLowerCase()) {
//       case "help": {
//         this.terminal?.writeln("Bienvenido al sistema de ayuda.");
//         this.terminal?.writeln("Los comandos disponibles son:");
//         this.terminal?.writeln("- help: muestra este mensaje de ayuda");
//         this.terminal?.writeln("- cls: borra la terminal");
//         this.terminal?.writeln(
//           "- command: crea un ControlCommand de tipo script con el comando introducido"
//         );
//         this.terminal?.writeln("");
//         this.prompt();
//         break;
//       }
//       case "cls": {
//         this.terminal?.clear();
//         this.text = "";
//         this.terminal?.write("\r");
//         this.prompt();
//         break;
//       }
//       default: {
//         this.createControlCommand(command);
//       }
//     }
//   }

//   /* Creates a ControlCommand object and inserts it into the database for being
//   processed by the control server */
//   createControlCommand(command: string) {
//     this.commandsService.getActivityCommandsRabbit().subscribe((res) => {
//       const selectedCommand = res.find(
//         (c) => c.privateName == "script" && c.publicName == "Scripting"
//       );

//       let commandToSent = {
//         command: selectedCommand?.privateName ?? "script",
//         data: JSON.stringify({
//           script: command,
//         }),
//       };

//       //TODO: MOCKED HOSTNAME FOR TESTING
//       let controlCommand: ControlCommand = {
//         description: "Script from terminal",
//         tenantUid: this.tenantUid,
//         type: "script",
//         data: JSON.stringify(commandToSent),
//         //controlCommandRecipients: [{ clientId: this.host.toUpperCase() }],
//         controlCommandRecipients: [{ clientId: "OA-TEST-W10" }],
//         activityCommandsRabbitId: selectedCommand?.isParentCommand
//           ? selectedCommand.id
//           : undefined,
//         activityCommandsExtendedId: selectedCommand?.isParentCommand
//           ? undefined
//           : selectedCommand?.id,
//       };

//       /* this.controlCommandService.addControlCommand(controlCommand).subscribe(
//         (res) => {
//           let response = `Command sent (id=${res.id}). Waiting for response...`;
//           this.write(response + "\r\n");
//           this.prompt();
//         },
//         (error) => {
//           console.log("Error al crear el ControlCommand");
//         }
//       ); */

//       // TESTING Mock Process response received from Rabbit
//       var id = 101675;

//       let response = `Command sent (id=${id}). Waiting for response...`;
//       this.write(response);
//       this.prompt();
//     });
//   }

//   /* Replaces the placeholder for a controlCommand with its response received after being executed */
//   processResponse(response: CommandResponse) {
//     const regex = new RegExp(
//       `Command sent \\(id=${response.id}\\)\\. Waiting for response...`,
//       "g"
//     );
//     this.text = this.text.replace(regex, response.text);
//   }

//   openMenu() {
//     console.log("menu");
//   }
// }

// export interface Command {
//   command: string;
//   data: CommandData;
// }

// export interface CommandData {
//   [field: string]: any;
// }

// /* Defines the command response identified by its controlCommandId */
// export interface CommandResponse {
//   id: string;
//   text: string;
// }

// /* Defines the structure of the response of a script command */
// export interface ResultObject {
//   stdout: string;
//   stderr: string;
//   parsed_output_type: string;
//   runtime_failure: string;
//   exit_status: number;
// }

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Terminal } from "@xterm/xterm";
import { FitAddon } from "@xterm/addon-fit";
import { AccountService } from "@overa/security";
import { ControlCommandService } from "src/app/services/controlCommand.service";
import { TenantIdTenantUidService } from "src/app/services/tenantIdTenantUidService";
import { ActivityCommandsService } from "src/app/services/activityCommands.service";

import { OveraTerminalComponent } from "../../shared/overa-terminal/overa-terminal.component";
import { ControlCommand } from "src/app/models/controlCommand.model";

@Component({
  selector: "app-terminal",
  templateUrl: "./terminal.component.html",
  styleUrls: ["./terminal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TerminalComponent implements AfterViewInit {
  @ViewChild("terminal") terminal!: OveraTerminalComponent;
  tenantUid: string = "";
  testHost: string = "OA-TEST-W10";

  constructor(
    public accountService: AccountService,
    protected tenantService: TenantIdTenantUidService,
    protected commandsService: ActivityCommandsService,
    protected controlCommandService: ControlCommandService
  ) {}

  ngAfterViewInit(): void {
    let selectedTenant = this.accountService.currentTenant;
    let tenantId = selectedTenant?.id;

    if (tenantId) {
      this.tenantService.getTenantById(tenantId).subscribe((res) => {
        if (res) {
          this.tenantUid = res.tenantUid ?? "";
        }
      });
    }
  }

  /* Update terminal text replacing placeholders for commands with their responses */
  /* refresh(event: any) {
    const regex = /Command sent \(id=(\d+)\)\. Waiting for response.../g; // Placeholder created when sending a command
    const ids: number[] = []; // ControlCommands ids generated

    // Get all ids of controlCommands with waiting response
    let match;
    while ((match = regex.exec(this.text)) !== null) {
      const id = Number(match[1]);
      ids.push(id);
    }

    // Get deliveries of controlCommands with waiting response
    this.controlCommandService
      .getDeliveriesFromControlCommands(ids)
      .subscribe((res) => {
        let deliveries = res;

        // Get Responses from deliveries
        deliveries.forEach((delivery) => {
          if (delivery.response) {
            let responseObject = this.getResultObject(delivery.response);
            let responseText = "";

            if (responseObject) {
              responseText =
                this.getStdout(responseObject) +
                this.redText +
                this.boldText +
                this.getStderr(responseObject) +
                this.getRuntimeFailure(responseObject) +
                this.resetText;

              // Replace placeholders with responses
              this.processResponse({
                id: delivery.controlCommandId!,
                text: responseText,
              });
            }
          }
        });

        // Clean terminal and write its text again with replacements made
        this.terminal?.clear();
        this.terminal?.write("\r");
        this.terminal?.write(this.text);
      });
  } */

  /* Extract result object from string that contains it */
  getResultObject(content: string): ResultObject | undefined {
    const startIndex = content.indexOf("{");
    const endIndex = content.lastIndexOf("}");
    const jsonSubstring = content.substring(startIndex, endIndex + 1);

    let result: ResultObject | undefined;

    try {
      result = JSON.parse(jsonSubstring);
    } catch {
      result = undefined;
    }

    return result;
  }

  /* Returns content of stdout property */
  getStdout(resultObject: ResultObject): string {
    return this.replaceNewlines(resultObject.stdout);
  }

  /* Returns content of stderr property */
  getStderr(resultObject: ResultObject): string {
    return this.replaceNewlines(resultObject.stderr);
  }

  /* Returns content of runtime_failure property */
  getRuntimeFailure(resultObject: ResultObject): string {
    return this.replaceNewlines(resultObject.runtime_failure);
  }

  private replaceNewlines(input: string | undefined): string {
    return input?.replace(/(\\+r\\+n|\\+n\\+r|\\+r|\n)+/g, "\r\n") ?? "";
  }

  onCommandSent(event: {
    command: string;
    callback: (response: number) => void;
  }): void {
    const { command, callback } = event;

    // TODO: Mock de simulación de tiempo para generar el controlCommand
    setTimeout(() => {
      // Se crea un ControlCommand con id 101675
      let commandId = 101675;
      callback(commandId); // Enviar el id del controlCommand creado al terminal
    }, 2000);
  }

  /* Creates a ControlCommand object and inserts it into the database for being
  processed by the control server */
  createControlCommand(command: string) {
    this.commandsService.getActivityCommands().subscribe((res) => {
      const selectedCommand = res.find(
        (c) => c.privateName == "script" && c.publicName == "Scripting"
      );

      let commandToSent = {
        command: selectedCommand?.privateName ?? "script",
        data: JSON.stringify({
          script: command,
        }),
      };

      //TODO: MOCKED HOSTNAME FOR TESTING
      let controlCommand: ControlCommand = {
        description: "Script from terminal",
        tenantUid: this.tenantUid,
        commandType: "script",
        data: JSON.stringify(commandToSent),
        //controlCommandRecipients: [{ clientId: this.host.toUpperCase() }],
        controlCommandRecipients: [{ clientId: "OA-TEST-W10" }],
        commandId: selectedCommand?.id,
      };

      /* this.controlCommandService.addControlCommand(controlCommand).subscribe(
        (res) => {
          let response = `Command sent (id=${res.id}). Waiting for response...`;
          this.write(response + "\r\n");
          this.prompt();
        },
        (error) => {
          console.log("Error al crear el ControlCommand");
        }
      ); */

      // TESTING Mock Process response received from Rabbit
      var id = 101675;

      let response = `Command sent (id=${id}). Waiting for response...`;
      /* this.write(response);
      this.prompt(); */
    });
  }

  /* Replaces the placeholder for a controlCommand with its response received after being executed */
  /* processResponse(response: CommandResponse) {
    const regex = new RegExp(
      `Command sent \\(id=${response.id}\\)\\. Waiting for response...`,
      "g"
    );
    this.text = this.text.replace(regex, response.text);
  } */

  openMenu() {
    console.log("menu");
  }
}

export interface Command {
  command: string;
  data: CommandData;
}

export interface CommandData {
  [field: string]: any;
}

/* Defines the command response identified by its controlCommandId */
export interface CommandResponse {
  id: string;
  text: string;
}

/* Defines the structure of the response of a script command */
export interface ResultObject {
  stdout: string;
  stderr: string;
  parsed_output_type: string;
  runtime_failure: string;
  exit_status: number;
}
