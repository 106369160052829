import { ChangeDetectorRef, Component } from "@angular/core";
import { ActivityBaseComponent } from "../../shared/activity-base.component";
import { GeneralDataService } from "src/app/services/generalData.service";
import { Filters } from "src/app/models/filters.model";

@Component({
  selector: "app-text-component",
  template: `
    <app-filters
      #filterCmp
      [filterInit]="filterInit"
      (loading)="setLoading($event)"
      (loadData)="loadData($event)"
      (filterChange)="changeFilter($event)"
      (exportOnClick)="csvExport($event)"
      (validAllRequiredFilters)="validAllRequiredFilters($event)"
      (viewModeChange)="viewModeChange($event)"
    >
    </app-filters>
    <input
      #input
      id="input"
      type="text"
      [value]="initialValue"
      (input)="updateValue(input.value)"
    />
  `,
  styles: [
    `
      :host {
        position: absolute;
      }

      #input {
        display: block;
      }
    `,
  ],
})
export class TextComponent extends ActivityBaseComponent {
  private _value!: string;
  public initialValue!: string;

  constructor() {
    //protected override generalDataService: GeneralDataService,
    super(/* generalDataService, */);
  }

  setInitialValue(value: string) {
    this.initialValue = value;
    this._value = value;
  }

  updateValue(value: string) {
    this._value = value;
  }

  handleContainerStateRequestEvent(): TextComponent.State | undefined {
    if (this._value === TextComponent.undefinedTextValue) {
      return undefined;
    } else {
      return {
        text: this._value,
      };
    }
  }

  override ngOnInit() {
    super.ngOnInit();
    /*this.filterInit.autoloadData = true;
    this.filterInit.isLazyLoadData = false;
    this.filterInit.loadFilterWhenDropdown = true;
    this.filterInit.autoloadChangeFilter = true;
    this.filterInit.cleanFilterOnReloadData = false;
    //this.filterInit.notifyOnInitChangeFilter = false;
    this.filterInit.dataRangeDays = 1;    

    //TODOFIRST DASH
    this.refreshService.refresh3(this.filterInit.filter);
    //this.filterInit.export = true;   */
  }

  loadData(filters: Filters) {}
}

export namespace TextComponent {
  export const componentTypeName = "Text";
  export const undefinedTextValue = "<undefined>";

  type TextPropertyName = "text";
  export type State = { [propertyName in TextPropertyName]: string };
}
