import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Filters } from "../models/filters.model";
import { Observable } from "rxjs";
import { ConfigsService } from "@overa/shared";
import { OveraEchartData } from "@overa/components/echart";

@Injectable({
  providedIn: "root",
})
export class MeasurementAppsService {
  constructor(private http: HttpClient, private config: ConfigsService) {}

  get baseUrl(): string {
    return this.config.getModuleConfig("overa-security", "apiBaseUrl") + "/api";
  }

  getSingleUrls(filters: Filters): Observable<OveraEchartData> {
    return this.http.post<OveraEchartData>(
      `${this.baseUrl}/MeasurementUrls/topurls`,
      filters
    );
  }

  getSingleApplications(filters: Filters) {
    return this.http.post<any>(
      `${this.baseUrl}/MeasurementApps/topapps`,
      filters
    );
  }
}
