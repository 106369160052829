import { Component, ViewChild } from "@angular/core";
import { ActivityBaseComponent } from "../../shared/activity-base.component";
import { Filters } from "src/app/models/filters.model";
import {
  OveraEchartComponent,
  OveraEchartData,
  OveraEchartLineOptions,
  OveraEchartMultipleData,
} from "@overa/components/echart";
import { MeasurementDeviceService } from "src/app/services/measurementDevice.service";

@Component({
  selector: "app-process-consumption",
  templateUrl: "./process-consumption.component.html",
  styleUrls: ["./process-consumption.component.scss"],
})
export class ProcessConsumptionComponent extends ActivityBaseComponent {
  @ViewChild("chartCpu")
  chartCpu?: OveraEchartComponent;
  @ViewChild("chartRam")
  chartRam?: OveraEchartComponent;

  chartOptions: OveraEchartLineOptions = new OveraEchartLineOptions();
  chartDataCpu?: OveraEchartData;
  chartDataRam?: OveraEchartData;

  constructor(private measurementDeviceService: MeasurementDeviceService) {
    super();
  }

  override ngOnInit(): void {}

  override loadData(filters: Filters): void {
    this.loading = true;

    this.measurementDeviceService
      .getProcessConsumptionByHost(filters)
      .subscribe((res) => {
        this.chartDataCpu = res[0];
        this.chartDataRam = res[1];
        this.loading = false;
      });
  }

  showStacked(event: any) {
    if (event.target.checked) {
      this.chartOptions.seriesOption.stack = "total";
      this.chartOptions.seriesOption.areaStyle!.opacity = 0.7;
      this.chartCpu!.updateChart();
      this.chartRam!.updateChart();
    } else {
      this.chartOptions.seriesOption.stack = undefined;
      this.chartOptions.seriesOption.areaStyle!.opacity = 0;
      this.chartCpu!.updateChart();
      this.chartRam!.updateChart();
    }
  }
}
