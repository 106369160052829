<div class="main-content">
    <div class="container-fluid">
        <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <mat-card-content>
                        <div class="container-fluid main-container">

                            <div class="container-individualFilters mb-4">

                                <app-filters
                                #filterCmp
                                [filterInit]="filterInit"
                                (loadData)="loadData($event)"
                                (filterChange)="changeFilter($event)"
                                (validAllRequiredFilters)="validAllRequiredFilters($event)">
                                </app-filters>
                            </div>
                            
                            <div class="row mt-5 mb-5">
                                <div class="col-12">
                                    <div *ngIf="loading" class="p-component-overlay loading-overlay">
                                        <em class="pi pi-spin pi-spinner p-datatable-loading-icon"></em>
                                    </div>

                                    <overa-treetable
                                    #treetable
                                    [items]="data"
                                    [columns]="columns"
                                    [scrollable]="true"
                                    [scrollHeight]="'40vh'"
                                    (onExpand)="onNodeExpand($event)"
                                    (onCollapse)="onNodeCollapse($event)"
                                    >
                                    </overa-treetable>
                                </div>
                            </div>

                            </div>
                        </mat-card-content>
                    </div>
                 </div>
            </div>
        </div>
    </div>
</div>






